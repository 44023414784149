import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../api";


export const deleteUserPath = createAsyncThunk(
  "deleteUserPath",
  async (pathID, { rejectWithValue }) => {
    try {
      await axios.put(
        "/userpath/deleteuserpath",
        {
          data: {
            path_ID: pathID,
          },
        }
      );
    } catch (error) {
      // console.log({error})
      // console.error(`There was an error removing the ${pathID} book: ${error}`);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
