import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
// IMPORT CSS
import '../../../style/SearchbarLittle.css'
// IMPORT COMPONENTS
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { FromInput } from '../../SearchBar/From/FromInput';
import { ToInput } from '../../SearchBar/To/ToInput';
import frLocale from "date-fns/locale/fr";
import { useTranslation } from 'react-i18next';

// IMPORT IMG

// IMPORT DATA FROM SLICES
import { selectSelectedToLocation } from '../../SearchBar/To/ToAutosuggestionsSlice';
import { selectSelectedFromLocation } from '../../SearchBar/From/FromAutosuggestionsSlice';
import { setPaths } from '../../User/UserPathSlice';
import { setAddPathToBallance } from '../AddPathToBallance//AddPathToBallanceSlice';
import { setActivePath, setDisplayedPath, setHoveredPath } from '../Results/PathList/PathListSlice';
import { setPathCar, resetPassengerNumber, resetElectric } from '../Results/PathList/Car/PathCarSlice';
import { setPathBus, setBackInitialBusStates } from '../Results/PathList/Transit/Bus/PathBusSlice';
import { setPathTrain, setBackInitialTrainStates } from '../Results/PathList/Transit/Train/PathTrainSlice';
import { setDate, selectDate, setPastDate } from '../../SearchBar/DateSlice';
import { setPathPlane } from '../Results/PathList/Plane/PathPlaneSlice';
import { setPathBicycle } from '../Results/PathList/Bicycle/PathBicycleSlice';
import { setAlert } from '../../../app/AppSlice';

// IMPORT UTIL CALLS
import { askTransitPath } from '../Results/PathList/Transit/Util/askTransitPath';
import { askCarPath } from '../Results/PathList/Car/Util/askCarPath';
import { askElectricityPrice } from '../Results/PathList/Car/Util/askElectricityPrice';
import { askElectricityCO2Factor } from '../Results/PathList/Util/askElectricityCO2Factor';
import { askGazolinePrice } from '../Results/PathList/Car/Util/askGazolinePrice';
import { askBicyclePath } from '../Results/PathList/Bicycle/Util/askBicyclePath';
import { askPlanePath } from '../Results/PathList/Plane/Util/askPlanePath';
import InverseArrowButton from '../../../components/InverseArrowButton';
import { useHistory } from 'react-router';

const customTheme = createMuiTheme({
	palette: {
		primary: {
			main: '#F5F5F5',
		},
		secondary: {
			main: '#592689',
		},
    },
    typography: {
        htmlFontSize: 18,
      },
})

export function SearchbarLittle( { journeyType } ) {

    const history = useHistory();
    const dispatch = useDispatch();
    const from = useSelector(selectSelectedFromLocation);
    const to = useSelector(selectSelectedToLocation);
    const selectedDate = useSelector(selectDate);
    const { t, i18n } = useTranslation();
    const language = i18n.language === 'fr' ? 'French': 'English';

    React.useEffect(() => {
      return () => {
        if(journeyType === "PAST_JOURNEY"){
          dispatch(setDate(new Date()));
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPossiblePastDate = React.useMemo(() => {
        if (journeyType) {
          const currentTime = moment().startOf("day").hour(12);
          const tempDate = moment(selectedDate);
          const tempSelectedDate = moment(
            `${tempDate.year()}-${tempDate.month() + 1}-${tempDate.date()}`
          );
          if (currentTime.diff(tempSelectedDate, "days") < 7) {
            return tempSelectedDate.toDate().toISOString();
          } else {
            for (let index = 0; index < 7; index++) {
              const previousPossibleDay = moment().subtract(index, "days");
              if (previousPossibleDay.day() === tempSelectedDate.day()) {
                return previousPossibleDay.toDate().toISOString();
              }
            }
            return currentTime.toDate().toISOString();
          }
        }
      }, [selectedDate, journeyType]);

    const getPossibleFutureDate = React.useMemo(() => {
        if (journeyType) {
          const tempDate = moment(selectedDate);
          const tempSelectedDate = moment(
            `${tempDate.year()}-${tempDate.month() + 1}-${tempDate.date()}`
          );
          for (let index = 1; index < 8; index++) {
            const upcomingPossibleDay = moment().add(index, "days");
            if (upcomingPossibleDay.day() === tempSelectedDate.day()) {
              return upcomingPossibleDay.toDate().toISOString();
            }
          }
          return moment().toDate().toISOString();
        }
      }, [selectedDate, journeyType]);

    function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {

        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2-lat1);  // deg2rad below
        var dLon = deg2rad(lon2-lon1); 
        var a = 
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
          Math.sin(dLon/2) * Math.sin(dLon/2)
          ; 
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        var d = R * c; // Distance in km
        return d;
      }
      
      function deg2rad(deg) {
        return deg * (Math.PI/180)
      }

    return (

        <div className="SearchbarLittle">

        <div className="Searchbar-content-little">

            <div className="SearchBar-fields-little">

                 <FromInput />
            
                <InverseArrowButton />

                <ToInput />


            {journeyType === "REGULAR_JOURNEY" ? null : <>

                <MuiThemeProvider theme={customTheme}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === 'French' ? frLocale : null}>
                            <KeyboardDatePicker
                                id="time-picker-little"
                                variant={/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? "standard" : "inline"}
                                format={language === 'French' ? "dd MMM yyy" : "dd MMM yyy"}
                                ampm={false}
                                autoOk={true}
                                disablePast={journeyType === 'PAST_JOURNEY' ? false : true}
                                disableFuture={journeyType === 'PAST_JOURNEY' ? true : false }
                                value={selectedDate}
                                onChange={(date) => {
                                  if(journeyType === 'PAST_JOURNEY') {
                                    if(date) dispatch(setPastDate(date.toString()));
                                    return;
                                  }
                                    if(date) dispatch(setDate(date.toString()))
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </MuiThemeProvider>

            <button className="SearchBar-submit" 



                onClick = {() => {
                    if(from && to){
                        history.replace('/map');
                        dispatch(setActivePath(''));
                        dispatch(setHoveredPath(''));
                        dispatch(setPaths([]));
                        dispatch(setAddPathToBallance(false));
                        dispatch(setDisplayedPath(''));
                        dispatch(setPathCar({id: 'car',routes: [],CO2displayed: 0, passengersNumber: 1, electric: false }));
                        dispatch(resetElectric());
                        dispatch(resetPassengerNumber());
                        dispatch(setPathBus({id: 'bus',routes: [],CO2displayed: 0 }));
                        dispatch(setBackInitialBusStates());
                        dispatch(setPathTrain({id: 'train',routes: [],CO2displayed: 0 }));
                        dispatch(setBackInitialTrainStates());
                        dispatch(setPathBicycle({id: 'bicycle',routes: [],CO2displayed: 0 }));
                        dispatch(setPathPlane(['reset']));
                        dispatch(askCarPath([from, to, (new Date(selectedDate)).toISOString()])).then(() => {
                                dispatch(askElectricityPrice(from.country))
                                .then(dispatch(askElectricityCO2Factor(from.country)))
                                .then(dispatch(askGazolinePrice(from.country)))                          
                        });
                        if(getDistanceFromLatLonInKm(from.position.lat, from.position.lng, to.position.lat, to.position.lng) < 70) {
                            dispatch(askBicyclePath([from, to, (new Date(selectedDate)).toISOString()]));
                        }
                        if(getDistanceFromLatLonInKm(from.position.lat, from.position.lng, to.position.lat, to.position.lng) < 500) {
                            dispatch(setPathPlane(['CO2', 0.258]));
                        }
                        else if(getDistanceFromLatLonInKm(from.position.lat, from.position.lng, to.position.lat, to.position.lng) < 5000) {
                            dispatch(setPathPlane(['CO2', 0.187]));
                        } else {
                            dispatch(setPathPlane(['CO2', 0.152]));
                        }
                        dispatch(askTransitPath([from, to, journeyType === 'PAST_JOURNEY' ? getPossiblePastDate : (new Date(selectedDate)).toISOString()]));
                        dispatch(askPlanePath([from, to,  journeyType === 'PAST_JOURNEY' ? getPossibleFutureDate : (new Date(selectedDate)).toISOString(), getDistanceFromLatLonInKm(from.position.lat, from.position.lng, to.position.lat, to.position.lng)]));
                        document.getElementById('map').scrollIntoView();
                        var scrolledY = window.scrollY;
                        if(scrolledY){
                        window.scroll(0, scrolledY - 47);
                        }
                    } else if (!from && !to ) {
                        dispatch(setAlert({message: t("We haven't found your departure and arrival points"),showAlert: true, duration: 6000, severity:'error'}));
                    } else if (!from && to ) {
                        dispatch(setAlert({message: t("We haven't found your departure point"),showAlert: true, duration: 6000, severity:'error'}));
                    } else {
                        dispatch(setAlert({message: t("We haven't found your arrival point"),showAlert: true, duration: 6000, severity:'error'}));
                    }
                }
                }>
                    {t("LetsGo")}
            </button>
            </>}
            </div>

        </div>


        </div>

    )

};


