import { createAsyncThunk } from '@reduxjs/toolkit';
const axios = require('axios');

export const askPlanePath = createAsyncThunk("askPlanePath", async (inValue,{rejectWithValue}) => {

  try {

    const departureResp = await axios.get('https://skyscanner-skyscanner-flight-search-v1.p.rapidapi.com/apiservices/autosuggest/v1.0/UK/GBP/en-GB/', {
      params: {query: inValue[0].label},
      headers: {
        'x-rapidapi-key': '69d0c15c64mshb5d54ca53c05c97p17b431jsna0ac6aed87a2',
        'x-rapidapi-host': 'skyscanner-skyscanner-flight-search-v1.p.rapidapi.com'
      }
    })

    if(typeof departureResp === 'undefined') {
      return null
    }

    const arrivalResp = await axios.get('https://skyscanner-skyscanner-flight-search-v1.p.rapidapi.com/apiservices/autosuggest/v1.0/UK/GBP/en-GB/', {
      params: {query: inValue[1].label},
      headers: {
        'x-rapidapi-key': '69d0c15c64mshb5d54ca53c05c97p17b431jsna0ac6aed87a2',
        'x-rapidapi-host': 'skyscanner-skyscanner-flight-search-v1.p.rapidapi.com'
      }
    })

    if(typeof arrivalResp === 'undefined') {
      return null
    }

    const resp = await axios.get(`https://skyscanner-skyscanner-flight-search-v1.p.rapidapi.com/apiservices/browseroutes/v1.0/FR/EUR/en-UK/${departureResp.data.Places[0].PlaceId}/${arrivalResp.data.Places[0].PlaceId}/${inValue[2].substring(0, 10)}`, {
      headers: {
        'x-rapidapi-key': '69d0c15c64mshb5d54ca53c05c97p17b431jsna0ac6aed87a2',
        'x-rapidapi-host': 'skyscanner-skyscanner-flight-search-v1.p.rapidapi.com'
      }
    })

    let routes = []

    for(let i=0; i < resp.data.Quotes.length; i++) {

      let Carrier = '';
      for(let j=0; j <resp.data.Carriers.length; j++) {
        if(resp.data.Carriers[j].CarrierId === resp.data.Quotes[i].OutboundLeg.CarrierIds[0]) {
          Carrier = resp.data.Carriers[j].Name;
        }
      }

      let Departure = '';
      for(let j=0; j <resp.data.Places.length; j++) {
        if(resp.data.Places[j].PlaceId === resp.data.Quotes[i].OutboundLeg.OriginId) {
          Departure = resp.data.Places[j].Name;
        }
      }
      let Arrival = '';
      for(let j=0; j <resp.data.Places.length; j++) {
        if(resp.data.Places[j].PlaceId === resp.data.Quotes[i].OutboundLeg.DestinationId) {
          Arrival = resp.data.Places[j].Name;
        }
      }

      let route = {
        QuoteId: resp.data.Quotes[i].QuoteId,
        Price: resp.data.Quotes[i].MinPrice,
        Direct: resp.data.Quotes[i].Direct,
        Date: resp.data.Quotes[i].OutboundLeg.DepartureDate,
        Arrival: Arrival,
        Departure: Departure,
        Carrier: Carrier,
        distance: inValue[3]
      }

      routes.push(route);

    }

    return routes

    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }

});

