import { askCarPath } from './Util/askCarPath';
import { askElectricityPrice } from './Util/askElectricityPrice';
import { askElectricityCO2Factor } from '../Util/askElectricityCO2Factor';
import { askGazolinePrice } from './Util/askGazolinePrice';

import { createSlice } from '@reduxjs/toolkit';


export const PathCarSlice = createSlice({
  name: 'PathCar',
  initialState: {
    displayedCarPath: 0,
    path: 
      {
        id: 'car',
        routes: [],
        CO2displayed : 0
      },
    electric: false,
    passengersNumber : 1,
    calculationDetailsDisplayed : false,
    carLoading: false,
    electricCar: 
      {
        manufacture: 0.122563794937565,
        infrastructure: 0.0640012328004454,
        electricity_CO2_Factor: '',
        electricity_price: {
        price: '',
        }
      },
    conventionalCar: 
      {
        operation: 0.193,
        manufacture: 0.0505637949375646,
        infrastructure: 0.0640012328004454,
        gazoline_price: {
        price: '',
        }
      }  
  },
  reducers: {
    setPathCar(state, action) {
      return {
        ...state,
        path : action.payload
      }
    },
    setdisplayedCarPath: (state, action) => {
      if(state.electric === true) {
        return {
          ...state,
          displayedCarPath : action.payload,
          path: {
            id: 'car',
            routes: state.path.routes,
            CO2displayed: ((state.electricCar.manufacture + state.electricCar.infrastructure) * state.path.routes[action.payload].distanceByCar/1000 + state.electricCar.electricity_CO2_Factor.co2 * state.path.routes[action.payload].consumption)/ (state.passengersNumber) + state.path.routes[action.payload].totalCO2Transit
          }
        }
      } else {
        return {
          ...state,
          displayedCarPath : action.payload,
          path: {
            id: 'car',
            routes: state.path.routes,
            CO2displayed: ((state.conventionalCar.manufacture + state.conventionalCar.infrastructure +state.conventionalCar.operation) * state.path.routes[action.payload].distanceByCar/1000)/ (state.passengersNumber) + state.path.routes[action.payload].totalCO2Transit
          }
        }
      }
      
    },
    resetElectric(state) {
      return{
        ...state,
        electric: false
      }
    },
    switchToElectric(state) {
      if(state.electric === true) {
        return {
          ...state,
          electric : false,
          path: {
            id: state.path.id,
            routes: state.path.routes,
            CO2displayed: ((state.conventionalCar.manufacture + state.conventionalCar.infrastructure +state.conventionalCar.operation) * state.path.routes[state.displayedCarPath].distanceByCar/1000)/ (state.passengersNumber) + state.path.routes[state.displayedCarPath].totalCO2Transit
          }
        }
      } else {
        return {
          ...state,
          electric : true,
          path: {
            id: state.path.id,
            routes: state.path.routes,
            CO2displayed: ((state.electricCar.manufacture + state.electricCar.infrastructure) * state.path.routes[state.displayedCarPath].distanceByCar/1000 + state.electricCar.electricity_CO2_Factor.co2 * state.path.routes[state.displayedCarPath].totalCarConsumption)/ (state.passengersNumber) + + state.path.routes[state.displayedCarPath].totalCO2Transit
          }
        }
      }
    },
    resetPassengerNumber(state) {
      return {
        ...state,
        passengersNumber : 1
      }
    },
    incrementPassengerNumber(state) {
      if(state.passengersNumber < 6) {
        if(state.electric) {
          return {
            ...state,
            passengersNumber : state.passengersNumber + 1,
            path: {
              id: state.path.id,
              routes: state.path.routes,
              CO2displayed: ((state.electricCar.manufacture + state.electricCar.infrastructure) * state.path.routes[state.displayedCarPath].distanceByCar/1000 + state.electricCar.electricity_CO2_Factor.co2 * state.path.routes[state.displayedCarPath].totalCarConsumption)/ (state.passengersNumber + 1) + + state.path.routes[state.displayedCarPath].totalCO2Transit
            }
          }
        } else {
          return {
            ...state,
            passengersNumber : state.passengersNumber + 1,
            path: {
              id: state.path.id,
              routes: state.path.routes,
              CO2displayed: ((state.conventionalCar.manufacture + state.conventionalCar.infrastructure +state.conventionalCar.operation) * state.path.routes[state.displayedCarPath].distanceByCar/1000)/ (state.passengersNumber + 1) + + state.path.routes[state.displayedCarPath].totalCO2Transit
            }
          }
        }
      } else {
        return {
          ...state,
        }
      }
    },
    decrementPassengerNumber(state) {
      if(state.passengersNumber > 1) {
        if(state.electric) {
          return {
            ...state,
            passengersNumber : state.passengersNumber - 1,
            path: {
              id: state.path.id,
              routes: state.path.routes,
              CO2displayed: ((state.electricCar.manufacture + state.electricCar.infrastructure) * state.path.routes[state.displayedCarPath].distanceByCar/1000 + state.electricCar.electricity_CO2_Factor.co2 * state.path.routes[state.displayedCarPath].totalCarConsumption)/ (state.passengersNumber - 1) + state.path.routes[state.displayedCarPath].totalCO2Transit
            }
          }
        } else {
          return {
            ...state,
            passengersNumber : state.passengersNumber - 1,
            path: {
              id: state.path.id,
              routes: state.path.routes,
              CO2displayed: ((state.conventionalCar.manufacture + state.conventionalCar.infrastructure +state.conventionalCar.operation) * state.path.routes[state.displayedCarPath].distanceByCar/1000)/ (state.passengersNumber - 1) + state.path.routes[state.displayedCarPath].totalCO2Transit
            }
          }
        }
      } else {
        return {
          ...state,
        }
      }
    }
  },
  extraReducers: {
    [askCarPath.fulfilled]: (state, action) => {
      switch (action.type) {
        default:
          if(action.payload.routes[state.displayedCarPath]?.distanceByCar) {
            return {
              ...state,
              carLoading : false,
              path : {
                id: action.payload.id,
                routes: action.payload.routes,
                CO2displayed : action.payload.routes[state.displayedCarPath].distanceByCar /1000 * (state.conventionalCar.manufacture + state.conventionalCar.infrastructure +state.conventionalCar.operation) + action.payload.routes[state.displayedCarPath].totalCO2Transit
              }
            }
          }
      }
    },
    [askCarPath.rejected]:(state) => {
      return {
        ...state,
        path: {
          id: 'car',
          routes: [],
          CO2displayed : 0
        },
        carLoading: false
      }
    },
    [askCarPath.pending]: (state) => {
      return {
        ...state,
        carLoading : true
      }      
    },
    [askElectricityPrice.fulfilled]: (state, action) => {
      switch (action.type) {
        default:
          return {
            ...state,
            electricCar: {
              manufacture: state.electricCar.manufacture,
              infrastructure: state.electricCar.infrastructure,
              electricity_CO2_Factor: state.electricCar.electricity_CO2_Factor,
              electricity_price: action.payload
            }
          }
      }
    },
    [askElectricityCO2Factor.fulfilled]: (state, action) => {
      switch (action.type) {
        default:
          return {
            ...state,
            electricCar: {
              manufacture: state.electricCar.manufacture,
              infrastructure: state.electricCar.infrastructure,
              electricity_CO2_Factor: action.payload,
              electricity_price: state.electricCar.electricity_price 
            }
          }
      }
    },
    [askGazolinePrice.fulfilled]: (state, action) => {
      switch (action.type) {
        default:
          return {
            ...state,
            conventionalCar: {
                operation: state.conventionalCar.operation,
                manufacture: state.conventionalCar.manufacture,
                infrastructure: state.conventionalCar.infrastructure,
                gazoline_price: action.payload
            }
          }
        }
      }
  }
});


export const { setdisplayedCarPath, resetElectric, resetPassengerNumber, setPathCar, incrementPassengerNumber, decrementPassengerNumber, switchToElectric } = PathCarSlice.actions;


export const selectPathCar = state => state.PathCar.path;
export const selectPassengersNumber = state => state.PathCar.passengersNumber;
export const selectelectricCar = state => state.PathCar.electricCar;
export const selectconventionalCar = state => state.PathCar.conventionalCar;
export const selectElectric = state => state.PathCar.electric;
export const selectDisplayedCarPath = state => state.PathCar.displayedCarPath;


export const selectCarLoading = state => state.PathTrain.carLoading;


export default PathCarSlice.reducer;
