import React from "react";
import { useSelector } from "react-redux";

// IMPORT DATA FROM SLICES
// import { isLoggedIn } from "../../../Auth/AuthSlice";
import { selectUserPath } from "../../UserPathSlice";
// import { selectPickedPath } from "../../UserPathSlice";
import { useTranslation } from 'react-i18next';

// IMPORT COMPONENTS
import { makeStyles, withStyles  } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Table from './Table';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    backgroundColor : 'transparent',
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    
    fontWeight : 'bold'
  },

  expanded: {},
})(MuiAccordionSummary);


export default function ControlledAccordions() {

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(`pannel${(new Date()).getFullYear()}`);

  const handleChange = (panel) => (isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const userPath = useSelector(selectUserPath);

  function listTheYears (userPath) {
  const unique = [...new Set(userPath.map(item => (new Date(item.date)).getFullYear()))];
  return unique
  }

  const { t } = useTranslation();

  return (
   
    <div className={classes.root}>

    {listTheYears(userPath).length === 0 ? 
      <>
      <p>{t("You don't have any journey saved in your account.")}</p>
      <p>{t("Start by searching the best way for your next travel.")}</p>
      </>
      :
      <>
      {listTheYears(userPath).sort(((a, b) => b - a)).map((year,index) => (
      
      <Accordion expanded={expanded === `pannel${year}`} onChange={handleChange(`pannel${year}`)} key={index}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${year}bh-content`}
        id={`panel${year}bh-header`}
      >
        <Typography className={classes.heading}>{year}</Typography>
        
      </AccordionSummary>
      <AccordionDetails>
        <Table year={year} />
      </AccordionDetails>
    </Accordion>

      ))}
      </>
    }

    </div>
  );
}