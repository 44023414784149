import { createSlice } from "@reduxjs/toolkit";
import { deleteUserPath } from "./Util/deleteUserPath";

import { getUserPath } from "./Util/getUserPath";

export const UserSlice = createSlice({
  name: "UserPath",
  initialState: {
    value: [],
    pickedPath: ''
  },
  reducers: {
    setPickedPath(state, action) {
      return {
        ...state,
        pickedPath: action.payload,
      };
    },
    setPaths(state, action){
      return {
        ...state,
        value: action.payload
      }
    }
  },
  extraReducers: {
    [getUserPath.fulfilled]: (state, action) => {
      switch (action.type) {
        default:
          return {
            ...state,
            value: action.payload,
          };
      }
    },
    [deleteUserPath.rejected]: (state, action) => {
      return {
        ...state,
        pickedPath: '',
      };
    },
    [deleteUserPath.fulfilled]: (state, action) => {
      const array = state.value.filter(obj => obj._id !== state.pickedPath);
      return {
        ...state,
        value: array,
        pickedPath: ''
      };
    },
  },
});

export const { setPickedPath, setPaths } = UserSlice.actions;

export const selectUserPath = (state) => state.UserPath.value;

export const selectPickedPath = (state) => state.UserPath.pickedPath;

export default UserSlice.reducer;
