import React from "react";

import { useTranslation } from 'react-i18next';

// IMPORT COMPONENTS
import AccordeonTable from './AccordeonTable';

export default function JourneyContainer() {

  const { t } = useTranslation();

  return (
    <div className="Journeys">

        <h4>{t('Overview')}</h4>

        <AccordeonTable />


    </div>
  );
}

    /*
        

    */