import React from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return (
    <Snackbar open={props.open} autoHideDuration={props.autoHideDuration} onClose={props.onClose}>
      <MuiAlert
        onClose={props.onClose}
        severity={props.severity}
        elevation={6}
        variant="filled"
      >
        {props.children}
      </MuiAlert>
    </Snackbar>
  );
}

Alert.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    severity: PropTypes.oneOf(['success','info', 'warning','error']).isRequired,
    autoHideDuration: PropTypes.number
};

export default Alert;
