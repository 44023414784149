import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
// import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';


function GoogleSignInTerm({
    show,
    onAccept,
    onCancel,
    title,
    description
}) {
    const { t } = useTranslation();
    return (
        <Dialog open={show}>
            <DialogTitle id="form-dialog-title">{t(title)}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <p>{t(description)}</p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    {t('Cancel')}
                </Button>
                <Button onClick={onAccept} color="primary">
                    {t("Accept")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

// AcceptGoogleSignInTerm.propTypes = {

// }

export default GoogleSignInTerm

