import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../api';


  export const getUserPath = createAsyncThunk("getUserPath", async () => {
    try {
      const {data} = await axios.get("/userpath/getuserpath/")
       return data
     } catch (error) {
       return []
     }
  })