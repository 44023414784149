import { createAsyncThunk } from '@reduxjs/toolkit';
const axios = require('axios');

export const askCO2Train = createAsyncThunk("askCO2Train", (inValue) => {

  return axios.get(`${process.env.REACT_APP_API}/trains`, {
    params: {
      country: inValue
    }
  }).then(function(result) {
  return result.data
  });


});