import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { isLoggedIn } from './Auth/AuthSlice';
import { useTranslation } from 'react-i18next';

import megaphone from './takeaction.png';
import climate from './climatechange.png';
import transport from './transportsector.png'

// CSS IMPORT
import '../style/Home.css';

// COMPONENT IMPORT
import { SearchBar } from './SearchBar/SearchBar';

export function Home() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      const history = useHistory();
      const isLogin = useSelector(isLoggedIn);
      const { t } = useTranslation();

        return (

            <div className='Home'>

                <SearchBar />

                <div className='HomeContent' >    

                    <div className='HomeContentText' >

                    <p>{t("Moveco was designed to help you find the most sustainable way to travel to your destination. We compare the different alternatives, keep track of your carbon emissions, and reduce your carbon footprint.")}</p>

                    </div>           

                    <div className='HomeContentIcons' >

                    <div className='Icon' onClick={(e) => { e.preventDefault(); history.push("/climate-change")}}>
                        <img src={climate} alt="Logo" />
                        <h2>{t('Climate change')}</h2>
                    </div>
                    
                    <div className='Icon'>
                        <img src={transport} alt="Logo" onClick={(e) => { e.preventDefault(); history.push("/transport")}} />
                        <h2>{t('Transport')}</h2>
                    </div>
                    
                    {isLogin ?
                    <div className='Icon' onClick={(e) => { e.preventDefault(); history.push("/user/balance")}}>
                        <img src={megaphone} alt="Logo" />
                        <h2>{t('Your account')}</h2>
                    </div>
                    :
                    <div className='Icon' onClick={(e) => { e.preventDefault(); history.push("/register")}}>
                        <img src={megaphone} alt="Logo" />
                        <h2>{t('Sign up')}</h2>
                    </div>
                    }

                    

                </div>

                <div className='HomeContentText2' >

                <h1>{t('Roam the world – and reduce your impact')}</h1>


                <p>{t("Moveco was created out of our aspiration to reconcile our desire to explore the world with the challenge that climate change requires us to meet. We want to travel without “too much” damaging our environment. Our vision is to inform travellers and commuters about the impact of each of their trips, so they choose the most sustainable way to reach their destination.")}</p>

                <ol>
                    <li>{t("Choose your departure and arrival points")}</li>
                    <li>{t("Choose the date of your travel")}</li>
                    <li>{t("Compare the different routes available, considering the price, time and carbon emissions of each option")}</li>
                    <li>{t("Save your journey in your yearly carbon balance")}</li>
                    <li style={{fontWeight: "500"}}>{t("Enjoy the ride!")}</li>
                </ol>
  

                </div>



                </div>
            </div>

        )
};

/*
                /* <div className='HomeContent'>

                    </div>

*/