// the key name should be in english
// chlidrens are aliaces

// this list has english and french country names
const countries = {
  Afghanistan: [],
  Albania: ["Albanie"],
  Algeria: ["Algérie"],
  Andorra: ["Andorre"],
  Angola: [],
  "Antigua and Barbuda": ["Antigua-et-Barbuda"],
  Argentina: ["Argentine"],
  Armenia: ["Arménie"],
  Australia: ["Australie"],
  Austria: ["Autriche"],
  Azerbaijan: ["Azerbaïdjan"],
  "The Bahamas": ["Les Bahamas", "Bahamas"],
  Bahrain: ["Bahreïn", "Le Bahreïn"],
  Bangladesh: ["Le Bangladesh"],
  Barbados: ["Barbade", "la Barbade"],
  Belarus: ["Biélorussie", "la Biélorussie"],
  Belgium: ["Belgique", "la Belgique"],
  Belize: [],
  Benin: ["Bénin", "le Bénin"],
  Bhutan: ["Bhoutan", "le Bhoutan"],
  Bolivia: ["Bolivie"],
  "Bosnia and Herzegovina": ["Bosnie-Herzégovine", "la Bosnie-Herzégovine"],
  Botswana: ["Botswana", "le Botswana"],
  Brazil: ["Brésil", "le Brésil"],
  Brunei: ["Brunéi", "le Brunéi"],
  Bulgaria: ["Bulgarie","la Bulgarie"],
  "Burkina Faso": ["Burkina","le Burkina"],
  Burundi: ["le Burundi"],
  "Cape Verde": ["Cap-Vert","le Cap-Vert"],
  Cambodia: ["Cambodge", "le Cambodge"],
  Cameroon: ["Cameroun","le Cameroun"],
  Canada: ["le Canada"],
  "Central African Republic": ["République centrafricaine", "la République centrafricaine"],
  Chad: ["Tchad","le Tchad"],
  Chile: ["Chili","le Chili"],
  China: ["Chine", "la Chine"],
  Colombia: ["Colombie","la Colombie"],
  Comoros: ["Comores", "les Comores"],
  "congo": [],
  "Congo, Democratic Republic of the": ["République Dém. du Congo"],
  "Congo, Republic of the": ['République du Congo'],
  "Costa Rica": ['le Costa Rica'],
  "Côte d’Ivoire": ["la Côte d'Ivoire"],
  Croatia: ["Croatie","la Croatie"],
  Cuba: [],
  Cyprus: ["Chypre"],
  "Czech Republic": ['Tchéquie', 'République tchèque', 'la République tchèque'],
  Denmark: ['Danemark','le Danemark'],
  Djibouti: ['Djibouti','le Djibouti'],
  Dominica: ['Dominique','la Dominique'],
  "Dominican Republic": ['République dominicaine', 'la République dominicaine'],
  "Timor-Leste": ['East Timor', 'Timor Oriental'],
  Ecuador: ["Équateur"],
  Egypt: ['Égypte'],
  "El Salvador": ['Salvador'],
  "Equatorial Guinea": ['Guinée Équatoriale'],
  Eritrea: ['Érythrée'],
  Estonia: ['Estonie'],
  Eswatini: [],
  Ethiopia: ['Éthiopie'],
  Fiji: ['Fidji', 'les Fidji'],
  Finland: ['Finlande', 'La Finlande'],
  France: ['la France'],
  'French Polynesia': ['Polynésie Française'],
  Gabon: ['le Gabon'],
  "The Gambia": ['Gambia','Gambie','la Gambie'],
  Georgia: ['Géorgie', 'la Géorgie'],
  Germany: ['Allemagne'],
  Ghana: ['le Ghana'],
  Greece: ['Grèce', 'la Grèce'],
  Grenada: ['Grenade','la Grenade'],
  Guatemala: ['le Guatemala'],
  Guinea: ['Guinée', 'la Guinée'],
  "Guinea-Bissau": ['Guinée-Bissao','la Guinée-Bissao'],
  Guyana: ['la Guyana'],
  Haiti: ['Haïti'],
  Honduras: ['le Honduras'],
  Hungary: ['Hongrie','la Hongrie'],
  Iceland: ['Islande'],
  India: ['Inde'],
  Indonesia: ['Indonésie'],
  Iran: [],
  Iraq: [],
  Ireland: ['Irlande'],
  Israel: ['Israël'],
  Italy: ['Italie'],
  Jamaica: ['Jamaïque', 'la Jamaïque'],
  Japan: ['Japon','le Japon'],
  Jordan: ['Jordanie', 'la Jordanie'],
  Kazakhstan: ['le Kazakhstan'],
  Kenya: ['le Kenya'],
  Kiribati: ['Koweït', 'le Koweït'],
  "Korea North": ['Corée du Nord', 'la Corée du Nord'],
  "Korea South": ['Corée du Sud', 'la Corée du Sud'],
  Kosovo: [],
  Kuwait: ['Koweït','le Koweït'],
  Kyrgyzstan: ['Kirghizstan', 'le Kirghizstan'],
  Laos: ['le Laos'],
  Latvia: ['Lettonie', 'la Lettonie'],
  Lebanon: ['Liban','le Liban'],
  Lesotho: ['le Lesotho'],
  Liberia: ['Libéria','le Libéria'],
  Libya: ['la Libye'],
  Liechtenstein: ['le Liechtenstein'],
  Lithuania: ['Lituanie','la Lituanie'],
  Luxembourg: ['le Luxembourg'],
  Madagascar: [],
  Malawi: ['le Malawi'],
  Malaysia: ['Malaisie','la Malaisie'],
  Maldives: ['les Maldives'],
  Mali: ['le Mali'],
  Malta: ['Malte'],
  "Marshall Islands": ['les Îles Marshall'],
  Mauritania: ['la Mauritanie'],
  Mauritius: ['Maurice','Île Maurice'],
  Mexico: ['Mexique','le Mexique'],
  "Micronesia, Federated States of": ['États Fédérés de Micronésie'],
  "Micronesia": ['la Micronésie'],
  Moldova: ['Moldavia', 'la Moldavie'],
  Monaco: [],
  Mongolia: ['Mongolie', 'la Mongolie'],
  Montenegro: ['Monténégro','le Monténégro'],
  Morocco: ['Maroc', 'le Maroc'],
  Mozambique: ['le Mozambique'],
  "Myanmar (Burma)": [],
  "Myanmar": [],
  Namibia: ['Namibie', 'la Namibie'],
  Nauru: ['Nauru', 'la Nauru'],
  Nepal: ['Népal', 'le Népal'],
  Netherlands: ['Pays-Bas', 'les Pays-Bas'],
  "New Zealand": ['Nouvelle-Zélande', 'la Nouvelle-Zélande'],
  Nicaragua: ['le Nicaragua'],
  Niger: ['le Niger'],
  Nigeria: ['Nigéria', 'le Nigéria'],
  Nieu: ['Nioué'],
  "North Macedonia": ['Macédoine', 'la Macédoine', 'Macédoine du Nord'],
  "Macedonia": ['Macédoine', 'la Macédoine', 'Macédoine du Nord'],
  Norway: ['Norvège', 'la Norvège'],
  Oman: [],
  Pakistan: ['le Pakistan'],
  Palau: ['Palaos'],
  Panama: ['le Panama'],
  "Papua New Guinea": ['Papouasie-Nouvelle-Guinée', 'la Papouasie-Nouvelle-Guinée'],
  Paraguay: ['le Paraguay'],
  Peru: ['Pérou', 'le Pérou'],
  Philippines: ['Philippines', 'les Philippines'],
  Poland: ['Pologne', 'la Pologne'],
  Portugal: ['le Portugal'],
  Qatar: ['le Qatar'],
  Romania: ['Roumanie' ,'la Roumanie'],
  Russia: ['Russie', 'la Russie'],
  Rwanda: ['le Rwanda'],
  "Saint Kitts and Nevis": ['Saint-Christophe-et-Niévès'],
  "Saint Lucia": ['Sainte-Lucie'],
  "Saint Vincent and the Grenadines": ['Saint-Vincent-et-les-Grenadines'],
  Samoa: [],
  "San Marino": ['Saint-Marin'],
  "Sao Tome and Principe": ['Sao Tomé et Principe'],
  "Saudi Arabia": ['Arabie saoudite'],
  Senegal: ['Sénégal', 'le Sénégal'],
  Serbia: ['Serbie', 'la Serbie'],
  Seychelles: ['les Seychelles'],
  "Sierra Leone": ['la Sierra Leone'],
  Singapore: [],
  Slovakia: ['Slovaquie', 'la Slovaquie'],
  Slovenia: ['Slovénie', 'la Slovénie'],
  "Solomon Islands": ['Îles Salomon'],
  Somalia: ['Somalie', 'la Somalie'],
  "South Africa": ['Afrique du Sud'],
  Spain: ['Espagne'],
  "Sri Lanka": ['le Sri Lanka'],
  Sudan: ['Soudan', 'le Soudan'],
  "Sudan South": ['Soudan du Sud'],
  Suriname: ['Surinam', 'le Surinam'],
  Sweden: ['Suède', 'la Suède'],
  Switzerland: ['Suisse', 'la Suisse'],
  Syria: ['Syrie', 'la Syrie'],
  Taiwan: [],
  Tajikistan: ['Tadjikistan', 'le Tadjikistan'],
  Tanzania: ['Tanzanie', 'la Tanzanie'],
  Thailand: ['Thaïlande', 'la Thaïlande'],
  Togo: ['le Togo'],
  Tonga: ['les Tonga'],
  "Trinidad and Tobago": ['Trinité-et-Tobago', 'la Trinité-et-Tobago'],
  Tunisia: ['Tunisie','la Tunisie'],
  Turkey: ['Turquie', 'la Turquie'],
  Turkmenistan: ['Turkménistan', 'le Turkménistan'],
  Tuvalu: ['le Tuvalu'],
  Uganda: ['Ouganda'],
  Ukraine: ['Ukraine'],
  "United Arab Emirates": ['Émirats arabes unis', 'les Émirats arabes unis'],
  "United Kingdom": ['Royaume-Uni', 'le Royaume-Uni'],
  "USA": ["United States", 'États-Unis', 'les États-Unis'],
  Uruguay: [],
  Uzbekistan: ['Ouzbékistan'],
  Vanuatu: ['le Vanuatu'],
  "Vatican City": ['Vatican', 'État de la Cité du Vatican'],
  Venezuela: ['le Venezuela'],
  Vietnam: ['Viêt-Nam', 'le Viêt-Nam'],
  Yemen: ['Yémen', 'le Yémen'],
  Zambia: ['Zambie', 'la Zambie'],
  Zimbabwe: ['le Zimbabwe'],
};

export const getCountryName = (country) => {
  if(country) {
    if(country in countries) {
      return country;
    }
    for (const property in countries) {
      for (let i = 0;  i < countries[property].length; i++) {
        const element = countries[property][i];
        if(element === country) {
          return property;
        } else if(element.toLowerCase() === country.toLowerCase()){
          return property;
        }
      }
    }
  } else {
    return country;
  }
} 
