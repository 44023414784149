import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  isMobile,
  isWinPhone,
  isTablet,
  isAndroid,
  isIOS,
} from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import { throttle } from "lodash";
import "../../../style/ToFromInput.css";
import RegularViewInput from "../../../components/Inputs/LocationPicker/RegularViewInput/RegularViewInput";
import MobileViewInput from "../../../components/Inputs/LocationPicker/MobileViewInput/MobileViewInput";

import {
  selectToAutosuggestions,
  fetchToAutosuggestions,
  setToAutosuggestions,
  selectIsToAutosuggestionsLoading,
  selectSelectedToLocation,
  setSelectedToLocation,
} from "./ToAutosuggestionsSlice";
import { useTranslation } from "react-i18next";

export const ToInput = React.memo(() => {
  const initialRender = useRef(true);
  const [showMobileViewInput, _showMobileViewInput] = useState(false);
  const toAutosuggestions = useSelector(selectToAutosuggestions);
  const isLoading = useSelector(selectIsToAutosuggestionsLoading);
  const selectedToLocation = useSelector(selectSelectedToLocation);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    if (!showMobileViewInput && !initialRender.current) {
      const cleanUpState = () => {
        if (toAutosuggestions && toAutosuggestions.length) {
          if (selectedToLocation) {
            if (selectedToLocation.label === toAutosuggestions[0].label) {
              return;
            }
            if (!toAutosuggestions.some((e) => e.label === selectedToLocation.label)) {
              dispatch(setToAutosuggestions([selectedToLocation]));
            }
          } else {
            dispatch(setToAutosuggestions([]));
          }
        }
      };
      cleanUpState();
    }
  }, [
    dispatch,
    toAutosuggestions,
    selectedToLocation,
    showMobileViewInput,
  ]);

  let content = {
    English: {
      placeholderTo: "To",
    },
    French: {
      placeholderTo: "Arrivé",
    },
  };

  language === "fr" ? (content = content.French) : (content = content.English);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledFetch = useCallback(
    throttle((data, language) => {
      dispatch(fetchToAutosuggestions([data, language]));
    }, 1000),
    [] // will be created only once initially
  );
  return (
    <>
      {showMobileViewInput ? (
        <MobileViewInput
          inputId="mTo-select-input"
          title={content.placeholderTo}
          placeholder={content.placeholderTo}
          data={toAutosuggestions}
          onInputChange={throttledFetch}
          isLoading={isLoading}
          onSelect={(e) => {
            if(e) {
              dispatch(setSelectedToLocation(e));
              dispatch(setToAutosuggestions([e]));
            }
          }}
          onClose={() => {
            _showMobileViewInput(false);
          }}
        />
      ) : (
        <div className="inputTo">
          <RegularViewInput
            isLoading={isLoading}
            value={selectedToLocation}
            onFocus={(e) => {
              e.stopPropagation();
              if (isMobile || isWinPhone || isTablet || isAndroid || isIOS) {
                _showMobileViewInput(true);
                if (initialRender.current) {
                  initialRender.current = false;
                }
              }
            }}
            onBlur={() => {
              dispatch(setToAutosuggestions([]));
            }}
            placeholder={content.placeholderTo}
            data={toAutosuggestions}
            onInputChange={throttledFetch}
            onSelect={(e) => {
              if (e) {
                dispatch(setSelectedToLocation(e));
                dispatch(setToAutosuggestions([e]));
              }
            }}
          />
        </div>
      )}
    </>
  );
});
