import React, { useEffect } from "react";
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom'
import { getUserPath } from "./Util/getUserPath";

import '../../style/User.css';
import { useSelector, useDispatch } from 'react-redux';

// IMPORT USER IMG
import userImages from "./user_images";

import { userData, isLoggedIn } from '../Auth/AuthSlice';

import Balance from './Content/Balance/Balance';
import Journeys from './Content/Journeys/Journeys';
import Compensation from './Content/Compensation';
import Parameter from './Content/Parameter';

import { useTranslation } from 'react-i18next';

export function User(match) {
    const { path } = useRouteMatch();
    const dispatch = useDispatch();  
    const isLogin = useSelector(isLoggedIn);
    // const randomImg = Math.random()
    const userDetail = useSelector(userData);
    const { t } = useTranslation();

    useEffect(() => {
      if(isLogin){
        dispatch(getUserPath())
      }
    }, [isLogin, dispatch]);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    if (!isLogin) {
        document.location.href = "/login"
        return ''
    }
   
    return (
      <div className="UserContainer">
        <div className="UserBanner">
          <div className="UserBannerCountainer">
            <img src={userImages[userDetail.avtar].src} alt="User" className="UserImg" style={{borderColor : userImages[userDetail.avtar].color}} />
            <div className="UserName">
              {userDetail.name}
            </div>

          </div>
        </div>

        <div className="UserHeadbar">
          <div className="UserHeadbarCountainer">
            <div className="UserHeadbarCountainer2">
              <Link style={{ textDecoration: 'none', color: "unset" }} role="span" to={`${path}/balance`}><h3>{t('Balance')}</h3></Link>
              <Link style={{ textDecoration: 'none', color: "unset" }} role="span" to={`${path}/journey`}><h3>{t('Journeys')}</h3></Link>
              
            </div>

            <Link style={{ textDecoration: 'none', color: "unset" }} role="span" to={`${path}/parameter`}><h3>{t('Parameters')}</h3></Link>
          </div>
        </div>
        <Switch>
          <Route path={`${path}/balance`} component={Balance} />
          <Route path={`${path}/journey`} component={Journeys} />
          <Route path={`${path}/compensation`} component={Compensation} />
          <Route path={`${path}/parameter`} component={Parameter} />
        </Switch>
      </div>
    );
};

// <Link style={{ textDecoration: 'none', color: "unset" }} role="span" to={`${path}/compensation`}><h3>Compensation</h3></Link>