import { createSlice } from '@reduxjs/toolkit';

export const auth = createSlice({
  name: 'user',
  initialState: {
    user: '',
    isAppLoading: true,
    isLoggedIn: false, // if will be false on load if token is not valid or available
  },
  reducers: {
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.isLoggedIn = true;
    },
    logout: state => {
      state.isLoggedIn = false;
      state.user = null;
    },
    setAppLoading: (state, action) => {
      state.isAppLoading = action.payload
    }
  },
});

export const { setLogin, logout, setAppLoading } = auth.actions;

export const isLoggedIn = state => {
  return state.user.isLoggedIn
};

export const selectIsAppLoading = state => { 
  return state.user.isAppLoading 
}

export const userData = state => {
  return state.user.user
};

export default auth.reducer;
