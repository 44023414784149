import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import '../../style/Footer.css'


export function Footer() {

    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div className='FooterContainer'>

            <div className='Footer'>

                <div className='FooterLinks'>
                    <span onClick={(e) => { 
                        e.preventDefault(); history.push("/privacy-policy");
                    }}>{t('Privacy')}</span>   
                    
                    <span onClick={(e) => { 
                        e.preventDefault(); history.push("/terms-of-use");
                    }}>{t('Terms of use')}</span>       

                    <span onClick={(e) => { 
                        e.preventDefault(); history.push("/cookies-policy");
                    }}>{t('Cookies policy')}</span>
                </div>


                <div className='Copyrigth'> 
                    <p>© moveco 2020</p>
                </div>        
    
            </div>

        </div>
    )

};