import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

// IMPORT CSS
import '../../../style/AddPathToBallance.css';

// IMPORT COMPONENTS
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';

// IMPORT DATA FROM SLICES
import { selectPathCar } from '../Results/PathList/Car/PathCarSlice';
import { selectPathBicycle } from '../Results/PathList/Bicycle/PathBicycleSlice';
import { selectPathPlane } from '../Results/PathList/Plane/PathPlaneSlice';
import { selectPathBus, selectDisplayedBusPath } from '../Results/PathList/Transit/Bus/PathBusSlice';
import { selectPathTrain, selectDisplayedTrainPath } from '../Results/PathList/Transit/Train/PathTrainSlice';
import { userData } from '../../Auth/AuthSlice';
import { selectAddPathToBallanceLoading, selectAddPathToBallanceSelected } from './AddPathToBallanceSlice';
import { selectDate, selectRegularDate, selectTimesPerWeek } from '../../SearchBar/DateSlice';
import { selectActivePath } from "../Results/PathList/PathListSlice";

// IMPORT UTILS
import { saveUserPath} from './Util/PostPath';
import { isLoggedIn } from '../../Auth/AuthSlice';

import { useTranslation } from 'react-i18next';


export function AddPathToBallance( { journeyType, journeyName, from, to } ) {

    const dispatch = useDispatch();
    const history = useHistory();
    // IMPORT THE STATES
    const activePath = useSelector(selectActivePath);
    const PathCar = useSelector(selectPathCar);
    const PathPlane = useSelector(selectPathPlane);
    const PathBicycle = useSelector(selectPathBicycle);
    const PathTrain = useSelector(selectPathTrain);
    const displayedTrainPath = useSelector(selectDisplayedTrainPath);
    const PathBus = useSelector(selectPathBus);
    const displayedBusPath = useSelector(selectDisplayedBusPath);
    const selectedRegularDate = useSelector(selectRegularDate);
    const timesPerWeek = useSelector(selectTimesPerWeek)
    const endDate = new Date(selectedRegularDate.endDate).toString();
    const startDate = new Date(selectedRegularDate.startDate).toString();
    const dateSpan = {
        endDate,
        startDate
    }

    const userDetail = useSelector(userData);

    const AddPathToBallanceLoading = useSelector(selectAddPathToBallanceLoading)
    const AddPathToBallanceAdded = useSelector(selectAddPathToBallanceSelected)

    const LogIn = useSelector(isLoggedIn);

    const selectedDate = useSelector(selectDate);

    const { t } = useTranslation();

    function postPath(){

        if (activePath.type === 'bus' && PathBus.routes.length) {
            let details = [];
            for(let j=0; j < PathBus.routes[displayedBusPath].steps.length; j++) {
                let detail = {
                    type: PathBus.routes[displayedBusPath].steps[j].vehicleModeStep ? PathBus.routes[displayedBusPath].steps[j].vehicleModeStep : 'walk',
                    distance: PathBus.routes[displayedBusPath].steps[j].distanceStep,
                    CO2: PathBus.routes[displayedBusPath].steps[j].CO2
                }
                details.push(detail); 
            }
            let params = {
                  user_ID: userDetail.userId,
                  totalCO2: PathBus.routes[displayedBusPath].totalCO2,
                  type: 'bus',
                  departure: from.substring( 0, from.indexOf(",")),
                  arrival: to.substring( 0, to.indexOf(",")),
                  date: journeyType === 'PAST_JOURNEY' ? selectedDate.toString() : (new Date (PathBus.routes[displayedBusPath].steps[0].departureTimeStep)).toString(),
                  steps: JSON.stringify(details)
                }
                if(journeyType === 'REGULAR_JOURNEY'){
                    params = {
                        ...params,
                        journeyName,
                        timesPerWeek,
                        dateSpan,
                        journeyType
                    }
                }
            dispatch(saveUserPath({
                params
            }))
        } else if (activePath.type === 'train' && PathTrain.routes.length) {
            let details = [];
            for (let j = 0; j < PathTrain.routes[displayedBusPath].steps.length; j++) {
                let detail = {
                    type: PathTrain.routes[displayedTrainPath].steps[j].vehicleModeStep ? PathTrain.routes[displayedTrainPath].steps[j].vehicleModeStep : 'walk',
                    distance: PathTrain.routes[displayedTrainPath].steps[j].distanceStep,
                    CO2: PathTrain.routes[displayedTrainPath].steps[j].CO2
                }
                details.push(detail);
            }
            let params = {
                user_ID: userDetail.userId,
                totalCO2: PathTrain.routes[displayedTrainPath].totalCO2,
                type: 'train',
                departure: from.substring(0, from.indexOf(",")),
                arrival: to.substring(0, to.indexOf(",")),
                date: journeyType === 'PAST_JOURNEY' ? selectedDate.toString() : (new Date(PathTrain.routes[displayedTrainPath].steps[0].departureTimeStep)).toString(),
                steps: JSON.stringify(details)
            }
            if (journeyType === 'REGULAR_JOURNEY') {
                params = {
                    ...params,
                    journeyName,
                    timesPerWeek,
                    dateSpan,
                    journeyType
                }
            }
            dispatch(saveUserPath({
                params
            }))
        } else if (activePath.type === 'car'  && PathCar.routes.length) {
            let details = [{
                type: 'car',
                distance: PathCar.routes[0].distance,
                CO2: PathCar.CO2displayed,
            }];
            let params = {
                user_ID: userDetail.userId,
                totalCO2: PathCar.CO2displayed,
                type: 'car',
                departure: from.substring(0, from.indexOf(",")),
                arrival: to.substring(0, to.indexOf(",")),
                date: selectedDate.toString(),
                steps: JSON.stringify(details)
            }
            if (journeyType === 'REGULAR_JOURNEY') {
                params = {
                    ...params,
                    journeyName,
                    timesPerWeek,
                    dateSpan,
                    journeyType
                }
            }
            dispatch(saveUserPath({
                params
            }))
        } else if (activePath.type === 'plane'  && PathPlane.routes.length) {
            let details = [{
                type: 'plane',
                distance: PathPlane.routes[0].distance * 1000,
                CO2: PathPlane.CO2displayed,
            }];
            let params = {
                user_ID: userDetail.userId,
                totalCO2: PathPlane.CO2displayed,
                type: 'plane',
                departure: from.substring(0, from.indexOf(",")),
                arrival: to.substring(0, to.indexOf(",")),
                date: journeyType === 'PAST_JOURNEY' ? selectedDate.toString() : (new Date(PathPlane.routes[0].Date)).toString(),
                steps: JSON.stringify(details)
            }
            if (journeyType === 'REGULAR_JOURNEY') {
                params = {
                    ...params,
                    journeyName,
                    timesPerWeek,
                    dateSpan,
                    journeyType
                }
            }
            dispatch(saveUserPath({
                params
            }))

        }
        else if (activePath.type === 'bicycle' && PathBicycle.routes.length) {
            let details = [{
                type: 'bicycle',
                distance: PathBicycle.routes[0].distance,
                CO2: PathBicycle.CO2displayed,
            }];
            let params = {
                user_ID: userDetail.userId,
                totalCO2: PathBicycle.CO2displayed,
                type: 'bicycle',
                departure: from.substring(0, from.indexOf(",")),
                arrival: to.substring(0, to.indexOf(",")),
                date: selectedDate.toString(),
                steps: JSON.stringify(details)
            }
            if (journeyType === 'REGULAR_JOURNEY') {
                params = {
                    ...params,
                    journeyName,
                    timesPerWeek,
                    dateSpan,
                    journeyType
                }
            }
            dispatch(saveUserPath({
                params
            }))
        }

    }

    function getCO2Displayed(selectedPath) {
        if (selectedPath === 'bus') {
            return (
                <div className='AddPathToBallanceCO2'>
                    <p >{PathBus.routes[displayedBusPath].totalCO2 > 10 ? Math.round(PathBus.routes[displayedBusPath].totalCO2) : Math.round(PathBus.routes[displayedBusPath].totalCO2 * 10) / 10} kgCO<sub>2</sub></p>
                </div>
            )
        } else if (selectedPath === 'train') {
            return (
                <div className='AddPathToBallanceCO2'>
                    <p>{PathTrain.routes[displayedTrainPath].totalCO2 > 10 ? Math.round(PathTrain.routes[displayedTrainPath].totalCO2) : Math.round(PathTrain.routes[displayedTrainPath].totalCO2 * 10) / 10} kgCO<sub>2</sub></p>
                </div>
            )
        } else if (selectedPath === 'car') {
            return (
                <div className='AddPathToBallanceCO2'>
                    <p>{PathCar.CO2displayed > 10 ? Math.round(PathCar.CO2displayed) : Math.round(PathCar.CO2displayed * 10) / 10} kgCO<sub>2</sub></p>
                </div>
            )
        } else if (selectedPath === 'plane') {
            return (
                <div className='AddPathToBallanceCO2'>
                    <p>{PathPlane.CO2displayed > 10 ? Math.round(PathPlane.CO2displayed) : Math.round(PathPlane.CO2displayed * 10) / 10} kgCO<sub>2</sub></p>
                </div>
            )
        } else if (selectedPath === 'bicycle') {
            return (
                <div className='AddPathToBallanceCO2'>
                    <p>{PathBicycle.CO2displayed > 10 ? Math.round(PathBicycle.CO2displayed) : Math.round(PathBicycle.CO2displayed * 10) / 10} kgCO<sub>2</sub></p>
                </div>
            )
        }
    }

      function findTransport(transportIn) {
        if(transportIn === 'plane') {
            return t('plane')
        } else if (transportIn === 'bicycle') {
            return t('bicycle')
        } else if (transportIn === 'car') {
            return t('car')
        } else if (transportIn === 'bus') {
            return t('bus')
        }   else if (transportIn === 'train') {
                return t('train') 
        } else {
            return transportIn
        }
      }

    return (
        <div className="AddPathToBallance">
            {!LogIn ?
                <>
                    <p><b>{t('Sign up')}</b><br />{t('and keep track of your emissions')}</p>
                    <button className="register-button" type="button" onClick={(e) => { e.preventDefault(); history.push("/register") }}>
                        Ok
            </button>
                </>
                : null
            }
            {!AddPathToBallanceAdded && LogIn ?
                <>
                    <p className='AddPathToBallanceFromTo'>{from.substring(0, from.indexOf(","))} {t('to AddPathToBalance')} {to.substring(0, to.indexOf(","))}</p>

                    <p>{/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? t('by').charAt(0).toUpperCase() + t('by').slice(1) : t('by')} {findTransport(activePath.type)}</p> 

                    {getCO2Displayed(activePath.type)}
                    {journeyType === 'REGULAR_JOURNEY' && <p>{t('per journey')}</p>}
                </>
                :
                null
            }
            {AddPathToBallanceAdded && LogIn ?
                <><p style={{fontWeight:'600'}}>{t('Journey added')}</p>
                    <CheckIcon style={{ marginTop: 7 }} size="10" color="#592689" />
                    <Link style={{ textDecoration: 'none', fontSize: '0.8rem', color: '#592689', fontWeight:'500' }} to={`/user/balance`}>{t('Check your balance')}</Link>
                </>
                : null
            }
            {AddPathToBallanceLoading && LogIn ?
                <CircularProgress style={{ marginTop: 7 }} size={20} color="#592689" />
                :
                null
            }
            {!AddPathToBallanceLoading && !AddPathToBallanceAdded && LogIn ?
                <><Icon
                    onClick={() => {
                        postPath();
                    }}
                    style={{ color: '#592689', fontSize: 30, cursor: 'pointer', marginTop: 7 }}>add_circle</Icon>
                    <p onClick={() => {
                        postPath();
                    }}><span style={{fontWeight:'500', cursor: 'pointer'}}>{t('Add to balance')}</span></p></>
                : null
            }
        </div>
    )
};