import React, { useState } from 'react'
import '../../style/Register.css';
// import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import Axios from '../../api/index';
import luggage from './luggage.png';
import { setLogin } from './AuthSlice';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import { useEffect } from 'react';
import { setAlert } from '../../app/AppSlice';
import CustomModal from '../../components/Modal/CustomModal';
import OTPInput from '../../components/OtpInputBox/OTPInput';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import GoogleSignInTerm from '../../components/GoogleSignInTerm';


export default function Login(props) {
  const { t, i18n } = useTranslation();
  const language = i18n.language === 'fr' ? 'French': 'English';
  const [error, setError] = useState("")
  const [state, setState] = useState({
    email: "",
    password: "",
  })
  const dispatch = useDispatch();

  const inputRefOne = useRef(null);
  const inputRefTwo = useRef(null);
  const inputRefThree = useRef(null);
  const inputRefFour = useRef(null);
  const [isLoading, _isLoading] = useState(false);
  const [otp, _otp] = useState({1:'',2:'',3:'',4:''});
  const [resend, _resend] = useState(false);
  const timerRef = useRef(null);
  const [showOTPModal, _showOTPModal] = useState(false);
  const [countDownText, _countDownText] = useState(null);
  const [expiresIn, _expiresIn] = useState(null);
  const [showForgotPassword, _showForgotPassword] = useState(false);
  const [showForgotDialog, _showForgotDialog] = useState(false);
  const [isShowGoogleTerm, _isShowGoogleTerm] = useState(false);
  const [currentGoogleToken, _currentGoogleToken] = useState(null);


  useEffect(()=>{
    if(expiresIn){
    timerRef.current = setInterval(()=>{
      const now = new Date().getTime();
        const distance = expiresIn - now;
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);
        let displaySeconds = seconds < 10 ? `0${seconds}` : seconds;
        const displayText = `${minutes}:${displaySeconds}`
        _countDownText(displayText)
        if(minutes === -1){
          _countDownText(null);
          _expiresIn(null);
          return () => clearInterval(timerRef.current);
        }
    },1000)
  } else {
    return () => clearInterval(timerRef.current);
  }
  return () => clearInterval(timerRef.current);
  },[expiresIn]);

  const handleResend = () => {
    _otp({1:'',2:'',3:'',4:''});
    _countDownText(null);
    _expiresIn(null);
    _resend(true);
    handleSubmit();
  }

  const closeModal = () => {
    _showOTPModal(false);
    _expiresIn(null);
    _otp({1:'',2:'',3:'',4:''});
    _countDownText(null);
  }

  // const onFailureHandler = () => {
  //  setError("Something went wrong please try again.")
  // }

  // const responseFacebook = (response) => {
    // console.log("responseFacebook", response);
    // var data = {
    //   first_name: response.name,
    //   last_name: "",
    //   email: response.email,
    // }
    // Axios.post('/auth/register', data)
    // dispatch(setLogin(data))
    // localStorage.setItem("users", btoa(JSON.stringify(data)))
    // props.history.push('/')
  // }


  // const componentClicked = (response) => {
  //  console.log(response);
  // }

  const responseGoogle = async (response, withTerm = null) => {
    try {
      let payload = {
        type: 'google',
        accessToken: response.accessToken
      }
      if(withTerm === 'accept') {
        payload.confirmation = 'accept';
      }
      const {data} = await Axios.post('/auth/google-login', payload);
      if(data.message === 'accept or decline the sign up with google term') {
        _currentGoogleToken(response.accessToken);
        _isShowGoogleTerm(true);
        return;
      }
      if(data.user){
        dispatch(setLogin({user: data.user}));
        dispatch(setAlert({message: t("Successfully logged in"), showAlert: true, duration: 6000, severity:'success'}));
      }
      if(data.message === "You are already logged in with email, We have merged with your google account"){
        dispatch(setAlert({message: t("We have merged your account with your Google account") ,showAlert: true, duration: 6000, severity:'success'}));
      }
    } catch (err) {
      if(err.response){
        dispatch(setAlert({message: err.response.data.message,showAlert: true, duration: 6000, severity:'error'}));
        setError(err.response.data.message);
      }
    } 
  }

  const handleOnChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (e = null) => {
    if(e) e.preventDefault();
    try {
      _isLoading(true);
      const { data } = await Axios.post('/auth/login',{...state, language});
      if(data.expiresIn){
        _expiresIn(new Date(data.expiresIn));
        _showOTPModal(true);
        if(inputRefOne.current)inputRefOne.current.focus();
      } else if(data.message === "Login success"){
        dispatch(setAlert({message: t("Successfully logged in"), showAlert: true, duration: 6000, severity:'success'}));
        dispatch(setLogin({user: data.user})); 
      }
      _resend(false);
      _isLoading(false);
    } catch (err) {
      _resend(false);
      _isLoading(false);
      if(err.response){
        if(err.response.data.message === "User not found.") {
          _showForgotPassword(false);
        }
        if(err.response.data.message === "Invalid password.") {
          _showForgotPassword(true);
        }
        if (err.response.data?.message.includes('email')
          || err.response.data?.message.includes('password') ||
          err.response.data?.message.includes('User')
        ) {
          setError(err.response.data.message);
          return;
        }
        dispatch(setAlert({message: err.response.data.message,showAlert: true, duration: 6000, severity:'error'}));
      }  
    }
  }

  const otpChangeEvent = (e) => {
    let {key} = e;
    const {name} = e.target;
    if(key === "Backspace" || key === "Delete"){
      _otp({...otp,[Number(name)]:''})
      if(name!== "1"){
        if(name === "2")inputRefOne.current.focus(); 
        if(name === '3')inputRefTwo.current.focus();
        if(name === "4")inputRefThree.current.focus();
      }
      return
    }
    if (!key.match(/[0-9]/)) {
      // if(key === "Shift" || key === "CapsLock") return;
        _otp({...otp,[name]: ''});
        e.preventDefault();
        e.target.value = ''
        return
    }
    key = Number(key)
    if(key === 0 && otp[4] === 0){
      e.preventDefault();
      e.target.value = 0
      return;
    }
    if((key >= 0 && key < 10)){
      _otp({...otp,[name]: key});
      if(name === "1")inputRefTwo.current.focus(); 
      if(name === '2')inputRefThree.current.focus();
      if(name === "3")inputRefFour.current.focus();
      if(name === "4")inputRefFour.current.blur();
    }
  }

  const submitRegistration = async () => {
    // OTP and email
    const temp = Number(`${otp[1]}${otp[2]}${otp[3]}${otp[4]}`);
    const email = state.email
    try {
      _isLoading(true);
      const {data} = await Axios.post('/auth/register/verify&register',{otp:temp, email});
       _isLoading(false);
       _showOTPModal(false);
       _otp({1:'',2:'',3:'',4:''});
       dispatch(setLogin({user: data.user}))
       dispatch(setAlert({message: t("Your account is successfully registered and verified"), showAlert: true, duration: 6000, severity:'success'}));
    } catch (error) {
      if(error.response){
        setError(error.response.data.message);
       if(error.response.data.message === "Too Many attempts, Please try again tomorrow!"){
          _showOTPModal(false);
          dispatch(setAlert({message: t("Too Many attempts, please try again later!"),showAlert: true, duration: 6000, severity:'error'}));
       }
       if(error.response.data.message === "Invalid OTP"){
        // let user know
        _otp({1:'',2:'',3:'',4:''});
        dispatch(setAlert({message: t("Invalid code"),showAlert: true, duration: 6000, severity:'error'}));
       }
       if(error.response.data.message === "OTP Expired"){
        // let user know
        dispatch(setAlert({message: t('Code expired'),showAlert: true, duration: 6000, severity:'error'}));
       }
      }
      _isLoading(false);
    }
  }

  const requestForgotPassword = async () => {
    _isLoading(true);
    try {
      await Axios.post('/auth/request-forgot-password',{email: state.email});
      dispatch(setAlert({message: t("Check your e-mail to set a new password"),showAlert: true, duration: 6000, severity:'success'}));
    } catch (error) {
      if(error.response){
        dispatch(setAlert({message: error.response.data.message,showAlert: true, duration: 6000, severity:'error'}));
      }
    }
    _isLoading(false);
    _showForgotDialog(false);
  }

  return (
    <>
    <div className="RegisterContainer">
      <section className="sign-in">
        <div className="container">
          <div className="signin-content">
            <div className="signin-image">
              <figure><img src={luggage} alt="signupimage" /></figure>
              <Link to="/register" className="signup-image-link">{t("Create an account")}</Link>
            </div>

            <div className="signin-form">
              <h2 className="form-title">{t("Sign in")}</h2>
              <p>{error}</p>
              <form method="POST" className="register-form" id="login-form" onSubmit={handleSubmit} >
                <div className="form-group">
                  <label htmlFor="email"><i className="zmdi zmdi-email"></i></label>
                  <input type="email" autoComplete={"false"} name="email" required onChange={handleOnChange} id="email" placeholder="Email" />
                </div>
                <div className="form-group">
                  <label htmlFor="pass"><i className="zmdi zmdi-lock"></i></label>
                  <input type="password" autoComplete={"false"} name="password" required onChange={handleOnChange} id="pass" placeholder={t("Password")} />
                </div>
                {showForgotPassword && <Button onClick={()=>{_showForgotDialog(true)}} color="primary">{t("Forgot password?")}</Button>}
                <div className="form-group form-button">
                  <input type="submit" name="signin" id="signin" className="form-submit" value={t("Log in")} />
                </div>
              </form>
              <p className="social-label">{t("Or login with")}</p><br />
              <div className="social-login">
                <i className="display-flex-center zmdi zmdi-google"></i>
                <GoogleLogin
                  clientId={"91140303370-4bek32qk73h5fpfe2mlcmhmpbi9c4ndu.apps.googleusercontent.com"}
                  buttonText={t("Login with Google")}
                  onSuccess={responseGoogle}
                  className="g-btn"
                  icon={true}
                  onFailure={(err)=>{console.log(err)}}
                  cookiePolicy={'single_host_origin'}
                  theme={"light"}
                  prompt="select_account"
                />


              </div>
            </div>
          </div>
        </div>
      </section>

    </div >
    <GoogleSignInTerm
        title={t("Confirm your account creation")}
        description={t("You are about to create an account on moveco. We will use your email and name from your Google account. You can delete your moveco account and all the data linked whenever you want.")}
        show={isShowGoogleTerm}
        onAccept={async () => {
          _isShowGoogleTerm(false);
          await responseGoogle({
            accessToken: currentGoogleToken,
          }, 'accept');
        }}
        onCancel={() => {
          _isShowGoogleTerm(false);
          _currentGoogleToken(null);
        }}
      />
    {showOTPModal && <CustomModal onClose={closeModal}>
      <div className="modal-content">
        <div className="main-text">
          <span>{t("We have sent you a code to your email to verify your account")}</span>
          <div>
            <p><i>{t("Remember to also check your spam folder")}</i></p>
            <p>{t("Please enter the code")}</p>
            <p>{expiresIn && countDownText}</p>
          </div>
        </div>
        <div className="otp-container">
            <OTPInput ref={inputRefOne}   value={otp[1]} name={1} onKeyUp={otpChangeEvent} />
            <OTPInput ref={inputRefTwo}   value={otp[2]} name={2} onKeyUp={otpChangeEvent} />
            <OTPInput ref={inputRefThree} value={otp[3]} name={3} onKeyUp={otpChangeEvent} />
            <OTPInput ref={inputRefFour}  value={otp[4]} name={4} onKeyUp={otpChangeEvent} />
        </div>
          <div className="btn-container">
            <PrimaryButton isLoading={resend} disabled={Boolean(expiresIn)} onClick={handleResend}>{t("Resend the code")}</PrimaryButton>
              <PrimaryButton
                isLoading={
                  isLoading &&
                  otp[1] !== "" &&
                  otp[2] !== "" &&
                  otp[3] !== "" &&
                  otp[4] !== ""
                }
                disabled={
                  otp[1] === "" ||
                  otp[2] === "" ||
                  otp[3] === "" ||
                  otp[4] === ""
                }
                onClick={submitRegistration}
              >
                {t('Verify email')}
            </PrimaryButton>
          </div>
        </div>
    </CustomModal>}
    <Dialog open={showForgotDialog}>
      <DialogTitle id="form-dialog-title">{t("Forgot password?")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p>{t("We will send you an email with instruction to set a new password")}</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={()=>{_showForgotDialog(false)}} color="primary">
            {t("Cancel")}
          </Button>
          <Button disabled={isLoading} onClick={requestForgotPassword} color="primary">
            {t("Send me the instructions")}
          </Button>
      </DialogActions>
    </Dialog>
    </>
  )
}

/*

                <i className="display-flex-center zmdi zmdi-facebook"></i>
                <FacebookLogin
                  appId="387035795811736"
                  cssClass="fb-btn"
                  fields="name,email,picture"
                  onClick={componentClicked}
                  onFailure={onFailureHandler}
                  callback={responseFacebook} />



*/