/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { selectActivePath, setActivePath, setDisplayedPath, setHoveredPath } from "../PathListSlice";
import styles from "./DetailedSelectedPath.module.css";
import { selectPathTrain, setdisplayedTrainPath } from "../Transit/Train/PathTrainSlice";
import Card from "./Card";
import { selectPathCar, setdisplayedCarPath, selectPassengersNumber, selectconventionalCar, selectelectricCar, selectElectric } from "../Car/PathCarSlice";
import { selectPathBus, setdisplayedBusPath } from "../Transit/Bus/PathBusSlice";
import { selectPathPlane } from "../Plane/PathPlaneSlice";
import { setCalculationDetails } from "../CalculationDetails/CalculationDetailsSlice";
import moment from "moment";
import { secondsToHm } from "../Util/secondsToHm";
import { useTranslation } from "react-i18next";

import TrainLineLogo from '../Transit/Train/trainlineLogo.png';
import OuiSNCFLogo from '../Transit/Train/OuiSNCFLogo.png';
import FlixBusLogo from '../Transit/Bus/FlixBusLogo.png';
import BudgetLogo from '../Car/BudgetLogo.png';
import BlablacarLogo from '../Car/blablacar.png';
import MobicoopLogo from '../Car/mobicoop.png';

const DetailedSelectedPath = ({ title, journeyType }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activePath = useSelector(selectActivePath);
  const [showDetails, _showDetails] = useState(null);
  const [active, _active] = useState(null);
  const { t } = useTranslation();
  const PathCar = useSelector(selectPathCar);
  const PassengersNumber = useSelector(selectPassengersNumber);
  const ConventionalCar = useSelector(selectconventionalCar);
  const ElectricChosen = useSelector(selectElectric);
  const ElectricCar = useSelector(selectelectricCar);
  const PathBus = useSelector(selectPathBus);
  const PathTrain = useSelector(selectPathTrain);
  const PathPlane = useSelector(selectPathPlane);
  
  // selectCard for plane, car, bicycle
  useEffect(() => {
    if (activePath.type === "plane" && title === "plane") {
      _active(0);
    }
    return null
  }, [activePath.type, title])

  // select Card for bus and train
  useEffect(() => {
    if(activePath){
      if(activePath.type === 'bus' && title === "bus" && PathBus.routes.length){
        const index = PathBus.routes.findIndex((o) => o.id === activePath.id )
        if(index >= 0) {
          _active(index);
        }
      } else if(activePath.type === 'train' && PathTrain.routes.length && title === "train"){
        const index = PathTrain.routes.findIndex((o) => o.id === activePath.id )
        if(index >= 0) {
          _active(index);
        }
      } else if(activePath.type === 'car' && PathCar.routes.length && title === "car"){
        const index = PathCar.routes.findIndex((o) => o.id === activePath.id )
        if(index >= 0) {
          _active(index);
        }
      }
    }
  }, [PathBus, PathTrain, PathCar, activePath, title])


  return (
    <div className={styles["d-container"]}>
      <a
        onClick={() => history.goBack()}
        className={styles.btn__back}
        href="#"
      >
        &#8592; &nbsp; {t("Back to results")}
      </a>
      <div className={styles.title}>{t(title)}</div>
      <div className={styles["main-path-container"]}>
        {title === 'train' && PathTrain.routes.map((route, i) => (
          <Card
            journeyType={journeyType}
            co2Text={route.totalCO2}
            departureTime={moment(route.steps.find(e => e.travelTypeStep !== 'pedestrian').departureTimeStep).format("HH:mm")}
            arrivalTime={moment(Array(...route.steps).reverse().find(e => e.travelTypeStep !== 'pedestrian').arrivalTimeStep).format("HH:mm")}
            onClickCo2={() => { dispatch(setCalculationDetails()) }}
            selected={active === i ? true : false}
            key={i}
            smallTitle={i === 0 ? t('Best route') : `${t('Alternative')} ${i}`}
            route={route}
            path={title}
            clickOnShowDetails={(e) => {
              e.stopPropagation();
              i === showDetails ? _showDetails(null) : _showDetails(i)
            }
            }
            onClick={() =>{if(active !== i) {_active(i); dispatch(setDisplayedPath('train')); dispatch(setdisplayedTrainPath(i)); dispatch(setActivePath(PathTrain.routes[i])) };}}
            onMouseEnter={() => {if(active !== i) {dispatch(setHoveredPath(PathTrain.routes[i])) } }}
            onMouseLeave={() => { dispatch(setHoveredPath('')) }}
            showDetails={showDetails === i ? true : false}
          />
        ))}
        {title === 'bus' && PathBus.routes.map((route, i) => (
          <Card
            journeyType={journeyType}
            departureTime={moment(route.steps.find(e => e.travelTypeStep !== 'pedestrian').departureTimeStep).format("HH:mm")}
            arrivalTime={moment(Array(...route.steps).reverse().find(e => e.travelTypeStep !== 'pedestrian').arrivalTimeStep).format("HH:mm")}
            onClickCo2={() => { dispatch(setCalculationDetails()) }}
            co2Text={route.totalCO2}
            selected={active === i ? true : false}
            key={i}
            smallTitle={i === 0 ? t('Best route') : `${t('Alternative')} ${i}`}
            route={route}
            path={title}
            clickOnShowDetails={(e) => {
              e.stopPropagation();
              i === showDetails ? _showDetails(null) : _showDetails(i)
            }
            }
            showDetails={showDetails === i ? true : false}
            onClick={() => { if(active !== i) {_active(i); dispatch(setdisplayedBusPath(i));dispatch(setDisplayedPath('bus')); dispatch(setActivePath(PathBus.routes[i]))};}}
            onMouseEnter={() => {if(active !== i) {dispatch(setHoveredPath(PathBus.routes[i])) } }}
            onMouseLeave={() => { dispatch(setHoveredPath('')) }}
          />
        ))}
        {title === 'car' && PathCar.routes.map((route, i) => (
          <Card
            journeyType={journeyType}
            key={i}
            departureTime=''
            arrivalTime={`${secondsToHm(route.duration, {hour: t('hours'), hours: t('hours'), minute: t('minute'), minutes: t("minutes")})}`}
            onClickCo2={() => { dispatch(setCalculationDetails()) }}
            co2Text={ElectricChosen ?
              ((ElectricCar.manufacture + ElectricCar.infrastructure) * route.distanceByCar/1000 + ElectricCar.electricity_CO2_Factor.co2 * route.totalCarConsumption)/ (PassengersNumber) + route.totalCO2Transit
              :
              ((ConventionalCar.manufacture + ConventionalCar.infrastructure + ConventionalCar.operation) * route.distanceByCar/1000)/ (PassengersNumber) + route.totalCO2Transit
            }
            selected={active === i ? true : false}
            smallTitle={i === 0 ? t('Best route') : `${t('Alternative')} ${i}`}
            route={route}
            path={title}
            clickOnShowDetails={(e) => {
              e.stopPropagation();
              i === showDetails ? _showDetails(null) : _showDetails(i)
            }
            }
            showDetails={showDetails === i ? true : false}
            onClick={ () => { if(active !== i) {
              _active(i); 
              dispatch(setdisplayedCarPath(i));
              dispatch(setDisplayedPath('car'));
              dispatch(setActivePath({...PathCar.routes[i]}));
            }}}
            onMouseEnter={() => {if(active !== i) {
              dispatch(setHoveredPath(({...PathCar.routes[i]})))
            }}}
            onMouseLeave={() => { dispatch(setHoveredPath('')) }}
          />
        ))}
        {title === 'plane' && PathPlane.routes.map((route, i) => (
          <Card
            journeyType={journeyType}
            onClickCo2={() => { dispatch(setCalculationDetails()) }}
            co2Text={PathPlane.CO2displayed}
            departureTime={moment(PathPlane.routes[0].Date).format("HH:mm")}
            arrivalTime={moment(PathPlane.routes[PathPlane.routes.length - 1].Date).format("HH:mm")}
            selected={active === i ? true : false}
            key={i}
            smallTitle={i === 0 ? t('Best route') : `${t('Alternative')} ${i}`}
            path={title}
            route={route}
            clickOnShowDetails={(e) => {
              e.stopPropagation();
              i === showDetails ? _showDetails(null) : _showDetails(i)
            }
            }
            showDetails={showDetails === i ? true : false}
            onClick={() => {_active(i);dispatch(setDisplayedPath('plane'));}}
          />
        ))}

      {journeyType !== 'PAST_JOURNEY' ?

        <div className={styles['container-booking']} id='container-booking'>
       
          {title === 'bus' || title === 'train' || title === 'plane' ?
            <h3 style={{marginBlock:0}}>{t("Book now")}</h3>
          : null}

          {title === 'bus' ? 
              <div style={{display:'flex', justifyContent: 'space-between', padding: '0 10px', marginTop:'15px'}}>
                <div style={{width:'60%', alignSelf: 'center'}}><a href="https://tc.tradetracker.net/?c=27640&amp;m=12&amp;a=401491&amp;r=&amp;u=" target="_blank" rel="noreferrer">{t("FlixBus is one of Europe’s leading coach companies. Choose your route from their extensive route network of over 2,500 destinations in 35 European countries.")}</a></div>
                <div ><a href={`${process.env.REACT_APP_API}/openofferpageflixbus`} target="_blank" rel="noreferrer"><img src={FlixBusLogo} alt="TrainLine logo" style={{height:'25px'}}/></a></div>
              </div>
            : null}

          {title === 'bus' || title === 'train' ? 
            <div style={{display:'flex', justifyContent: 'space-between', padding: '0 10px', marginTop:'10px'}}>
              <div style={{width:'60%', alignSelf: 'center'}}><a href={`${process.env.REACT_APP_API}/openofferpage`} target="_blank" rel="noreferrer">{t("Explore Europe effortlessly by train and bus.")}</a></div>
              <div style={{alignSelf: 'center'}}><a style={{textDecoration: 'none'}} href={`${process.env.REACT_APP_API}/openofferpagetrainline`} target="_blank" rel="noreferrer"><img src={TrainLineLogo} alt="TrainLine logo" style={{height:'40px'}}/></a></div>
            </div>
          : null}

          {title === 'train' ? 
            <div style={{display:'flex', justifyContent: 'space-between', padding: '0 10px', marginTop:'15px'}}>
              <div style={{width:'60%', alignSelf: 'center'}}><a href="https://track.effiliation.com/servlet/effi.click?id_compteur=22617169" target="_blank" rel="noreferrer">{t("Oui.sncf sells passes and point-to-point tickets for rail travel around Europe.")}</a></div>
              <div style={{alignSelf: 'center'}}><a href="https://track.effiliation.com/servlet/effi.click?id_compteur=22617169" target="_blank" rel="noreferrer"><img src={OuiSNCFLogo} alt="TrainLine logo" style={{height:'30px'}}/></a></div>
            </div>
          : null}

          {title === 'car' ?
          <> 
            <h3 style={{marginBlock:0}}>{t("Share your ride")}</h3>
            <div style={{display:'flex', justifyContent: 'space-between', padding: '0 10px', marginTop:'15px'}}>
              <div style={{width:'60%', alignSelf: 'center'}}><a href="https://www.blablacar.fr/" target="_blank" rel="noreferrer">{t("Your ride. Your choice.")}</a></div>
              <div style={{alignSelf: 'center'}}><a href="https://www.blablacar.fr/" target="_blank" rel="noreferrer"><img src={BlablacarLogo} alt="TrainLine logo" style={{height:'30px'}}/></a></div>
            </div>
            <div style={{display:'flex', justifyContent: 'space-between', padding: '0 10px', marginTop:'15px'}}>
              <div style={{width:'60%', alignSelf: 'center'}}><a href="https://clk.tradedoubler.com/click?p=283783&a=3211186" target="_blank" rel="noreferrer">{t("Free carpooling, commission-free and cooperative.")}</a></div>
              <div style={{alignSelf: 'center'}}><a href="https://track.effiliation.com/servlet/effi.click?id_compteur=22617169" target="_blank" rel="noreferrer"><img src={MobicoopLogo} alt="TrainLine logo" style={{height:'30px'}}/></a></div>
            </div>

            <h3 style={{marginBlock:0}}>{t("Hire a car")}</h3>
            <div style={{display:'flex', justifyContent: 'space-between', padding: '0 10px', marginTop:'15px'}}>
              <div style={{width:'60%', alignSelf: 'center'}}><a href="https://clk.tradedoubler.com/click?p=283783&a=3211186" target="_blank" rel="noreferrer">{t("Do car hire your way.")}</a></div>
              <div style={{alignSelf: 'center'}}><a href="https://track.effiliation.com/servlet/effi.click?id_compteur=22617169" target="_blank" rel="noreferrer"><img src={BudgetLogo} alt="TrainLine logo" style={{height:'30px'}}/></a></div>
            </div>
          </>
          :null }

        </div>
        :null}
      </div>
    </div>
  );
};
export default React.memo(DetailedSelectedPath);
