import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import styles from "./PrimaryInput.module.css";

const PrimaryInput = forwardRef(
  (
    {
      style,
      placeholder,
      name,
      value,
      onChange,
      onKeyDown,
      onFocus,
      disabled,
      className
    },
    ref
  ) => {
    return (
      <input
        ref={ref}
        style={style}
        className={`${styles.input} ${className}`}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        disabled={disabled}
      />
    );
  }
);

PrimaryInput.defaultProps = {
  style: {},
  name: "",
  value: "",
  placeholder: "",
  disabled: false,
  className: "",
  onChange: () => {},
  onKeyDown: () => {},
  onFocus: () => {}
};

PrimaryInput.propTypes = {
  style: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  className: PropTypes.string
};

export default PrimaryInput;
