import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// CSS IMPORT
import '../../style/SearchBar.css';

// COMPONENT IMPORT
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import frLocale from "date-fns/locale/fr";
import { useTranslation } from 'react-i18next';
// import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { FromInput } from './From/FromInput';
import { ToInput } from './To/ToInput';
import { setDate, selectDate} from './DateSlice';

import { askTransitPath } from '../MapApp/Results/PathList/Transit/Util/askTransitPath';

import { askCarPath } from '../MapApp/Results/PathList/Car/Util/askCarPath';
import { askElectricityPrice } from '../MapApp/Results/PathList/Car/Util/askElectricityPrice';
import { askElectricityCO2Factor } from '../MapApp/Results/PathList/Util/askElectricityCO2Factor';
import { askGazolinePrice } from '../MapApp/Results/PathList/Car/Util/askGazolinePrice';
import { askPlanePath } from '../MapApp/Results/PathList/Plane/Util/askPlanePath';
import { askBicyclePath } from '../MapApp/Results/PathList/Bicycle/Util/askBicyclePath';
import { selectSelectedToLocation } from './To/ToAutosuggestionsSlice';
import { selectSelectedFromLocation } from './From/FromAutosuggestionsSlice';
import { setPaths } from '../User/UserPathSlice';
import { setAddPathToBallance } from '../MapApp/AddPathToBallance/AddPathToBallanceSlice';
import { setActivePath, setDisplayedPath, setHoveredPath } from '../MapApp/Results/PathList/PathListSlice';
import { setPathCar, resetPassengerNumber, resetElectric } from '../MapApp/Results/PathList/Car/PathCarSlice';
import { setPathBus } from '../MapApp/Results/PathList/Transit/Bus/PathBusSlice';
import { setPathTrain } from '../MapApp/Results/PathList/Transit/Train/PathTrainSlice';
import { setPathPlane } from '../MapApp/Results/PathList/Plane/PathPlaneSlice';
import { setAlert } from '../../app/AppSlice';
import InverseArrowButton from '../../components/InverseArrowButton';


const customTheme = createMuiTheme({
	palette: {
		primary: {
			main: '#F5F5F5',
		},
		secondary: {
			main: '#592689',
		},
    },
    typography: {
        htmlFontSize: 18,
      },
})


export const SearchBar = React.memo( () => {
    const {t, i18n} = useTranslation();
    let history = useHistory();
    const dispatch = useDispatch();
    const from = useSelector(selectSelectedFromLocation);
    const to = useSelector(selectSelectedToLocation);
    const selectedDate = useSelector(selectDate);

    function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {

        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2-lat1);  // deg2rad below
        var dLon = deg2rad(lon2-lon1); 
        var a = 
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
          Math.sin(dLon/2) * Math.sin(dLon/2)
          ; 
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        var d = R * c; // Distance in km
        return d;
      }
      
      function deg2rad(deg) {
        return deg * (Math.PI/180)
      }

    return (
    
        <div className="Searchbar">
    
            <div className="Searchbar-content">
    
                <h2>{t("What is the most sustainable way to travel?")}</h2>
    
                <div className="SearchBar-fields">
        
                    <><FromInput /></>
                                
                    <InverseArrowButton />

                    <ToInput />

                </div>

                <div className="SearchBar-fields-DateAndHour">
    
   
                    <div className='DateAndTimePicker'>

                    <MuiThemeProvider theme={customTheme}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === 'fr' ? frLocale : null}>
                            <KeyboardDatePicker
                                id="time-picker"
                                variant={/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? "standard" : "inline"}
                                format={i18n.language === 'fr' ? "dd MMM yyy" : "dd MMM yyy"}
                                ampm={false}
                                autoOk={true}
                                disablePast={true}
                                value={selectedDate}
                                onChange={(date) => {
                                    dispatch(setDate(date.toString()))
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
    
                    </div>
    
                </div>

                <div className='SearchBar-submit-container'>
                    <button className="SearchBar-submit" 
                        onClick = {() => {
                            if(from && to){
                                dispatch(setActivePath(''));
                                dispatch(setHoveredPath(''));
                                dispatch(setPaths([]));
                                dispatch(setAddPathToBallance(false));
                                dispatch(setDisplayedPath(''));
                                dispatch(setPathCar({id: 'car',routes: [],CO2displayed: 0}));
                                dispatch(resetElectric());
                                dispatch(resetPassengerNumber());
                                dispatch(setPathBus({id: 'bus',routes: [],CO2displayed: 0 }));
                                dispatch(setPathTrain({id: 'train',routes: [],CO2displayed: 0 }));
                                dispatch(askCarPath([from, to, (new Date(selectedDate)).toISOString()])).then(() => {
                                        dispatch(askElectricityPrice(from.country))
                                        .then(dispatch(askElectricityCO2Factor(from.country)))
                                        .then(dispatch(askGazolinePrice(from.country)))                          
                                });
                                if(getDistanceFromLatLonInKm(from.position.lat, from.position.lng, to.position.lat, to.position.lng) < 70) {
                                    dispatch(askBicyclePath([from, to, (new Date(selectedDate)).toISOString()]));
                                }
                                if(getDistanceFromLatLonInKm(from.position.lat, from.position.lng, to.position.lat, to.position.lng) < 500) {
                                    dispatch(setPathPlane(['CO2', 0.258]));
                                }
                                else if(getDistanceFromLatLonInKm(from.position.lat, from.position.lng, to.position.lat, to.position.lng) < 5000) {
                                    dispatch(setPathPlane(['CO2', 0.187]));
                                } else {
                                    dispatch(setPathPlane(['CO2', 0.152]));
                                }
                                dispatch(askTransitPath([from, to, (new Date(selectedDate)).toISOString()]));
                                dispatch(askPlanePath([from, to, (new Date(selectedDate)).toISOString(), getDistanceFromLatLonInKm(from.position.lat, from.position.lng, to.position.lat, to.position.lng)]));
                                history.push('/map')
                            } else if (!from && !to) {
                                dispatch(setAlert({message: t("We haven't found your departure and arrival points"),showAlert: true, duration: 6000, severity:'error'}));
                            } else if (!from && to) {
                                dispatch(setAlert({message: t("We haven't found your departure point"),showAlert: true, duration: 6000, severity:'error'}));
                            } else {
                                dispatch(setAlert({message: t("We haven't found your arrival point"),showAlert: true, duration: 6000, severity:'error'}));
                            }                        
                        }}
                    >
                        <span role="button" href='#' style={{textDecoration: "none"}}                           
                        >{t("LetsGo")}</span>
                    </button>               
                    </div>

            </div>

        </div>    
    );
})