import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  BarChart, Bar, XAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

import "./CalculationDetails.css";

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { selectCalculationDetails, setCalculationDetails } from './CalculationDetailsSlice';
import { selectDisplayedPath } from '../PathListSlice';
import { selectPathBus, selectDisplayedBusPath } from '../Transit/Bus/PathBusSlice';
import { selectPathTrain, selectDisplayedTrainPath } from '../Transit/Train/PathTrainSlice';
import { selectDisplayedCarPath, selectconventionalCar, selectelectricCar, selectElectric, selectPathCar, selectPassengersNumber } from '../Car/PathCarSlice';
import { selectPathBicycle, selectBicycleCO2 } from '../Bicycle/PathBicycleSlice';
import { selectPathPlane, selectPlaneCO2 } from '../Plane/PathPlaneSlice';

import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    width: '60%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    width: 'auto'
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CalculationDetails(props) {

  const dispatch = useDispatch();

  // IMPORT THE STATES
  const isCalculationDetailOpen = useSelector(selectCalculationDetails);
  const selectedPath = useSelector(selectDisplayedPath);
  const PathTrain = useSelector(selectPathTrain);
  const displayedTrainPath = useSelector(selectDisplayedTrainPath);
  const PathBus = useSelector(selectPathBus);
  const displayedBusPath = useSelector(selectDisplayedBusPath);
  const PathCar = useSelector(selectPathCar)
  const ConventionalCar = useSelector(selectconventionalCar);
  const ElectricCar = useSelector(selectelectricCar);
  const IsElectricityChosen = useSelector(selectElectric);
  const passengersNumber = useSelector(selectPassengersNumber);
  const PathBicycle = useSelector(selectPathBicycle);
  const BicycleCO2 = useSelector(selectBicycleCO2);
  const PathPlane = useSelector(selectPathPlane);
  const PlaneCO2 = useSelector(selectPlaneCO2);
  const DisplayedCarPath = useSelector(selectDisplayedCarPath)

  const { t, i18n } = useTranslation();
  const language = i18n.language === 'fr' ? 'French': 'English';

  const classes = useStyles();

  if (selectedPath === 'bus' || selectedPath === 'train') {

    let data = [];
    let pathSelection = () => { 
      if (selectedPath === 'bus') {
        return PathBus.routes[displayedBusPath]
      } else if (selectedPath === 'train') {
        return PathTrain.routes[displayedTrainPath]
      }
    };

    for(let i=0, part = 0; pathSelection().steps.length > i; i++) {
      let onedata = {};
      part = part +1;
      if (pathSelection().steps[i].travelTypeStep !== 'pedestrian') {


        onedata = {
          id: i,
          lastid : pathSelection().steps[i].id,
          part: `${t('Part')} ${part}`,
          tick: `${t('Part')} ${part} (${t(pathSelection().steps[i].vehicleModeStep)})`,
          departureStopStep: pathSelection().steps[i].departureStopStep,
          arrivalStopStep: pathSelection().steps[i].arrivalStopStep,
          travelTypeStep: pathSelection().steps[i].travelTypeStep,
          infrastructure: Math.round((pathSelection().steps[i].CO2.infrastructure * pathSelection().steps[i].distanceStep /1000)*100)/100,
          manufacture: Math.round((pathSelection().steps[i].CO2.manufacture *pathSelection().steps[i].distanceStep /1000)*100)/100,
          operation: Math.round((pathSelection().steps[i].CO2.operation *pathSelection().steps[i].distanceStep /1000)*100)/100
        }

        data.push(onedata)
      }
    }


    return (
      <div>
  
        <Dialog 
        onClose={() => {dispatch(setCalculationDetails)}} 
        aria-labelledby="customized-dialog-title" 
        open={isCalculationDetailOpen}
        maxWidth="md">
          <DialogTitle id="customized-dialog-title" onClose={() => {dispatch(setCalculationDetails())}}>
            {t("Calculation details")}
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              <p>{t("Let's look into the details of your")} {selectedPath}{language === 'English' ? ' route' : null}.</p>
              <p>{t("We take into consideration three different types of emissions")}</p>
              <ul>
                <li style={{fontSize:'15px'}}>{t("Operation emissions")}</li>
                <li style={{fontSize:'15px'}}>{t("Manufacture emissions")}</li>
                <li style={{fontSize:'15px'}}>{t("Infrastructure emissions")}</li>
              </ul>

              <p>{t("For each emission type, we define a factor that represents the amount of CO2 emitted for every kilometre travelled.")}</p>
               
              <p>{t("The emissions for each part of the journey are calculated as follows")}</p><br /> 

              <div className="ChartInCalculationDetails" >
              <h3 style={{textAlign:'center'}}>Emissions [kgCO<sub>2eq</sub>]</h3>
                <ResponsiveContainer>
                  <BarChart
                    width='60%'
                    height={250}
                    data={data}
                    maxBarSize={150}
                    margin={{
                      top: 20, right: 30, left: 20, bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="tick" />
                    <Tooltip 
                      itemSorter={(item) => (-item.value)}
                      cursor={{fill: '#FAFAFA'}}
                    />
                    <Legend />
                    <Bar dataKey="operation" name={t("Operation")} stackId="a" fill="#592689" />
                    <Bar dataKey="manufacture" name={t('Manufacture')} stackId="a" fill="#2C2D40" />
                    <Bar dataKey="infrastructure" name={t('Infrastructure')} stackId="a" fill="#DB9D47" />
                  </BarChart>
                </ResponsiveContainer>
              </div>

            </Typography>

            <br/>
            <br/>
            <div style={{height:'3px', width:'70%', backgroundColor:'#636592', margin:'40px auto 40px auto'}}>
            <br/>
            <br/>

            </div>
              <TableContainer component={Paper}>

                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">{t('Part')}</TableCell>
                      <TableCell align="center">{t('Transport type')}</TableCell>
                      <TableCell align="center">Distance<br />[km]</TableCell>
                      <TableCell>x</TableCell>
                        <TableCell><span style={{fontSize:'60px'}}>(</span></TableCell>
                      <TableCell align="center">{t('Operation')}<br />[gCO<sub>2</sub>/km]</TableCell>
                      <TableCell>+</TableCell>
                      <TableCell align="center">{t('Manufacture')}<br />[gCO<sub>2</sub>/km]</TableCell>
                      <TableCell>+</TableCell>
                      <TableCell align="center">{t('Infrastructure')}<br />[gCO<sub>2</sub>/km]</TableCell>
                      <TableCell><span style={{fontSize:'60px'}}>)</span></TableCell>
                      <TableCell>=</TableCell>
                      <TableCell align="center">Total<br />[kgCO<sub>2</sub>]</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                  {(selectedPath === 'bus' ? PathBus.routes[displayedBusPath] : PathTrain.routes[displayedTrainPath]).steps.map((step) => (
                    <>
                    <TableRow key={step.id}>
                      <TableCell align="center" component="th" scope="row">{parseInt(step.id.substring(4))+1}</TableCell>
                      <TableCell align="center">{t(step.travelTypeStep === 'pedestrian' ? 'Walk' : step.vehicleModeStep)}</TableCell>
                      <TableCell align="center">{(step.distanceStep/1000).toFixed(1)}</TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell align="center">{step.CO2?.operation ? (step.CO2?.operation * 1000).toFixed(1) : 0}</TableCell>
                      <TableCell />
                      <TableCell align="center">{step.CO2?.manufacture ? (step.CO2?.manufacture * 1000).toFixed(1) : 0}</TableCell>
                      <TableCell />
                      <TableCell align="center">{step.CO2?.infrastructure ? (step.CO2?.infrastructure *1000).toFixed(1) : 0}</TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell align="center">{step.CO2?.CO2 ? Math.round(step.CO2?.CO2) : 0 }</TableCell>
                    </TableRow>
                    </>
                  ))}

                  </TableBody>
                </Table>
              </TableContainer>


              <p style={{textAlign:'center'}} dangerouslySetInnerHTML={{__html: t("Do you want to help us improve our data?</br> Send us a mail at moveco@gomoveco.com")}} />

          </DialogContent>
          <DialogActions>
          </DialogActions>
        </Dialog>
      </div>
    );

  } else if (selectedPath === 'car') {

    let data = []

    if (language === 'English') {
      data = [{
        id: `Electric car`,
        infrastructure: Math.round((ElectricCar.infrastructure * PathCar.routes[DisplayedCarPath].distanceByCar / 1000 / passengersNumber)*100)/100,
        manufacture: Math.round((ElectricCar.manufacture * PathCar.routes[DisplayedCarPath].distanceByCar / 1000 / passengersNumber)*100)/100,
        operation: Math.round((ElectricCar.electricity_CO2_Factor.co2 * PathCar.routes[DisplayedCarPath].totalCarConsumption / passengersNumber)*100)/100
      },
      {
        id: `Conventional car`,
        infrastructure: Math.round((ConventionalCar.infrastructure * PathCar.routes[DisplayedCarPath].distanceByCar / 1000 / passengersNumber)*100)/100,
        manufacture: Math.round((ConventionalCar.manufacture * PathCar.routes[DisplayedCarPath].distanceByCar / 1000 / passengersNumber)*100)/100,
        operation: Math.round((ConventionalCar.operation * PathCar.routes[DisplayedCarPath].distanceByCar / 1000 / passengersNumber)*100)/100
      }
      ]
    } else {
      data = [{
        id: `Voiture électrique`,
        infrastructure: Math.round((ElectricCar.infrastructure * PathCar.routes[DisplayedCarPath].distanceByCar / 1000 / passengersNumber)*100)/100,
        manufacture: Math.round((ElectricCar.manufacture * PathCar.routes[DisplayedCarPath].distanceByCar / 1000 / passengersNumber)*100)/100,
        operation: Math.round((ElectricCar.electricity_CO2_Factor.co2 * PathCar.routes[DisplayedCarPath].totalCarConsumption / passengersNumber)*100)/100
      },
      {
        id: `Voiture conventionnelle`,
        infrastructure: Math.round((ConventionalCar.infrastructure * PathCar.routes[DisplayedCarPath].distanceByCar / 1000 / passengersNumber)*100)/100,
        manufacture: Math.round((ConventionalCar.manufacture * PathCar.routes[DisplayedCarPath].distanceByCar / 1000 / passengersNumber)*100)/100,
        operation: Math.round((ConventionalCar.operation * PathCar.routes[DisplayedCarPath].distanceByCar / 1000 / passengersNumber)*100)/100
      }
      ]
    }

    return(

      <div>

        <Dialog 
          onClose={() => {dispatch(setCalculationDetails)}} 
          aria-labelledby="customized-dialog-title" 
          open={isCalculationDetailOpen}
          maxWidth="md">
            <DialogTitle id="customized-dialog-title" onClose={() => {dispatch(setCalculationDetails())}}>
            {t("Calculation details")}
            </DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>

              <p>{t("Let's look into the details of your")} {language === 'English' ? 'car route' : 'voiture'}.</p>
              <p>{t("We take into consideration three different types of emissions")}</p>
              <ul>
                <li style={{fontSize:'15px'}}>{t("Operation emissions")}</li>
                <li style={{fontSize:'15px'}}>{t("Manufacture emissions")}</li>
                <li style={{fontSize:'15px'}}>{t("Infrastructure emissions")}</li>
              </ul>

              <p>{t("For each emission type, we define a factor that represents the amount of CO2 emitted for every kilometre travelled.")}</p>

              <p>{t("The emissions for each part of the journey are calculated as follows")}<br/>
              {t("CO2 emissions = distance x (operation + manufacture + infrastructure)")}</p> 

              {IsElectricityChosen ? 
              <p>
                {t("We use the electricity CO2 factor from")} {ElectricCar.electricity_CO2_Factor.country} : {ElectricCar.electricity_CO2_Factor.co2} kgCO<sub>2eq</sub>/kWh.<br/>
                {t("The car will consume around")} {Math.round(PathCar.routes[DisplayedCarPath].totalCarConsumption)} kWh.
              </p>
              : null}

              <div className="ChartInCalculationDetails">
              <h3 style={{textAlign:'center'}}>Emissions [kgCO<sub>2eq</sub>]</h3>
                <ResponsiveContainer>
                  <BarChart
                    width='60%'
                    height={250}
                    data={data}
                    maxBarSize={150}
                    margin={{
                      top: 20, right: 30, left: 20, bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="id" />
                    <Tooltip cursor={{fill: '#FAFAFA'}} />
                    <Legend />
                    
                    <Bar dataKey="manufacture" name={t('Manufacture')} stackId="a" fill="#2C2D40" />
                    <Bar dataKey="operation" name={t('Operation')} stackId="a" fill="#592689" />
                    <Bar dataKey="infrastructure" name={t('Infrastructure')} stackId="a" fill="#DB9D47" />
                  </BarChart>
                </ResponsiveContainer>

            </div>

            <br/>
            <br/>
            <div style={{height:'3px', width:'70%', backgroundColor:'#636592', margin:'40px auto 40px auto'}}>
            <br/>
            <br/>

              </div>

              <TableContainer component={Paper}>

                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">{t('Transport type')}</TableCell>
                      <TableCell align="center">Distance<br />[km]</TableCell>
                      <TableCell>x</TableCell>
                      <TableCell><span style={{fontSize:'60px'}}>(</span></TableCell>
                      <TableCell align="center">{t("Operation")}<br />[gCO<sub>2</sub>/km]</TableCell>
                      <TableCell>+</TableCell>
                      <TableCell align="center">{t('Manufacture')}<br />[gCO<sub>2</sub>/km]</TableCell>
                      <TableCell>+</TableCell>
                      <TableCell align="center">{t('Infrastructure')}<br />[gCO<sub>2</sub>/km]</TableCell>
                      <TableCell><span style={{fontSize:'60px'}}>)</span></TableCell>
                      <TableCell>+</TableCell>
                      <TableCell><span style={{fontSize:'60px'}}>(</span></TableCell>
                      <TableCell align="center">{t('Consumption')}<br />[kWh]</TableCell>
                      <TableCell align="center">x</TableCell>
                      <TableCell align="center">{t("Network electricity emissions")}<br />[gCO<sub>2</sub>/kWh]</TableCell>
                      <TableCell><span style={{fontSize:'60px'}}>)</span></TableCell>
                      <TableCell>=</TableCell>
                      <TableCell align="center">Total<br />[kgCO<sub>2</sub>]</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    <TableRow>
                      <TableCell align="center">{t("Conventional car")}</TableCell>
                      <TableCell align="center">{(PathCar.routes[DisplayedCarPath].distanceByCar/1000).toFixed(1)}</TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell align="center">{(ConventionalCar.operation * 1000).toFixed(1)}</TableCell>
                      <TableCell />
                      <TableCell align="center">{(ConventionalCar.manufacture * 1000).toFixed(1)}</TableCell>
                      <TableCell />
                      <TableCell align="center">{(ConventionalCar.infrastructure *1000).toFixed(1)}</TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell align="center">{Math.round(PathCar.routes[DisplayedCarPath].distanceByCar * (ConventionalCar.operation + ConventionalCar.manufacture + ConventionalCar.infrastructure)/1000)}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="center">{t('Electric car')}</TableCell>
                      <TableCell align="center">{(PathCar.routes[DisplayedCarPath].distanceByCar/1000).toFixed(1)}</TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell align="center">{(ElectricCar.manufacture * 1000).toFixed(1)}</TableCell>
                      <TableCell />
                      <TableCell align="center">{(ElectricCar.infrastructure *1000).toFixed(1)}</TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell align="center">{(PathCar.routes[DisplayedCarPath].totalCarConsumption).toFixed(1)}</TableCell>
                      <TableCell />
                      <TableCell align="center">{(ElectricCar.electricity_CO2_Factor.co2 * 1000).toFixed(1)}</TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell align="center">{Math.round((PathCar.routes[DisplayedCarPath].distanceByCar / 1000 * (ElectricCar.manufacture + ElectricCar.infrastructure) + ElectricCar.electricity_CO2_Factor.co2 * PathCar.routes[DisplayedCarPath].totalCarConsumption))}</TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
                </TableContainer>

              </Typography>

              <p style={{textAlign:'center'}} dangerouslySetInnerHTML={{__html: t("Do you want to help us improve our data?</br> Send us a mail at moveco@gomoveco.com")}} />

            </DialogContent>
          <DialogActions>
          </DialogActions>
        </Dialog>

      </div>


    )

  } else if (selectedPath === 'plane') {

    let data = [{
      id: t('plane').charAt(0).toUpperCase() + t('plane').slice(1),
      infrastructure: Math.round((PlaneCO2.infrastructure * PathPlane.routes[0].distance )*100)/100,
      manufacture: Math.round((PlaneCO2.manufacture * PathPlane.routes[0].distance )*100)/100,
      operation: Math.round((PlaneCO2.operation * PathPlane.routes[0].distance )*100)/100
    }];

    return(

      <div>

        <Dialog 
          onClose={() => {dispatch(setCalculationDetails)}} 
          aria-labelledby="customized-dialog-title" 
          open={isCalculationDetailOpen}
          maxWidth="md">
            <DialogTitle id="customized-dialog-title" onClose={() => {dispatch(setCalculationDetails())}}>
              {t("Calculation details")}
            </DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                <p>{t("Let's look into the details of your")} {selectedPath}{language === 'English' ? 'by plane' : 'avion'}.</p>
                <p>{t("We take into consideration three different types of emissions")}</p>
                <ul>
                  <li style={{fontSize:'15px'}}>{t("Operation emissions")}</li>
                  <li style={{fontSize:'15px'}}>{t("Manufacture emissions")}</li>
                  <li style={{fontSize:'15px'}}>{t("Infrastructure emissions")}</li>
                </ul>

                <p>{t("For each emission type, we define a factor that represents the amount of CO2 emitted for every kilometre travelled.")}</p>

                <p>{t("The emissions for each part of the journey are calculated as follows")}<br/>
                {t("CO2 emissions = distance x (operation + manufacture + infrastructure)")}</p> 
              
                <div className="ChartInCalculationDetails" >
              <h3 style={{textAlign:'center'}}>Emissions [kgCO<sub>2eq</sub>]</h3>
                <ResponsiveContainer>
                  <BarChart
                    width='60%'
                    height={250}
                    data={data}
                    maxBarSize={150}
                    margin={{
                      top: 20, right: 30, left: 20, bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="id" />
                    <Tooltip cursor={{fill: '#FAFAFA'}} />
                    <Legend />
                    
                    <Bar dataKey="manufacture" name={t('Manufacture')} stackId="a" fill="#2C2D40" />
                    <Bar dataKey="operation" name={t('Operation')} stackId="a" fill="#592689" />
                    <Bar dataKey="infrastructure" name={t('Infrastructure')} stackId="a" fill="#DB9D47" />
                  </BarChart>
                </ResponsiveContainer>

            </div>

            <br/>
            <br/>
            <div style={{height:'3px', width:'70%', backgroundColor:'#636592', margin:'40px auto 40px auto'}}>
            <br/>
            <br/>

              </div>

              <TableContainer component={Paper}>

                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">{t('Transport type')}</TableCell>
                      <TableCell align="center">Distance<br />[km]</TableCell>
                      <TableCell>x</TableCell>
                      <TableCell><span style={{fontSize:'60px'}}>(</span></TableCell>
                      <TableCell align="center">{t('Operation')}<br />[gCO<sub>2</sub>/km]</TableCell>
                      <TableCell>+</TableCell>
                      <TableCell align="center">{t('Manufacture')}<br />[gCO<sub>2</sub>/km]</TableCell>
                      <TableCell>+</TableCell>
                      <TableCell align="center">{t('Infrastructure')}<br />[gCO<sub>2</sub>/km]</TableCell>
                      <TableCell><span style={{fontSize:'60px'}}>)</span></TableCell>
                      <TableCell>=</TableCell>
                      <TableCell align="center">Total<br />[kgCO<sub>2</sub>]</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    <TableRow>
                      <TableCell align="center">{t('plane').charAt(0).toUpperCase() + t('plane').slice(1)}</TableCell>
                      <TableCell align="center">{(PathPlane.routes[0].distance).toFixed(1)}</TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell align="center">{(PlaneCO2.operation * 1000).toFixed(1)}</TableCell>
                      <TableCell />
                      <TableCell align="center">{(PlaneCO2.manufacture * 1000).toFixed(1)}</TableCell>
                      <TableCell />
                      <TableCell align="center">{(PlaneCO2.infrastructure *1000).toFixed(1)}</TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell align="center">{(PathPlane.routes[0].distance * (PlaneCO2.operation + PlaneCO2.manufacture + PlaneCO2.infrastructure)).toFixed(1)}</TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
                </TableContainer>

              </Typography>

              <p style={{textAlign:'center'}} dangerouslySetInnerHTML={{__html: t("Do you want to help us improve our data?</br> Send us a mail at moveco@gomoveco.com")}} />

            </DialogContent>
          <DialogActions>
          </DialogActions>
        </Dialog>

      </div>


    )

  } 
    
  else if (selectedPath === 'bicycle') {

    let data = [{
        id: `Bicycle`,
        infrastructure: Math.round((BicycleCO2.infrastructure * PathBicycle.routes[0].distance / 1000 )*100)/100,
        manufacture: Math.round((BicycleCO2.manufacture * PathBicycle.routes[0].distance / 1000 )*100)/100,
        operation: Math.round((BicycleCO2.operation * PathBicycle.routes[0].distance / 1000 )*100)/100
      }];

  return(

    <div>

      <Dialog 
        onClose={() => {dispatch(setCalculationDetails)}} 
        aria-labelledby="customized-dialog-title" 
        open={isCalculationDetailOpen}
        maxWidth="md">
          <DialogTitle id="customized-dialog-title" onClose={() => {dispatch(setCalculationDetails())}}>
            {t("Calculation details")}
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              <p>{t("Let's look into the details of your")} {language === 'English' ? 'travel by bike' : 'vélo'}.</p>
              <p>{t("We take into consideration three different types of emissions")}</p>
              <ul>
                <li style={{fontSize:'15px'}}>{t("Operation emissions")}</li>
                <li style={{fontSize:'15px'}}>{t("Manufacture emissions")}</li>
                <li style={{fontSize:'15px'}}>{t("Infrastructure emissions")}</li>
              </ul>

              <p>{t("For each emission type, we define a factor that represents the amount of CO2 emitted for every kilometre travelled.")}</p>

              <p>{t("The emissions for each part of the journey are calculated as follows")}<br/>
              {t("CO2 emissions = distance x (operation + manufacture + infrastructure)")}</p> 

              <div className="ChartInCalculationDetails" >
              <h3 style={{textAlign:'center'}}>Emissions [kgCO<sub>2eq</sub>]</h3>
                <ResponsiveContainer>
                  <BarChart
                    width='60%'
                    height={250}
                    data={data}
                    maxBarSize={150}
                    margin={{
                      top: 20, right: 30, left: 20, bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="id" />
                    <Tooltip cursor={{fill: '#FAFAFA'}} />
                    <Legend />
                    
                    <Bar dataKey="manufacture" name={t('Manufacture')} stackId="a" fill="#2C2D40" />
                    <Bar dataKey="operation" name={t('Operation')} stackId="a" fill="#592689" />
                    <Bar dataKey="infrastructure" name={t('Infrastructure')} stackId="a" fill="#DB9D47" />
                  </BarChart>
                </ResponsiveContainer>

            </div>

            <br/>
            <br/>
            <div style={{height:'3px', width:'70%', backgroundColor:'#636592', margin:'40px auto 40px auto'}}>
            <br/>
            <br/>

              </div>

              <TableContainer component={Paper}>

                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">{t('Transport type')}</TableCell>
                      <TableCell align="center">Distance<br />[km]</TableCell>
                      <TableCell>x</TableCell>
                      <TableCell><span style={{fontSize:'60px'}}>(</span></TableCell>
                      <TableCell align="center">{t('Operation')}<br />[gCO<sub>2</sub>/km]</TableCell>
                      <TableCell>+</TableCell>
                      <TableCell align="center">{t('Manufacture')}<br />[gCO<sub>2</sub>/km]</TableCell>
                      <TableCell>+</TableCell>
                      <TableCell align="center">{t('Infrastructure')}<br />[gCO<sub>2</sub>/km]</TableCell>
                      <TableCell><span style={{fontSize:'60px'}}>)</span></TableCell>
                      <TableCell>=</TableCell>
                      <TableCell align="center">Total<br />[kgCO<sub>2</sub>]</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    <TableRow>
                      <TableCell align="center">{language === 'English' ? 'Bike' : 'Vélo'}</TableCell>
                      <TableCell align="center">{(PathBicycle.routes[0].distance/1000).toFixed(1)}</TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell align="center">{(BicycleCO2.operation * 1000).toFixed(1)}</TableCell>
                      <TableCell />
                      <TableCell align="center">{(BicycleCO2.manufacture * 1000).toFixed(1)}</TableCell>
                      <TableCell />
                      <TableCell align="center">{(BicycleCO2.infrastructure *1000).toFixed(1)}</TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell align="center">{(PathBicycle.routes[0].distance * (BicycleCO2.operation + BicycleCO2.manufacture + BicycleCO2.infrastructure)/1000).toFixed(1)}</TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
                </TableContainer>

              </Typography>

              <p style={{textAlign:'center'}} dangerouslySetInnerHTML={{__html: t("Do you want to help us improve our data?</br> Send us a mail at moveco@gomoveco.com")}} />

            </DialogContent>
          <DialogActions>
          </DialogActions>
        </Dialog>

      </div>


  )

} 
  
  
  else {

    return null

  }


}



/*



*/