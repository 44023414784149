import React from 'react';
import { useSelector } from "react-redux";
import PrimaryButtom from '../../../../components/PrimaryButton/PrimaryButton';
// import { SearchBar } from '../../../SearchBar/SearchBar';
import SearchModal from './SearchModal';
// IMPORT CSS
import '../../../../style/Balance.css'
import moment from "moment";
// IMPORT COMPONENTS 

import CarIcon from '../../../MapApp/Results/PathList/Util/vehicles_icons/car.png'
import trainIcon from '../../../MapApp/Results/PathList/Util/vehicles_icons/train.png';
import subwayIcon from '../../../MapApp/Results/PathList/Util/vehicles_icons/metro.png';
import busIcon from '../../../MapApp/Results/PathList/Util/vehicles_icons/bus.png';
import citybusIcon from '../../../MapApp/Results/PathList/Util/vehicles_icons/citybus.png';
import boatIcon from '../../../MapApp/Results/PathList/Util/vehicles_icons/boat.png';
import inclinedIcon from '../../../MapApp/Results/PathList/Util/vehicles_icons/aerial.png';
import tramIcon from '../../../MapApp/Results/PathList/Util/vehicles_icons/tram.png';
import taxiIcon from '../../../MapApp/Results/PathList/Util/vehicles_icons/taxi.png';
import walkIcon from '../../../MapApp/Results/PathList/Util/vehicles_icons/walk.png';
import BicycleIcon from '../../../MapApp/Results/PathList/Util/vehicles_icons/bike.png'
import PlaneIcon from '../../../MapApp/Results/PathList/Util/vehicles_icons/plane.png'

import {
  BarChart, Bar, CartesianGrid, Tooltip, Cell, LabelList, ResponsiveContainer, PieChart, Pie
} from 'recharts';

// IMPORT DATA FROM SLICES
import { selectUserPath } from "../../UserPathSlice";

import { useTranslation } from 'react-i18next';
import RegularJourneyModal from './RegularJourneyModal';

export default function Balance(){
  const [openSearchModal, _openSearchModal] = React.useState(false);
  const [openRegularTripModal, _openRegularTripModal] = React.useState(false);
  const userPath = useSelector(selectUserPath);
  const { t, i18n } = useTranslation();

  const userPathThisYear = React.useMemo(
    () =>
      userPath.filter((path) => new Date(path.date).getFullYear() === new Date().getFullYear()),
    [userPath]
  );

  const { data, fullDataMerged, totalCO2 } = React.useMemo(() => {
    const data = [],fullDataMerged = [];
    let totalCO2 = 0
    userPathThisYear.forEach((path, i) => {
      let tempTotalCO2 = path.totalCO2;
      if(path.journeyType === 'REGULAR_JOURNEY') {
        const startDate = moment(path.dateSpan.startDate);
        const endDate = moment(path.dateSpan.endDate);
        const totalDays = endDate.diff(startDate, 'days');
        const numberOfWeeks = Math.round(totalDays / 7);
        const timesPerWeek = path.timesPerWeek
        const remainingDays = Math.round(totalDays % 7);
        const days = (numberOfWeeks * timesPerWeek) + (remainingDays > 0 && timesPerWeek <= remainingDays ? timesPerWeek : 0 );
        tempTotalCO2 = tempTotalCO2 * days;
        totalCO2 = totalCO2 + tempTotalCO2;
        for (let index = 0; index < days; index++) {
          JSON.parse(path.steps).forEach(step => fullDataMerged.push(step))
        }
      }
      totalCO2 = totalCO2 + tempTotalCO2;
      data.push({
        id: `Path${i}`,
        label: path.journeyType === 'REGULAR_JOURNEY' ? path.journeyName : `${path.departure} - ${path.arrival}`,
        value: Math.round(tempTotalCO2),
        color: "hsl(246, 70%, 50%)",
        date: path.date,
      });
      if(path.journeyType !== 'REGULAR_JOURNEY'){
        JSON.parse(path.steps).forEach(step => fullDataMerged.push(step))
      }
    });
    return { data, fullDataMerged, totalCO2 }
  }, [userPathThisYear])
  const unique = React.useMemo(() => [...new Set(fullDataMerged.map(item => item.type))], [fullDataMerged]);
  const { distanceData, emissionData } = React.useMemo(() => {
    const distanceData = [], emissionData = [];
      unique.forEach(element => {
      let distance = 0;
      let CO2 = 0;
        fullDataMerged.forEach(step => {
          if(step.type === element) {
            distance = distance + step.distance;
            if(element === 'car' || element === 'bicycle' ) {
              CO2 = CO2 + step.CO2
            } else if (element === 'walk') {
              CO2 = 0;
            } else {
              const temp = step.CO2.CO2 ? step.CO2.CO2 : step.CO2
              CO2 = CO2 + temp
            }
          }
        })
        let data = {
          id: element,
          label: element,
          color: "hsl(246, 70%, 50%)"
        }
        distanceData.push({...data, value: Math.round(distance / 1000)});
        emissionData.push({...data, value: Math.round(CO2) })
    })
    return { distanceData, emissionData }
  }, [fullDataMerged, unique])

  const getIcon = ((type, width) => {

    if(type === 'highSpeedTrain' || type === 'intercityTrain' || type === 'interRegionalTrain' || type === 'regionalTrain' || type === 'cityTrain' ) {
          return <img className='ChartIcon' src={trainIcon} alt="train icon" style={{width:width/2.5, translateY: `100%`}} />
      } else if (type === 'subway') {
          return <img className='ChartIcon' src={subwayIcon} alt="subway icon" style={{width:width/2, translateY: `100%`}} />
      } else if (type === 'lightRail' || type === 'monorail'  ) {
          return <img className='ChartIcon' src={tramIcon} alt="tram icon" style={{width:width/2, translateY: `100%`}} />
      } else if (type === 'bus') {
          return <img className='ChartIcon' src={citybusIcon} alt="city bus icon" style={{width:width/2}} />
      } else if (type === 'privateBus') {
          return <img className='ChartIcon' src={taxiIcon} alt="taxi icon" style={{width:width/2}} />
      } else if (type === 'busRapid') {
          return <img className='ChartIcon' src={busIcon} alt="bus icon" style={{width:width/2}} />
      } else if (type === 'ferry') {
          return <img className='ChartIcon' src={boatIcon} alt="ferry icon" style={{width:width/2}} />
      } else if (type === 'inclined' || type === 'aerial') {
          return <img className='ChartIcon' src={inclinedIcon} alt="funicular icon" style={{width:width/2}} />
      } else if (type === 'walk') {
          return <img className='ChartIcon' src={walkIcon} alt="walk icon" style={{width:width/3}} />
      } else if (type === 'car') {
          return <img className='ChartIcon' src={CarIcon} alt="car icon" style={{width:width/2}} />
      } else if (type === 'bicycle') {
        return <img className='ChartIcon' src={BicycleIcon} alt="bike icon" style={{width:width/2}} />
      } else if (type === 'plane') {
        return <img className='ChartIcon' src={PlaneIcon} alt="plane icon" style={{width:width/2}} />
      } else {
        return '?'
      }    
})

function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function findTransport(transportIn) {
  if(transportIn === 'plane') {
      return t("by plane")
  } else if (transportIn === 'bicycle') {
      return t("by bicyle")
  } else if (transportIn === 'car') {
      return t("by car")
    } else if (transportIn === 'walk') {
      return t("on foot")} 
    else if (transportIn === 'bus') {
      return t("by bus")
    } 
      else if (transportIn === 'intercityTrain' ) {
        return t("by train")
    } else if (transportIn === 'interRegionalTrain' ) {
      return t("by train")
    } else if (transportIn === 'regionalTrain' ) {
      return t("by train")
    } else if (transportIn === 'cityTrain' ) {
      return t("by train")
    } else if (transportIn === 'subway' ) {
      return t("by subway")
    }
    else {
      return transportIn
    }
}


  const renderCustomizedImageLabel = (props) => {
    const {
      x, y, width, value,
    } = props;
  
    return (
      <g>
      <foreignObject x={x} y={y} width={width} height="100%" style={{overflow:'visible'}} >
          <div style={{justifyItems:'center'}}>
            {getIcon(value, width)}
          </div>

      </foreignObject>
    </g>
  
    );
  };

  const CustomTooltipMainCO2 = ({ active, payload }) => {
    if (active) {
      return (
        <div className="custom-tooltip" style={{border:'1px solid black', padding:'5px', background:'white', opacity:'0.8'}}>
          <p style={{fontSize:'12px'}}>{t("You made the journey")} {payload[0].payload.label}, {new Date(payload[0].payload.date).toLocaleDateString(i18n.language === 'fr' ? 'fr-FR' : undefined, { weekday: 'long', month: 'long', day: 'numeric' })},<br/>{t('and emitted')} <b>{payload[0].value} kgCO<sub>2eq</sub></b>.</p>
        </div>
      );
    }
  
    return null;
  };

  const CustomTooltipDistance = ({ active, payload }) => {
    if (active) {
      return (
        <div className="custom-tooltip" style={{border:'1px solid black', padding:'5px', background:'white', opacity:'0.8'}}>
          <p style={{fontSize:'12px'}}>{`${numberWithSpaces(payload[0].value)} km ${findTransport(payload[0].payload.id)}`}</p>
        </div>
      );
    }
  
    return null;
  };

  const CustomTooltipCO2 = ({ active, payload }) => {
    if (active) {
      return (
        <div className="custom-tooltip" style={{border:'1px solid black', padding:'5px', background:'white', opacity:'0.8'}}>
          <p style={{fontSize:'12px'}}>{`${numberWithSpaces(payload[0].value)} kgCO`}<sub>2eq</sub>{` ${findTransport(payload[0].payload.id)}`}</p>
        </div>
      );
    }
  
    return null;
  };

 const renderCustomizedPieLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  index
}) => {
  const RADIAN = Math.PI / 180;
  // eslint-disable-next-line
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  // eslint-disable-next-line
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  // eslint-disable-next-line
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      style={{fontSize:'12px'}}
    >
    {data[index].label}
    </text>
  );
};

  const colors = ['#DB9D47', '#699D51', '#2C2D40', '#592689', '#4B6549', '#432A65', '#A29D4C' ]



  return (

    

    <div className="Balance">

      <h4>{t("Yearly carbon balance")}</h4>

      <p style={{textAlign: "justify"}}>{t("Scientists have estimated that in order to minimize the inevitable impacts of climate change, temperature rise shouldn't exceed 1.5°C above pre-industrial levels. To achieve that, total emissions per year must decrease to 25 gigatons by 2030. Considering the projection of global population (11 billion by 2100), and with a strictly equal distribution, the individual allowance of carbon is between 1,6 and 2,8 tonnes per year. For the analysis, we choose an objective of 2,2 tonnes.")}</p>

      <div className="meter">
        <span className="filler" style={{ width: (totalCO2/3000*100) < 100 ? `${totalCO2/3000*100}%` : `100%` }} ></span>
      </div>

      <p style={{ textAlign: `center`, fontWeight: '500' }}>{t("You have used ")}{`${Math.round(totalCO2/3000*100)}%`}{t(" of your yearly allowance.")}</p>

      {userPathThisYear.length === 0 ? 
      <>
      <br />
      <p>{t("You don't have any journey saved in your account.")}</p>
      <p>{t("Start by searching the best way for your next travel.")}</p>

        <div style={{display: 'flex', flexWrap: 'wrap'}}>
          <div >
                <PrimaryButtom style={{ marginRight: '20px', marginBottom: '10px'}} onClick={()=>{_openSearchModal(true)}}>{t("Add a trip made")}</PrimaryButtom>
          </div>
          <div>
                <PrimaryButtom onClick={()=> _openRegularTripModal(true)}>{t("Add a regular journey")}</PrimaryButtom>
          </div>
        </div>

        <SearchModal onClose={_openSearchModal} show={openSearchModal} />
        <RegularJourneyModal onClose={_openRegularTripModal} show={openRegularTripModal} />

      </>
      :

      <>

      <h4>{t('Your Journeys')}</h4>

      <div className="PieChartAndText">

        <div className="TextBeforePie">

          <ol>

            {userPathThisYear.lenght > 10 ? 

              userPathThisYear.sort((a,b) => (a.totalCO2 < b.totalCO2) ? 1 : ((b.totalCO2 < a.totalCO2) ? -1 : 0)).slice(0, 9).map((path, i) => (

                path.journeyType && path.journeyType === 'REGULAR_JOURNEY' ?
                <li key={i}>{path.journeyName}</li>
                :
                <li key={i}>{path.departure} - {path.arrival}</li>

              ))
          
              :
          
              userPathThisYear.sort((a,b) => (a.totalCO2 < b.totalCO2) ? 1 : ((b.totalCO2 < a.totalCO2) ? -1 : 0)).map((path, i) => (

                path.journeyType && path.journeyType === 'REGULAR_JOURNEY' ?
                <li key={i}>{path.journeyName}</li>
                :
                <li key={i}>{path.departure} - {path.arrival}</li>

              ))
          
            } 

          </ol>

          <div className="btn-container">
            <PrimaryButtom onClick={()=>{_openSearchModal(true)}}>{t('Add a trip made')}</PrimaryButtom>
            <PrimaryButtom onClick={()=> _openRegularTripModal(true)}>{t('Add a regular journey')}</PrimaryButtom>
          </div>
        <SearchModal onClose={_openSearchModal} show={openSearchModal} />
        <RegularJourneyModal onClose={_openRegularTripModal} show={openRegularTripModal} />
        </div>
        <div className="PieChart1">

          <div style={{ width: '100%', height: 300 }}>
              <ResponsiveContainer width={'100%'}>
                <PieChart  height={300}>
                  <Pie
                    data={data}
                    labelLine={true}
                    outerRadius={90}
                    innerRadius={45}
                    // fill
                    dataKey="value"
                    label={renderCustomizedPieLabel}
                  >
                  {
                    data.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />)
                  }
                  </Pie>
                  <Tooltip content={<CustomTooltipMainCO2 />} />
                </PieChart>
              </ResponsiveContainer> 
          </div>

          


        </div>

      </div>

      <hr style={{ marginTop: `30px`}} />

      <h4>{t("Analysis")}</h4>

      <div className="TwoPieCharts">

        <div className="PieChart2">

          <h5>{t("Total distance")} {numberWithSpaces(distanceData.reduce((a, b) => a + (b.value || 0), 0))} km</h5>

          <div style={{ width: '100%', height: 200 }}>
            <ResponsiveContainer>
              <BarChart
                width={500}
                height={300}
                data={distanceData}
                maxBarSize={70}
                margin={{
                  top: 50, right: 5, left: 5, bottom: 5,
                }}
              >
                <CartesianGrid stroke="#eee" strokeDasharray="5 5" vertical={false} />
                <Tooltip content={<CustomTooltipDistance />} cursor={{fill: '#FAFAFA'}} />
                <Bar dataKey="value" fill="#8884d8">
                {
                  data.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />)
                }
                  <LabelList dataKey="id" content={renderCustomizedImageLabel} />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>

          

        </div>

        <div className="PieChart3">

          <h5>{t("Total emissions")} {numberWithSpaces(Math.round(totalCO2))} kg CO<sub>2eq</sub></h5>

          <div style={{ width: '100%', height: 200 }}>
            <ResponsiveContainer>
              <BarChart
                width={500}
                height={300}
                data={emissionData}
                maxBarSize={70}
                margin={{
                  top: 50, right: 5, left: 5, bottom: 5,
                }}
              >
                <CartesianGrid stroke="#eee" strokeDasharray="5 5" vertical={false} />
                <Tooltip content={<CustomTooltipCO2 />} cursor={{fill: '#FAFAFA'}} />
                <Bar dataKey="value" fill="#8884d8">
                {
                  data.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />)
                }
                  <LabelList dataKey="id" content={renderCustomizedImageLabel} />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>

          

        </div> 

      </div>       
      </>


      }

     



    </div>

  )
}
