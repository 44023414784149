import React from "react";
import { Redirect, Route } from "react-router-dom";
import { isLoggedIn } from "../containers/Auth/AuthSlice";
import { useSelector } from "react-redux";

const ExcludableRoute = ({ component: Component, ...rest }) => {
  // Add your own authentication on the below line.
  const isLogIn = useSelector(isLoggedIn);

  // direct to '/' if already logged in  
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogIn ? <Redirect to="/" />  : <Component {...props} />
      }
    />
  );
};

export default ExcludableRoute;