import React, { useState, useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  isMobile,
  isWinPhone,
  isTablet,
  isAndroid,
  isIOS,
} from "react-device-detect";
import { throttle } from "lodash";
import "../../../style/ToFromInput.css";
import MobileViewInput from "../../../components/Inputs/LocationPicker/MobileViewInput/MobileViewInput";
import {
  selectFromAutosuggestions,
  fetchFromAutosuggestions,
  setFromAutosuggestions,
  selectIsFromAutosuggestionsLoading,
  selectSelectedFromLocation,
  setSelectedFromLocation,
} from "./FromAutosuggestionsSlice";
import { useTranslation } from "react-i18next";
import RegularViewInput from "../../../components/Inputs/LocationPicker/RegularViewInput/RegularViewInput";

export const FromInput = React.memo(() => {
  const initialRender = useRef(true);
  const [showMobileViewInput, _showMobileViewInput] = useState(false);
  const fromAutosuggestions = useSelector(selectFromAutosuggestions);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const isLoading = useSelector(selectIsFromAutosuggestionsLoading);
  const selectedFromLocation = useSelector(selectSelectedFromLocation);
  const language = i18n.language;
  // const matches = useMediaQuery("(min-width: 600px)");
 
  useEffect(() => {
    if (!showMobileViewInput && !initialRender.current) {
      const cleanUpState = () => {
        if (fromAutosuggestions && fromAutosuggestions.length) {
          if (selectedFromLocation) {
            if (selectedFromLocation.label === fromAutosuggestions[0].label) {
              return;
            }
            if (!fromAutosuggestions.some((e) => e.label === selectedFromLocation.label)) {
              dispatch(setFromAutosuggestions([selectedFromLocation]));
            }
          } else {
            dispatch(setFromAutosuggestions([]));
          }
        }
      };
      cleanUpState();
    }
  }, [
    dispatch,
    fromAutosuggestions,
    selectedFromLocation,
    showMobileViewInput,
  ]);

  let content = {
    English: {
      placeholderFrom: "From",
    },
    French: {
      placeholderFrom: "Départ",
    },
  };

  language === "fr" ? (content = content.French) : (content = content.English);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledFetch = useCallback(
    throttle((data, language) => {
      dispatch(fetchFromAutosuggestions([data, language]));
    }, 1000),
    [] // will be created only once initially
  );

  return (
    <>
      {showMobileViewInput ? (
        <MobileViewInput
          inputId="mFrom-select-input"
          title={content.placeholderFrom}
          placeholder={content.placeholderFrom}
          data={fromAutosuggestions}
          onInputChange={throttledFetch}
          isLoading={isLoading}
          onSelect={(e) => {
            if (e) {
              dispatch(setSelectedFromLocation(e));
              dispatch(setFromAutosuggestions([e]));
            }
          }}
          onClose={() => {
            _showMobileViewInput(false);
          }}
        />
      ) : (
        <div className="inputFrom">
          <RegularViewInput
            isLoading={isLoading}
            value={selectedFromLocation}
            onFocus={(e) => {
              e.stopPropagation();
              if (isMobile || isWinPhone || isTablet || isAndroid || isIOS){
                  _showMobileViewInput(true);
                if (initialRender.current) {
                  initialRender.current = false;
                }
              }
            }}
            onBlur={() => {
              dispatch(setFromAutosuggestions([]));
            }}
            placeholder={content.placeholderFrom}
            data={fromAutosuggestions}
            onInputChange={throttledFetch}
            onSelect={(e) => {
              if (e) {
                dispatch(setSelectedFromLocation(e));
                dispatch(setFromAutosuggestions([e]));
              }
            }}
          />
        </div>
      )}
    </>
  );
});
