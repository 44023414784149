import React, { useEffect } from "react";
import styles from './Parameter.module.css';
import ChangePassword from '../../Auth/ChangePassword';
import PrimaryInput from "../../../components/PrimaryInput/PrimaryInput";
import userImages from "../user_images";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout, setLogin, userData } from "../../Auth/AuthSlice";
import PrimaryButton from "../../../components/PrimaryButton/PrimaryButton";
import PrimaryButtonAsync from "../../../components/PrimaryButton/PrimaryButtonAsync";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,TextField } from "@material-ui/core";
import Axios from "../../../api";
import { setAlert } from "../../../app/AppSlice";
import { useTranslation } from 'react-i18next';

export default function Parameter() {
  const userDetail = useSelector(userData);
  const [state, setState] = useState({avtar: userDetail.avtar, name: userDetail.name});
  const [openPasswordChangeDialog, setOpenPasswordChangeDialog] = useState(false);
  const [openDeleUserAccount, setOpenDeleUserAccount] = useState(false);
  const [deleteMyAccountText, setDeleteMyAccountText] = useState("");
  const [isDeleting, _isDeleting] = useState(false);
  const [unlinkDialogOpen, setUnlinkDialogOpen] = useState(false);
  const [unlinking, setUnlinking] = useState(false);

  useEffect(() => {
    Axios.interceptors.response.use(response => {
      if(response.data.message === "password changed"){
        setOpenPasswordChangeDialog(false);
      }
      return response
    })
  }, [])
  
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const updateUser = async () => {
    try {
    const {data} = await Axios.post('auth/updateUser', state);
      dispatch(setLogin({user: data.user,}));
      dispatch(setAlert({message: t("Successfully updated"), showAlert: true, duration: 6000, severity:'success'}));
    } catch (error) {
      if(error.response){
        dispatch(setAlert({message: error.response.data.message,showAlert: true, duration: 6000, severity:'error'}))
      }
    }
  }

  const deleteUser = async () => {
    _isDeleting(true);
    try {
      await Axios.post('auth/deleteAccount');
    } catch (error) {
      // something failed
    }
    _isDeleting(false);
  }

  const unLinkGoogle = async () => {
    setUnlinking(true)
    try {
      const {data} = await Axios.post('auth/unlinkGoogle');
      dispatch(setLogin({user: data.user}));
      dispatch(setAlert({message: t("Your Google account is unlinked"), showAlert: true, duration: 6000, severity:'info'}));
      if(userDetail.sessionType === "google"){
        dispatch(logout());
      }
    } catch (error) {
      dispatch(setAlert({message: t("Something went wrong!"), showAlert: true, duration: 6000, severity:'error'}));
    }
    setUnlinkDialogOpen(false)
    setUnlinking(false)
  }

  return (
    <>
      <div className="Balance">
        <div className={styles.form_container} style={{borderRadius:'5px'}}>
          <div className={styles.input_container}>
            <label style={{ marginRight: 10 }} htmlFor="name">
              {t('Name')} {" "}
            </label>
            <PrimaryInput
              name="name"
              value={state.name}
              placeholder={userDetail.name}
              onChange={(e) => {
                setState({ ...state, name: e.target.value });
              }}
            />
          </div>
          <div className={styles.input_container}>
            <label style={{ marginRight: 10, minWidth:'fit-content'}} htmlFor="name">
              Avatar :{" "}
            </label>
            <div className={styles.avtar_container}>
              {Object.keys(userImages).map((image, index) => {
                return (
                      <img 
                      alt="img"
                      style={image === state.avtar ? { borderColor : userImages[image].color } : { borderColor : 'transparent' } }
                      
                      onClick={() => {
                        setState({ ...state, avtar: image });
                      }}

                      onMouseEnter={(e) => {
                        if (image !== state.avtar)
                          e.currentTarget.style.borderColor =
                            userImages[image].color;
                      }}

                      onMouseLeave={(e) => {
                        if (image !== state.avtar) {
                          e.currentTarget.style.borderColor = "transparent";
                        }
                      }}


                      src={userImages[image].src} key={index} />

                );
              })}
            </div>
          </div>
          <div className={styles.saveBtnCtn}>
            <PrimaryButtonAsync disabled={(()=>{
              if(state.name === userDetail.name && state.avtar === userDetail.avtar){
                return true
              } else if(state.name && state.avtar && state.name.length > 2){
                return false;
              } else {
                return true;
              }
            })()} onClick={updateUser} style={{ width: 174 }}>{t('Save')}</PrimaryButtonAsync>
          </div>
        </div>
        <div className={styles.btn_wraper}>
        <div className={styles.btn_container}>
          {userDetail.status.emailVerified?
            <PrimaryButton className={styles.btn_style} onClick={()=>{setOpenPasswordChangeDialog(true)}}>
              {t('Change password')}
            </PrimaryButton>
          : null
          }
          <PrimaryButton className={styles.btn_style} onClick={()=>{setOpenDeleUserAccount(true)}}>
            {t("Delete account")}
          </PrimaryButton>
        </div>
        {userDetail.status.googleVerified && userDetail.status.emailVerified && <div><PrimaryButton onClick={()=>{setUnlinkDialogOpen(true)}} style={{ marginTop:10 }}>{t("Unlink Google account")}</PrimaryButton></div>}
        </div>
      </div>
      {/* change password dialog */}
      <Dialog fullWidth={true} maxWidth={'md'} open={openPasswordChangeDialog} onClose={()=>{setOpenPasswordChangeDialog(false)}} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('Change password')}</DialogTitle>
        <DialogContent>
          <ChangePassword />
        </DialogContent>
      </Dialog>
      {/* delete account dialog dialog */}
      <Dialog fullWidth={true} maxWidth={'md'} open={openDeleUserAccount} onClose={()=>{setOpenDeleUserAccount(false)}} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t("Delete account")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("Do you want to delete your account? Confirm by typing")} "<b>{t("Delete my account")}</b>"
          </DialogContentText>
          <TextField
            width="40%"
            value={deleteMyAccountText}
            onChange={(e)=>{setDeleteMyAccountText(e.target.value)}}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={isDeleting} onClick={()=>{setOpenDeleUserAccount(false)}} color="primary">
            {t('Cancel')}
          </Button>
          <Button onClick={deleteUser} color="primary" disabled={deleteMyAccountText !== t("Delete my account")}>
            {t('Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={unlinkDialogOpen}>
        <DialogTitle id="form-dialog-title">{t("Do you want to continue?")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {userDetail.sessionType === "google" ? t("You are currently logged in with Google, if you continue Google unlink, therefore you will be logout.") : t("This action will unlink your google account.") } 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button disabled={unlinking} onClick={()=>{setUnlinkDialogOpen(false)}} color="primary">
            {t('Cancel')}
          </Button>
          <Button onClick={unLinkGoogle} color="primary">
            {t('Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
