import * as turf from '@turf/turf'

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
}

export const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
}

export const getGreatCurveLineString = ({coord1, coord2}, color = '#6296ca') => {
    const route = {
        'type': 'FeatureCollection',
        'features': [
            {
                'type': 'Feature',
                'geometry': {
                    'type': 'LineString',
                    'coordinates': [[coord1.lng, coord1.lat], [coord2.lng, coord2.lat]]
                }
            }
        ]
    };
    const H = window.H;
    const steps = 500;
    const lineDistance = turf.length(route);
    const lineString = new H.geo.LineString();
    for (let i = 0; i < lineDistance; i += lineDistance / steps) {
        let segment = turf.along(route.features[0], i);
        lineString.pushPoint({ lat: segment.geometry.coordinates[1], lng: segment.geometry.coordinates[0] });
    }
    const newLines = new H.map.Polyline(lineString, { style: { lineWidth: 4, strokeColor: color } });
    return newLines;
}