import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from "./CustomModal.module.css"

function CustomModal({onClose, children, style, className}) {
    useEffect(() => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add(`${styles['modal-open']}`);
        return () => {body.classList.remove(`${styles['modal-open']}`)};
    },[])
    return (
        <div className={styles.modalContainer}>
            <div style={style} className={`${styles.modal} ${className}`}>
            {onClose && <span className={styles.closeBtn} onClick={onClose}>&times;</span>}
                {children}
            </div>
        </div>
    )
}

CustomModal.defaultProps = {
    className: "",
    style: {}
}

CustomModal.propTypes = {
    onClose: PropTypes.func,
    style: PropTypes.object,
    className: PropTypes.string
}

export default CustomModal

