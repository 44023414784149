import { createAsyncThunk } from '@reduxjs/toolkit';
const axios = require('axios');

  export const askElectricityCO2Factor = createAsyncThunk("askElectricityFactor", (country) => {
    return axios.get(`${process.env.REACT_APP_API}/electricity`, {
      params: {
        country: country
      }
    }).then(function(result) {
      return {
        co2: result.data.co2,
        country: result.data.country
      }
  });
  });