import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectPathBicycle, selectBicycleCO2 } from './PathBicycleSlice';

import {CO2andpercentCO2} from '../Util/CO2andpercentCO2'

import { secondsToHm } from '../Util/secondsToHm';
import BicycleIcon from '../Util/vehicles_icons/bike.png'

import { useTranslation } from 'react-i18next';


// IMPORT SELECTED PATH
import { selectDisplayedPath, setActivePath, setDisplayedPath, setHoveredPath } from '../PathListSlice';


export const PathBicycleContainer = React.forwardRef(({onClick}, ref) => {

    const dispatch = useDispatch();

    // IMPORT THE STATES
    const SelectedPath = useSelector(selectDisplayedPath);
    const Path = useSelector(selectPathBicycle);
    const BicycleCO2 = useSelector(selectBicycleCO2);
    const { t } = useTranslation();
    return (
          
        <div className="PathAndAlternatives" key={ref} onClick={onClick}>

            {Path.routes.length ? 
            <div className={SelectedPath === 'bicycle' ? 'ActivePath' : 'Path'}  >

                <div className="PathMain" 
                        onMouseEnter={() => { if (SelectedPath !== 'bicycle') { dispatch(setHoveredPath({ steps: [{ ...Path.routes[0], type: 'bicycle' }], type: 'bicycle', totalCO2: Path.CO2displayed })) } }}
                        onMouseLeave={() => { dispatch(setHoveredPath('')); }}
                        onClick={() => { if (SelectedPath !== 'bicycle') { dispatch(setDisplayedPath('bicycle')); dispatch(setActivePath({ steps: [{ ...Path.routes[0], type: 'bicycle' }], type: 'bicycle', totalCO2: Path.CO2displayed })) } }}>

                    <h2 className="TransportType" >{t('bicycle').charAt(0).toUpperCase() + t('bicycle').slice(1)}</h2>

                    <div className="PathInformation">     

                        <div className="IconAndTypeAndCO2">

                            <div className="IconAndType" >

                                <div className="RouteType" >{t("Best route")}</div>

                                    <div className="Icon" >
                                        <img className='BicyleIcon' src={BicycleIcon} alt='BikeIcon' />
                                    </div>                                  

                                </div>

                            <CO2andpercentCO2 CO2={((Path.routes[0].distance / 1000 * (BicycleCO2.operation + BicycleCO2.manufacture + BicycleCO2.infrastructure)))} />

                        </div>    

                        <div className="DurationContainer">

                        <p className="Duration">{secondsToHm(Path.routes[0].duration, {hour: t('hours'), hours: t('hours'), minute: t('minute'), minutes: t("minutes")})}</p>


                        </div>

                    </div>    

                </div>


                    <div className="PathSecond">
                        
                    </div>

            </div>
            : null}

        </div>
    )
});
