import { createSlice } from '@reduxjs/toolkit';

export const PathListSlice = createSlice({
  name: 'PathList',
  initialState: {
    displayedPath : '',
    mouseOnPath : '',
    activePath: '',
    hoveredPath: ''
  },
  reducers: {
    setDisplayedPath(state, action) {
      return {
        ...state,
        displayedPath : action.payload,
      }
    },
    setMouseOnPath(state, action) {
      return {
        ...state,
        mouseOnPath : action.payload,
      }
    },
    setActivePath: (state, action) => {
      return {
        ...state,
        activePath: action.payload
      }
    },
    setHoveredPath: (state, action) => {
      return {
        ...state,
        hoveredPath: action.payload
      }
    }
  }
});


export const { setDisplayedPath, setMouseOnPath, setActivePath, setHoveredPath } = PathListSlice.actions;


export const selectDisplayedPath = state => state.PathList.displayedPath;
export const selectMouseOnPath = state => state.PathList.mouseOnPath;
export const selectHoveredPath = state => state.PathList.hoveredPath;
export const selectActivePath = state => state.PathList.activePath;

export default PathListSlice.reducer;
