import { createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios");

export const askElectricityPrice = createAsyncThunk(
  "askElectricityPrice",
  (country) => {
    return axios
      .get(`${process.env.REACT_APP_API}/electricityprices`, {
        params: {
          country: country,
        },
      })
      .then(function (result) {
        return {
          price: result.data.price,
          country: result.data.country,
        };
      });
  }
);
