import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const dateSlice = createSlice({
  name: 'date',
  initialState: {
    value: (new Date()).toString(),
    regularDate: {
      startDate: new Date(),
      endDate: new Date(moment({ month: new Date().getMonth() + 1 }).clone().endOf('month').format('YYYY-MM-DD hh:mm'))
    },
    timesPerWeek: 1
  },
  reducers: {
    setDate: (state, action) => {
      let dateModified = (new Date((new Date (action.payload)).setHours(6,30,0))).toString()
      if(new Date (dateModified) < new Date()) {
        return {
          ...state,
          value : (new Date()).toString()
        }
      } else {
        return {
          ...state,
          value : dateModified
        }
      }
    },
    setPastDate: (state, action) => {
      return {
        ...state,
        value : action.payload
      }
    },
    setRegularDate: (state, action) => {
      return {
        ...state,
        regularDate: action.payload,
      }
    },
    setTimesPerWeek: (state, action) => {
      return {
        ...state,
        timesPerWeek: action.payload
      }
    },
  }
});

export const { setDate, setRegularDate, setTimesPerWeek, setPastDate } = dateSlice.actions;

export const selectTimesPerWeek = state => state.date.timesPerWeek;
export const selectDate = state => state.date.value;
export const selectRegularDate = state => state.date.regularDate;

export default dateSlice.reducer;
