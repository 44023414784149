import React from 'react';
import { useSelector } from 'react-redux';
// IMPORT CSS
import '../../../../style/PathList.css';
import loadinglogo from './loading.svg'; 
// IMPORT THE PATH
import { selectPathCar, selectCarLoading } from './Car/PathCarSlice';
import { selectPathBus, selectBusLoading } from './Transit/Bus/PathBusSlice';
import { selectPathBicycle, selectBicycleLoading } from './Bicycle/PathBicycleSlice';
import { selectPathTrain, selectTrainLoading } from './Transit/Train/PathTrainSlice';
import { selectPathPlane, selectPlaneLoading } from './Plane/PathPlaneSlice';

// IMPORT THE PATH COMPONENTS
import { PathCarContainer } from './Car/PathCar';
import { PathBusContainer } from './Transit/Bus/BusPath';
import { PathTrainContainer } from './Transit/Train/TrainPath';
import { PathBicycleContainer } from './Bicycle/PathBicycle';
import { PathPlaneContainer } from './Plane/PathPlane';
import FlipMove from 'react-flip-move';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const Banner = React.forwardRef((props, ref) => {
    const { t } = useTranslation();

    const journeyType = props.journeyType;
    const getBanner = React.useMemo(()=>{
        if(journeyType === 'PAST_JOURNEY') {
            return <div className="banner-container" ref={ref}>
                 <h2>{t('Select the journey you made')}</h2>
            </div>
        } else if(journeyType === 'REGULAR_JOURNEY') {
            return <div className="banner-container" ref={ref}>
                <h2>{t("Select your regular journey")}</h2>
            </div>
        }
        return ''
    }, [journeyType, ref, t])
    return <React.Fragment> {getBanner} </React.Fragment>
})

export function PathList( {journeyType} ) {
    const history = useHistory();
    // SELECT PATHS
    const PathCar = useSelector(selectPathCar);
    const PathBus = useSelector(selectPathBus);
    const PathBicycle = useSelector(selectPathBicycle);
    const PathTrain = useSelector(selectPathTrain);
    const PathPlane = useSelector(selectPathPlane);

    // SELECT LOADING STATES
    const TrainLoading = useSelector(selectTrainLoading);
    const BusLoading = useSelector(selectBusLoading);
    const CarLoading = useSelector(selectCarLoading);
    const BicycleLoading = useSelector(selectBicycleLoading);
    const PlaneLoading = useSelector(selectPlaneLoading);

    const allPath = [
        PathCar,
        PathBus,
        PathBicycle,
        PathTrain,
        PathPlane
    ]
        return (

            <div className="PathListAndLoading">
                <div className="PathList">
                    
                    
                    <FlipMove className='FlipMove' appearAnimation="fade" enterAnimation="fade" leaveAnimation="elevator" >
                        <Banner journeyType={journeyType} />
                        {
                    
                        allPath.sort((a, b) => a.CO2displayed > b.CO2displayed ? 1 : -1).map(path => {
                            // if(!path?.id) return null
                            if (path.id === 'car' && typeof path.routes !== 'undefined' && path.routes.length > 0) {
                                return <PathCarContainer key={path.id} onClick={()=>{history.push('/map?path=car')}} />                         
                            } else if (path.id === 'train' && typeof path.routes !== 'undefined' && path.routes.length > 0) {
                                return <PathTrainContainer key={path.id} onClick={()=>{history.push('/map?path=train')}} />
                            } else if(path.id === 'bus' && typeof path.routes !== 'undefined' && path.routes.length > 0) {
                                return <PathBusContainer key={path.id} onClick={()=>{history.push('/map?path=bus')}} />
                            } else if (path.id === 'plane' && typeof path.routes !== 'undefined' && path.routes.length > 0) {
                                return <PathPlaneContainer key={path.id}  onClick={()=>{history.push('/map?path=plane')}} />
                            } else if (path.id === 'bicycle' && typeof path.routes !== 'undefined' && path.routes.length > 0){
                                return <PathBicycleContainer key={path.id}  onClick={()=>{history.replace('/map')}} />
                            }
                             else {
                                return null
                            }
                        })}

                    </FlipMove>             
                </div>

                {TrainLoading || CarLoading || BusLoading || BicycleLoading || PlaneLoading ? 
                    <div className={TrainLoading & CarLoading & BusLoading & BicycleLoading & PlaneLoading ? 'BigLoadingLogoContainer' : 'LittleLoadingLogoContainer' }>
                        <div className={TrainLoading & CarLoading & BusLoading & BicycleLoading & PlaneLoading ? 'BigLoadingLogo' : 'LittleLoadingLogo' }>
                            <img id='loadingLogo'
                            src={loadinglogo} 
                            alt="loading"/>
                        </div>
                    </div>
                : null}
            </div>
        )
};
