import React from 'react'
import axios from '../../api/index'
import { Dialog,  DialogContentText, IconButton, Typography, withStyles,  } from '@material-ui/core'
import GoogleLogin from 'react-google-login'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux'
import { setLogin } from '../../containers/Auth/AuthSlice'
import { selectOpenGoogleLoginModal, setAlert, setOpenGoogleLoginModal,  } from '../../app/AppSlice'
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    width: '60%'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

/*
const useStyles = makeStyles({
  table: {
    minWidth: 650,
    width: 'auto'
  },
});
*/

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function GoogleLoginModal() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const openGoogleLoginModal = useSelector(selectOpenGoogleLoginModal);
    const responseGoogle = async (response) => {
        try {
          const {data} = await axios.post('/auth/google-login',{accessToken: response.accessToken,type:"google"});
          if(data.user){
            dispatch(setLogin({user: data.user}));
            dispatch(setAlert({message: t('Successfully logged in'), showAlert: true, duration: 6000, severity:'success'}));
            dispatch(setOpenGoogleLoginModal(false));
          }
        } catch (err) {
          if(err.response){
            dispatch(setAlert({message: err.response.data.message, showAlert: true, duration: 6000, severity:'error'}));
          }
        }
      }


    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={openGoogleLoginModal}
        onClose={() => {
          dispatch(setOpenGoogleLoginModal(false));
        }}
        maxWidth="xs"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            dispatch(setOpenGoogleLoginModal(false));
          }}
        >
          {t('Google login')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('You previously logged in with google')}
          </DialogContentText>
          <GoogleLogin
            clientId={
              "91140303370-4bek32qk73h5fpfe2mlcmhmpbi9c4ndu.apps.googleusercontent.com"
            }
            buttonText="Login with Google"
            onSuccess={responseGoogle}
            className="g-btn"
            icon={true}
            onFailure={responseGoogle}
            cookiePolicy={"single_host_origin"}
            theme={"light"}
          />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    );
}

GoogleLoginModal.propTypes = {

}

export default GoogleLoginModal

