import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { setPaths } from "../../../../containers/User/UserPathSlice";
import {
  setFromAutosuggestions,
  selectSelectedFromLocation,
  setSelectedFromLocation
} from "../../../SearchBar/From/FromAutosuggestionsSlice";
import {
  selectSelectedToLocation,
  setSelectedToLocation,
  setToAutosuggestions,
} from "../../../SearchBar/To/ToAutosuggestionsSlice";
import { setPathBus } from "../../../MapApp/Results/PathList/Transit/Bus/PathBusSlice";
import { setPathTrain } from "../../../MapApp/Results/PathList/Transit/Train/PathTrainSlice";
import { setPathPlane } from "../../../MapApp/Results/PathList/Plane/PathPlaneSlice";
import { setActivePath, setDisplayedPath, setHoveredPath } from "../../../MapApp/Results/PathList/PathListSlice";
import { askCarPath } from "../../../MapApp/Results/PathList/Car/Util/askCarPath";
import { askBicyclePath } from "../../../MapApp/Results/PathList/Bicycle/Util/askBicyclePath";
import { askElectricityCO2Factor } from "../../../MapApp/Results/PathList/Util/askElectricityCO2Factor";
import { setAddPathToBallance } from "../../../MapApp/AddPathToBallance/AddPathToBallanceSlice";
import { askElectricityPrice } from "../../../MapApp/Results/PathList/Car/Util/askElectricityPrice";
import { askGazolinePrice } from "../../../MapApp/Results/PathList/Car/Util/askGazolinePrice";
import { askTransitPath } from "../../../MapApp/Results/PathList/Transit/Util/askTransitPath";
import { askPlanePath } from "../../../MapApp/Results/PathList/Plane/Util/askPlanePath";
import {
  setPathCar,
  resetPassengerNumber,
  resetElectric,
} from "../../../MapApp/Results/PathList/Car/PathCarSlice";
import { selectDate, setDate, setPastDate } from "../../../SearchBar/DateSlice";
import { FromInput } from "../../../SearchBar/From/FromInput";
import { ToInput } from "../../../SearchBar/To/ToInput";
import CustomModal from "../../../../components/Modal/CustomModal";
import { setAlert } from "../../../../app/AppSlice";
import { getDistanceFromLatLonInKm } from '../../../../utils/calc';
import styles from "./SearchModal.module.css";
import PrimaryButton from "../../../../components/PrimaryButton/PrimaryButtonAsync";
import { useTranslation } from "react-i18next";
import InverseArrowButton from "../../../../components/InverseArrowButton";

const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#F5F5F5",
    },
    secondary: {
      main: "#592689",
    },
  },
  typography: {
    htmlFontSize: 18,
  },
});

const SearchModal = ({ show, onClose }) => {
  const { t, i18n } = useTranslation();
  let history = useHistory();
  const dispatch = useDispatch();
  const from = useSelector(selectSelectedFromLocation);
  const to = useSelector(selectSelectedToLocation);
  const selectedDate = useSelector(selectDate);

  const getPossiblePastDate = React.useMemo(() => {
    if (show) {
      const currentTime = moment().startOf("day").hour(12);
      const tempDate = moment(selectedDate);
      const tempSelectedDate = moment(
        `${tempDate.year()}-${tempDate.month() + 1}-${tempDate.date()}`
      );
      if (currentTime.diff(tempSelectedDate, "days") < 7) {
        return tempSelectedDate.toDate().toISOString();
      } else {
        for (let index = 0; index < 7; index++) {
          const previousPossibleDay = moment().subtract(index, "days");
          if (previousPossibleDay.day() === tempSelectedDate.day()) {
            return previousPossibleDay.toDate().toISOString();
          }
        }
        return currentTime.toDate().toISOString();
      }
    }
  }, [selectedDate, show]);

  React.useEffect(() => {
    return () => {
      if(!show){
        dispatch(setDate(new Date().toString()));
      }
    };
  }, [dispatch, show]);

  const getPossibleFutureDate = React.useMemo(() => {
    if (show) {
      const tempDate = moment(selectedDate);
      const tempSelectedDate = moment(
        `${tempDate.year()}-${tempDate.month() + 1}-${tempDate.date()}`
      );
      for (let index = 1; index < 8; index++) {
        const upcomingPossibleDay = moment().add(index, "days");
        if (upcomingPossibleDay.day() === tempSelectedDate.day()) {
          return upcomingPossibleDay.toDate().toISOString();
        }
      }
      return moment().toDate().toISOString();
    }
  }, [selectedDate, show]);
  React.useEffect(() => {
    if (show) {
      dispatch(setDate(new Date()));
      dispatch(setFromAutosuggestions([]));
      dispatch(setSelectedFromLocation(null));
      dispatch(setSelectedToLocation(null));
      dispatch(setToAutosuggestions([]));
      dispatch(setDisplayedPath(""));
      dispatch(setPathCar({ id: "car", routes: [], CO2displayed: 0 }));
      dispatch(setPathPlane(['reset']));
      dispatch(setPathBus({ id: "bus", routes: [], CO2displayed: 0 }));
      dispatch(setPathTrain({ id: "train", routes: [], CO2displayed: 0 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return show ? (
    <CustomModal
      className={styles.searchModal}
      onClose={() => {
        onClose(false);
      }}
    >
      <div className={styles.container}>
        <h2 className={styles.title}>{t("Search for the journey you made")}</h2>
        <div className={styles["input-container"]}>
          <FromInput />
            <InverseArrowButton />
          <ToInput />
        </div>
        <div className="SearchBar-fields-DateAndHour">

          <div className="DateAndTimePicker">
            <MuiThemeProvider theme={customTheme}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={i18n.language === "fr" ? frLocale : null}
              >
                <KeyboardDatePicker
                  disableFuture={true}
                  id="time-picker"
                  variant={/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? "standard" : "inline"}
                  format={i18n.language === 'fr' ? "dd MMM yyy" : "dd MMM yyy"}
                  ampm={false}
                  autoOk={true}
                  value={selectedDate}
                  onChange={(date) => {
                    dispatch(setPastDate(date.toString()));
                  }}
                />
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
          </div>
        </div>
        <div className="SearchBar-submit-container">
          <PrimaryButton
            className="SearchBar-submit"
            onClick={() => {
              if (from && to) {
                dispatch(setActivePath(''));
                dispatch(setHoveredPath(''));
                dispatch(setPaths([]));
                dispatch(setAddPathToBallance(false));
                dispatch(setDisplayedPath(""));
                dispatch(setPathCar({ id: "car", routes: [], CO2displayed: 0 }));
                dispatch(resetElectric());
                dispatch(resetPassengerNumber());
                dispatch(setPathBus({ id: "bus", routes: [], CO2displayed: 0 }));
                dispatch(setPathTrain({ id: "train", routes: [], CO2displayed: 0 }));
                dispatch(askCarPath([from,to,new Date(selectedDate).toISOString()])).then(() => {
                  dispatch(askElectricityPrice(from.country))
                    .then(dispatch(askElectricityCO2Factor(from.country)))
                    .then(dispatch(askGazolinePrice(from.country)));
                });
                if (getDistanceFromLatLonInKm( from.position.lat, from.position.lng, to.position.lat, to.position.lng) < 70) {
                  dispatch(askBicyclePath([from,to,new Date(selectedDate).toISOString()]));
                }
                if (getDistanceFromLatLonInKm(from.position.lat,from.position.lng,to.position.lat,to.position.lng) < 500) {
                  dispatch(setPathPlane(["CO2", 0.258]));
                } else if (getDistanceFromLatLonInKm(from.position.lat,from.position.lng,to.position.lat,to.position.lng) < 5000) {
                  dispatch(setPathPlane(["CO2", 0.187]));
                } else {
                  dispatch(setPathPlane(["CO2", 0.152]));
                }
                dispatch(askTransitPath([ from, to, getPossiblePastDate,]));
                dispatch(askPlanePath([ from, to, getPossibleFutureDate, getDistanceFromLatLonInKm( from.position.lat, from.position.lng, to.position.lat, to.position.lng)]));
                history.push("/map", { journeyType: "PAST_JOURNEY" });
              } else if (!from && !to) {
                dispatch(setAlert({message: t("We haven't found your departure and arrival points"),showAlert: true, duration: 6000, severity:'error'}));
              } else if (!from && to) {
                dispatch(setAlert({message: t("We haven't found your departure point"),showAlert: true, duration: 6000, severity:'error'}));
              } else {
                dispatch(setAlert({message: t("We haven't found your arrival point"),showAlert: true, duration: 6000, severity:'error'}));
              }
            }}
          >
            <span role="button" href="#" style={{ textDecoration: "none" }}>
              {t('LetsGo')}
            </span>
          </PrimaryButton>
        </div>
      </div>
    </CustomModal>
  ) : null;
};

SearchModal.prototype = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

SearchModal.defaultProps = {
  show: false,
  onClose: () => {},
};

export default SearchModal;
