import transitCarbonData from "./transitCarbonData.json";

export const getTranistCarbon = (type, distance) => {
  const carbonData = transitCarbonData.filter(
    (obj) => obj.transportTypeName === type
  )[0];
  if (carbonData) {
    const { operation, manufacture, infrastructure } = carbonData;

    const response = {
      operation: operation,
      manufacture: manufacture,
      infrastructure: infrastructure,
      CO2: (operation + manufacture + infrastructure) * (distance / 1000)
    }

    return response;

  } else {
    return 0;
  }
};