import React, {useEffect} from 'react';

import transport from './transport.png'

import { useTranslation } from 'react-i18next';

/*
const data01 = [
  { name: 'Road transport', value: 71.7 }, { name: 'Railways', value: 0.5 },
  { name: 'Aviation', value: 13.9 }, { name: 'Maritime', value: 13.4 },
  { name: 'Other transports', value: 0.5 }, 
];
*/

export default function Transport() {

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  const { t } = useTranslation();

  return (

    <div className='Pages'>

      <h1>{t("Transport sector")}</h1>

      <div className='PagesImage'>
        <img src={transport} alt="Logo" />
      </div>
 
      <div className='TransportFlexContainer' style={{marginTop:'20px'}}>
        <div className='TransportDataContainer' style={{color:"#E0A751"}}>
          1/4
        </div>
        <div className='TransportTextContainer'>
        

        
        <p dangerouslySetInnerHTML={{__html: t("A staggering ¼ of all direct CO<sub>2</sub> emissions comes from the transport sector. In the EU-28 in 2017, 27% of total greenhouse gas emissions were emitted by the transport sector.")}} />

        </div>
      </div>
      
      <div className='TransportFlexContainer' style={{marginTop:'20px'}}>
        <div className='TransportDataContainer' style={{color:"#2C2D40"}}>
          72<span style={{fontSize:'50px'}}>%</span>
        </div>
        <div className='TransportTextContainer'>
        

        <p>{t("Road transport accounts for more than 72% of all transport emissions, while aviation and maritime for 14% and 13% respectively. Rail transport represents only 0.5% of total transport sector emissions.")}</p>

        </div>
      </div>

      <br/>

      <p style={{textAlign:'center', fontWeight:'bold'}}>
      {t("But how can I know if the way I choose to travel is sustainable?")}<br />
      {t("And what sustainable transport really is?")}
      </p>
      <br/>

      <p>
      
      </p>

      <p >
      {t("Sustainable transport should be accessible, inclusive, easy, affordable, healthier and cleaner.")}
      <br />
      {t("But how can we achieve that?")}
      </p>

      <p style={{fontWeight:'bold'}}>
      {t("Use the moveco tool. Moveco was designed to help you find the most sustainable way to travel to your destination, keeping track of your GHG emissions, reducing your carbon footprint and offset your carbon emissions.")}
      </p>
 
    </div>
  );
}
