import React, {useEffect} from 'react';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar
} from 'recharts';

import '../../style/Pages.css'

import climatechange from './climatechange.png'

import { useTranslation } from 'react-i18next';

export default function ClimateChange() { 

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  const ClimateData =
  [
    {
      "year": 1850, 
      "CO2": 925.52}, 
    {
      "year": 1851, 
      "CO2": 951.64}, 
    {
      "year": 1852, 
      "CO2": 981.95}, 
    {
      "year": 1853, 
      "CO2": 1014.3}, 
    {
      "year": 1854, 
      "CO2": 1075.68}, 
    {
      "year": 1855, 
      "CO2": 1105.04}, 
    {
      "year": 1856, 
      "CO2": 1144.66}, 
    {
      "year": 1857, 
      "CO2": 1167.54}, 
    {
      "year": 1858, 
      "CO2": 1191.63}, 
    {
      "year": 1859, 
      "CO2": 1232.48}, 
    {
      "year": 1860, 
      "CO2": 1282.95}, 
    {
      "year": 1861, 
      "CO2": 1318.04}, 
    {
      "year": 1862, 
      "CO2": 1344.99}, 
    {
      "year": 1863, 
      "CO2": 1395.88}, 
    {
      "year": 1864, 
      "CO2": 1449.58}, 
    {
      "year": 1865, 
      "CO2": 1494.09}, 
    {
      "year": 1866, 
      "CO2": 1528.74}, 
    {
      "year": 1867, 
      "CO2": 1594.83}, 
    {
      "year": 1868, 
      "CO2": 1637.6}, 
    {
      "year": 1869, 
      "CO2": 1701.04}, 
    {
      "year": 1870, 
      "CO2": 1738.19}, 
    {
      "year": 1871, 
      "CO2": 1799.66}, 
    {
      "year": 1872, 
      "CO2": 1909.21}, 
    {
      "year": 1873, 
      "CO2": 1991.3}, 
    {
      "year": 1874, 
      "CO2": 1972.06}, 
    {
      "year": 1875, 
      "CO2": 2058.74}, 
    {
      "year": 1876, 
      "CO2": 2096.16}, 
    {
      "year": 1877, 
      "CO2": 2155.76}, 
    {
      "year": 1878, 
      "CO2": 2183.73}, 
    {
      "year": 1879, 
      "CO2": 2294.94}, 
    {
      "year": 1880, 
      "CO2": 2443.19}, 
    {
      "year": 1881, 
      "CO2": 2507.49}, 
    {
      "year": 1882, 
      "CO2": 2607.64}, 
    {
      "year": 1883, 
      "CO2": 2708.33}, 
    {
      "year": 1884, 
      "CO2": 2756.26}, 
    {
      "year": 1885, 
      "CO2": 2789.79}, 
    {
      "year": 1886, 
      "CO2": 2842.28}, 
    {
      "year": 1887, 
      "CO2": 2935.27}, 
    {
      "year": 1888, 
      "CO2": 3143.23}, 
    {
      "year": 1889, 
      "CO2": 3125.7}, 
    {
      "year": 1890, 
      "CO2": 3278}, 
    {
      "year": 1891, 
      "CO2": 3367.52}, 
    {
      "year": 1892, 
      "CO2": 3413.67}, 
    {
      "year": 1893, 
      "CO2": 3427.97}, 
    {
      "year": 1894, 
      "CO2": 3508.15}, 
    {
      "year": 1895, 
      "CO2": 3626.26}, 
    {
      "year": 1896, 
      "CO2": 3706.5}, 
    {
      "year": 1897, 
      "CO2": 3810.11}, 
    {
      "year": 1898, 
      "CO2": 3931.62}, 
    {
      "year": 1899, 
      "CO2": 4117.04}, 
    {
      "year": 1900, 
      "CO2": 4248.69}, 
    {
      "year": 1901, 
      "CO2": 4340.43}, 
    {
      "year": 1902, 
      "CO2": 4427.66}, 
    {
      "year": 1903, 
      "CO2": 4656.57}, 
    {
      "year": 1904, 
      "CO2": 4717.19}, 
    {
      "year": 1905, 
      "CO2": 4913.27}, 
    {
      "year": 1906, 
      "CO2": 5075.03}, 
    {
      "year": 1907, 
      "CO2": 5446.31}, 
    {
      "year": 1908, 
      "CO2": 5372.94}, 
    {
      "year": 1909, 
      "CO2": 5526.68}, 
    {
      "year": 1910, 
      "CO2": 5703.09}, 
    {
      "year": 1911, 
      "CO2": 5791.52}, 
    {
      "year": 1912, 
      "CO2": 5980.22}, 
    {
      "year": 1913, 
      "CO2": 6286.63}, 
    {
      "year": 1914, 
      "CO2": 6023.15}, 
    {
      "year": 1915, 
      "CO2": 6032.34}, 
    {
      "year": 1916, 
      "CO2": 6335.27}, 
    {
      "year": 1917, 
      "CO2": 6531.91}, 
    {
      "year": 1918, 
      "CO2": 6540.27}, 
    {
      "year": 1919, 
      "CO2": 6114.46}, 
    {
      "year": 1920, 
      "CO2": 6630.1}, 
    {
      "year": 1921, 
      "CO2": 6226.59}, 
    {
      "year": 1922, 
      "CO2": 6414.35}, 
    {
      "year": 1923, 
      "CO2": 6912.96}, 
    {
      "year": 1924, 
      "CO2": 6954.86}, 
    {
      "year": 1925, 
      "CO2": 7027.63}, 
    {
      "year": 1926, 
      "CO2": 7011.26}, 
    {
      "year": 1927, 
      "CO2": 7405.86}, 
    {
      "year": 1928, 
      "CO2": 7426.71}, 
    {
      "year": 1929, 
      "CO2": 7775.15}, 
    {
      "year": 1930, 
      "CO2": 7473.68}, 
    {
      "year": 1931, 
      "CO2": 7065.61}, 
    {
      "year": 1932, 
      "CO2": 6746.42}, 
    {
      "year": 1933, 
      "CO2": 6944.78}, 
    {
      "year": 1934, 
      "CO2": 7264.24}, 
    {
      "year": 1935, 
      "CO2": 7485.89}, 
    {
      "year": 1936, 
      "CO2": 7891.01}, 
    {
      "year": 1937, 
      "CO2": 8230.21}, 
    {
      "year": 1938, 
      "CO2": 8002.73}, 
    {
      "year": 1939, 
      "CO2": 8287.4}, 
    {
      "year": 1940, 
      "CO2": 8734.26}, 
    {
      "year": 1941, 
      "CO2": 8930.61}, 
    {
      "year": 1942, 
      "CO2": 9065.47}, 
    {
      "year": 1943, 
      "CO2": 9381.64}, 
    {
      "year": 1944, 
      "CO2": 9531.2}, 
    {
      "year": 1945, 
      "CO2": 8890.06}, 
    {
      "year": 1946, 
      "CO2": 9457.17}, 
    {
      "year": 1947, 
      "CO2": 10146.73}, 
    {
      "year": 1948, 
      "CO2": 10611.88}, 
    {
      "year": 1949, 
      "CO2": 10558.92}, 
    {
      "year": 1950, 
      "CO2": 11063.88}, 
    {
      "year": 1951, 
      "CO2": 11614.47}, 
    {
      "year": 1952, 
      "CO2": 11873.01}, 
    {
      "year": 1953, 
      "CO2": 12226.32}, 
    {
      "year": 1954, 
      "CO2": 12547.51}, 
    {
      "year": 1955, 
      "CO2": 13410.65}, 
    {
      "year": 1956, 
      "CO2": 14117.7}, 
    {
      "year": 1957, 
      "CO2": 14568.35}, 
    {
      "year": 1958, 
      "CO2": 15002.86}, 
    {
      "year": 1959, 
      "CO2": 15632.65}, 
    {
      "year": 1960, 
      "CO2": 16277.19}, 
    {
      "year": 1961, 
      "CO2": 16488.75}, 
    {
      "year": 1962, 
      "CO2": 16984.49}, 
    {
      "year": 1963, 
      "CO2": 17697.14}, 
    {
      "year": 1964, 
      "CO2": 18405.69}, 
    {
      "year": 1965, 
      "CO2": 19108.12}, 
    {
      "year": 1966, 
      "CO2": 19878.45}, 
    {
      "year": 1967, 
      "CO2": 20496.79}, 
    {
      "year": 1968, 
      "CO2": 21410.18}, 
    {
      "year": 1969, 
      "CO2": 22492.68}, 
    {
      "year": 1970, 
      "CO2": 23839.07}, 
    {
      "year": 1971, 
      "CO2": 24594.81}, 
    {
      "year": 1972, 
      "CO2": 25554.19}, 
    {
      "year": 1973, 
      "CO2": 26963.66}, 
    {
      "year": 1974, 
      "CO2": 26985.35}, 
    {
      "year": 1975, 
      "CO2": 26827.33}, 
    {
      "year": 1976, 
      "CO2": 28211.34}, 
    {
      "year": 1977, 
      "CO2": 28703.65}, 
    {
      "year": 1978, 
      "CO2": 29780.13}, 
    {
      "year": 1979, 
      "CO2": 30185.74}, 
    {
      "year": 1980, 
      "CO2": 29891.46}, 
    {
      "year": 1981, 
      "CO2": 29003.72}, 
    {
      "year": 1982, 
      "CO2": 28752.26}, 
    {
      "year": 1983, 
      "CO2": 28903.79}, 
    {
      "year": 1984, 
      "CO2": 29549.85}, 
    {
      "year": 1985, 
      "CO2": 30256.94}, 
    {
      "year": 1986, 
      "CO2": 30593.59}, 
    {
      "year": 1987, 
      "CO2": 31332.08}, 
    {
      "year": 1988, 
      "CO2": 32676.51}, 
    {
      "year": 1989, 
      "CO2": 33147.13}, 
    {
      "year": 1990, 
      "CO2": 32425.66}, 
    {
      "year": 1991, 
      "CO2": 32792.03}, 
    {
      "year": 1992, 
      "CO2": 31988.13}, 
    {
      "year": 1993, 
      "CO2": 32110.38}, 
    {
      "year": 1994, 
      "CO2": 32312.06}, 
    {
      "year": 1995, 
      "CO2": 32972.34}, 
    {
      "year": 1996, 
      "CO2": 33755.54}, 
    {
      "year": 1997, 
      "CO2": 33826.97}, 
    {
      "year": 1998, 
      "CO2": 33716.64}, 
    {
      "year": 1999, 
      "CO2": 34003.82}, 
    {
      "year": 2000, 
      "CO2": 34785.74}, 
    {
      "year": 2001, 
      "CO2": 35058.47}, 
    {
      "year": 2002, 
      "CO2": 35666.88}, 
    {
      "year": 2003, 
      "CO2": 37209.19}, 
    {
      "year": 2004, 
      "CO2": 38771.9}, 
    {
      "year": 2005, 
      "CO2": 39946.06}, 
    {
      "year": 2006, 
      "CO2": 41066.18}, 
    {
      "year": 2007, 
      "CO2": 42064.59}, 
    {
      "year": 2008, 
      "CO2": 42821.36}, 
    {
      "year": 2009, 
      "CO2": 42345.46}, 
    {
      "year": 2010, 
      "CO2": 44301.18}, 
    {
      "year": 2011, 
      "CO2": 45517.39}, 
    {
      "year": 2012, 
      "CO2": 46157.5}, 
    {
      "year": 2013, 
      "CO2": 46236.93}, 
    {
      "year": 2014, 
      "CO2": 46662.56}, 
    {
      "year": 2015, 
      "CO2": 46856.03}, 
    {
      "year": 2016, 
      "CO2": 47156.33}
    ];

    const { t } = useTranslation();

    function numberWithSpaces(x) {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    }

    const data = [
      {
        name: 'Transport',
        trainetbus: 0.70,
        fret: 3.17,
        avion: 3.97,
        voiture: 16.31,
      },
      {
        name: 'Logement',
        Equipement: 2.77,
        Construction: 5.58,
        Energie: 14.02,
      },
      {
        name: 'Consommation',
        Habillement: 6.31,
        Autrebiensetservice: 5.64,
        Achat: 9.76,
      },
      {
        name: 'Alimentation',
        Boissons: 2.17,
        Autres: 4.45,
        ProduitsLaitiers: 3.37,
        ViandeEtPoissons: 9.46,
      },
      {
        name: 'Services',
        ServicesPublicsSante: 12.31,
      },
    ];
    
    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        let tooltip = [];
        for (let i=0; i<payload.length; i++) {
          let tooltipdetail = {
            value: payload[i].value,
            label:  `tooltip_${payload[i].dataKey}`,
            color: payload[i].color
          }
        tooltip.push(tooltipdetail)
        }
        
        return (
          tooltip.reverse().map((entry, index) => 
          <div className="custom-tooltip" index={index} style={{padding:'5px', background:'white', opacity:'0.8', display:'flex', alignContent:'center', alignItems:'center'}}>
            <div style={{height:'15px', width:'15px', backgroundColor:`${entry.color}`, border:'black solid 1px', borderRadius:'3px', marginRight:'10px'}}></div>
            <div className="label">{t(entry.label)} : {Math.round(entry.value)}%</div>
          </div>
          )
        );
      }
      return null;
    };

        
    const CustomTooltip2 = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        let tooltip = [];
        for (let i=0; i<payload.length; i++) {
          let tooltipdetail = {
            year: payload[i].payload.year,
            CO2:  numberWithSpaces(Math.round(payload[i].payload.CO2)),
          }
        tooltip.push(tooltipdetail)
        }
        
        return (
          tooltip.map((entry, index) => 
          <div className="custom-tooltip" index={index} style={{padding:'5px', background:'white', opacity:'0.8'}}>
            <p className="label"><b>{entry.year}</b></p>
            <p>{entry.CO2} Mt CO<sub>2eq</sub></p>
          </div>
          )
        );
      }
      return null;
    };

  return (

    <div className='Pages'>

      <h1>{t('Climate change')}</h1>

      <div className='PagesImage'>
        <img src={climatechange} alt="Logo" />
      </div>

      <h2>{t('The challenge')}</h2>

      <p>{t("At this moment, we have our last chance to limit global temperature rise at 1.5°C. This level will still have serious effects on our environment and livelihoods, but it will be less devastating than higher levels of global warming. We all have to work together to reduce our carbon footprint. With our everyday choices and lifestyle, we can make a difference, fight against the climate emergency and protect our planet and livelihoods.")}</p>
      <p dangerouslySetInnerHTML={{__html: t("Despite the various Conferences of the Parties (COP) and the commitments of the States, our societies - doped with oil - do not stop emitting more greenhouse gases every year. Atmospheric CO<sub>2</sub> levels have increased by 31% between 1850 and today.")}} />
            
      <div style={{ width: '100%', height: 300, marginTop:'20px', marginBottom:'50px' }}>
      <h3 style={{textAlign:'center'}} dangerouslySetInnerHTML={{__html: t("Carbon Emissions Evolution [Mt CO<sub>2eq</sub>]")}} />
        <ResponsiveContainer>
          <AreaChart
          width={500}
          height={400}
          data={ClimateData}
          margin={{
            top: 10, right: 30, left: 0, bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" domain={[1850, 'dataMax']} />
          <YAxis
            tickFormatter={(tick) => {
            return `${numberWithSpaces(tick)}`;
            }}
          />
          <Tooltip content={<CustomTooltip2 />} />
          <Area type="monotone" dataKey="CO2" stroke="#8884d8" fill="#8884d8" />
        </AreaChart>
        </ResponsiveContainer>
      </div>

      <div className='TransportFlexContainer'>
        <div className='TransportDataContainer' style={{color:"#E0A751"}}>
          1.5°<span style={{fontSize:'50px'}}>C</span>
        </div>
        <div className='TransportTextContainer'>
        
        <p dangerouslySetInnerHTML={{__html: t("Many scientists agree that he limits the average temperature rise compared to the 1900s to +1.5°C. Going further will have serious effects on our environment and the functioning of our societies.")}} />
        <p dangerouslySetInnerHTML={{__html: t("We must together reduce our carbon footprint. With our daily choices and the lifestyle we adopt, we can make a difference. The fight against the climate emergency is the major challenge of our generations. <b> Let's take it up! </b>")}} />

        </div>
      </div>

      

      <h2>{t('How much do we have to reduce?')}</h2>

      <div className='TransportFlexContainer' style={{marginTop:'20px'}}>
        <div className='TransportDataContainer' style={{color:"#2C2D40"}}>
          3.2 t
        </div>
        <div className='TransportTextContainer'>
        
        <p dangerouslySetInnerHTML={{__html: t("To stay below this threshold of +1.5°C, emissions must drop from almost 50 gigatonnes of CO<sub>2</sub> currently to 25 gigatons of CO<sub>2</sub> by 2030. By 2030, each person will have to emit less than 3.2 tonnes of CO<sub>2</sub> per year. This equates to a division by almost two of our emissions in 10 years; Or a decrease of 7.6% every year for 10 years.")}} />
        <p>{t("Unfortunately, this division by two cannot be achieved only through technological improvements and energy efficiency. The longer we wait, the higher the step will be.")}</p>

        </div>
      </div>



      <h2>{t("How much do we emit?")}</h2>

      <div className='TransportFlexContainer' style={{marginTop:'20px'}}>
        <div className='TransportDataContainer' style={{color:"#699D51"}}>
          11,2 t
        </div>
        <div className='TransportTextContainer'>
        
        <p dangerouslySetInnerHTML={{__html: t("The average carbon footprint of a French person today is 11.2 tonnes of CO<sub>2</sub> equivalent per year (including 8 tonnes of CO<sub>2</sub> and 3.2 tonnes of CH<sub>4</sub> and N<sub>2</sub>O).")}} />

        </div>
      </div>

      <div style={{ width: '100%', height: 300, marginTop:'20px', marginBottom:'50px' }}>
      <h3 style={{textAlign:'center'}} dangerouslySetInnerHTML={{__html: t("Breakdown by sector [%]")}} />
        <ResponsiveContainer>
        <BarChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis
            tickFormatter={(tick) => {
            return `${tick}%`;
            }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="trainetbus" stackId="a" fill="#592689" />
          <Bar dataKey="fret" stackId="a" fill="#2C2D40" />
          <Bar dataKey="avion" stackId="a" fill="#699D51" />
          <Bar dataKey="voiture" stackId="a" fill="#DB9D47" />

          <Bar dataKey="Equipement" stackId="a" fill="#592689" />
          <Bar dataKey="Construction" stackId="a" fill="#699D51" />
          <Bar dataKey="Energie" stackId="a" fill="#DB9D47" />

          <Bar dataKey="Habillement" stackId="a" fill="#592689" />
          <Bar dataKey="Autrebiensetservice" stackId="a" fill="#699D51" />
          <Bar dataKey="Achat" stackId="a" fill="#DB9D47" />

          <Bar dataKey="Boissons" stackId="a" fill="#592689" />
          <Bar dataKey="Autres" stackId="a" fill="#2C2D40" />
          <Bar dataKey="ProduitsLaitiers" stackId="a" fill="#699D51" />
          <Bar dataKey="ViandeEtPoissons" stackId="a" fill="#DB9D47" />

          <Bar dataKey="ServicesPublicsSante" stackId="a" fill="#592689" />

        </BarChart>
        </ResponsiveContainer>
        
      </div>

      <p style={{textAlign:'center'}} >Source : ravijen.fr</p>


      <h2>{t("The carbon budget idea")}</h2>

      <p dangerouslySetInnerHTML={{__html: t("By taking into account only CO<sub>2</sub> emissions (and therefore not CH<sub>4</sub> and N<sub>2</sub>O), our carbon budget is the following")}} />

      <table>

        <tr style={{border:"1px solid #dddddd", padding:"8px"}}>
          <th style={{border:"1px solid #dddddd", padding:"8px"}}>{t("Temperature rising")}</th>
          <th style={{border:"1px solid #dddddd", padding:"8px"}}><p dangerouslySetInnerHTML={{__html: t("Carbon budget from January 1, 2020<br/>[billion tonnes of CO<sub>2</sub>]")}}></p></th>
        </tr>
        <tr>
          <td style={{border:"1px solid #dddddd", padding:"8px"}}>1,5°C</td>
          <td style={{border:"1px solid #dddddd", padding:"8px"}}>336</td>
        </tr>
        <tr>
          <td style={{border:"1px solid #dddddd", padding:"8px"}}>1,75°C</td>
          <td style={{border:"1px solid #dddddd", padding:"8px"}}>716</td>
        </tr>
        <tr>
          <td style={{border:"1px solid #dddddd", padding:"8px"}}>2°C</td>
          <td style={{border:"1px solid #dddddd", padding:"8px"}}>1 086</td>
        </tr>
      </table>

      <p dangerouslySetInnerHTML={{__html: t("According to population growth estimates, the CO<sub>2</sub> budget per person is between 1.6 and 2.8 tonnes of CO<sub>2</sub> per year between today and 2100. This objective should be compared with the 8 tonnes of CO<sub>2</sub> emissions per person above.")}} />


    </div>

  );
}
