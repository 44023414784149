import { createAsyncThunk } from '@reduxjs/toolkit';
const axios = require('axios');

export const askGazolinePrice = createAsyncThunk("askGazolinePrice", (country) => {
  return axios.get(`${process.env.REACT_APP_API}/gasolineprices`, {
    params: {
      country: country
    }
  }).then(function(result) {
    return {
      price: result.data.price,
      country: result.data.country
    }
});
});
