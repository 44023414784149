import React from "react";
import { useSelector, useDispatch } from "react-redux";
import inverseArrow from "../containers/SearchBar/inverse_arrows.png";
import {
  selectSelectedToLocation,
  setSelectedToLocation,
  setToAutosuggestions,
  selectToAutosuggestions
} from "../containers/SearchBar/To/ToAutosuggestionsSlice";

import {
    setSelectedFromLocation,
    selectSelectedFromLocation,
    setFromAutosuggestions,
    selectFromAutosuggestions
  } from "../containers/SearchBar/From/FromAutosuggestionsSlice.js";



const InverseArrowButton = () => {
  const dispatch = useDispatch();
  const fromAutosuggestions = useSelector(selectFromAutosuggestions);
  const toAutosuggestions = useSelector(selectToAutosuggestions);
  // 
  const selectedTo = useSelector(selectSelectedToLocation);
  const selectedFrom = useSelector(selectSelectedFromLocation);

  return (
    <div className="InverseArrow">
      <img
        id="inverseArrows"
        alt="Inverse arrow"
        src={inverseArrow}
        onClick={() => {
          dispatch(setToAutosuggestions(fromAutosuggestions));
          dispatch(setFromAutosuggestions(toAutosuggestions));
          //
          dispatch(setSelectedToLocation(selectedFrom));
          dispatch(setSelectedFromLocation(selectedTo)); 
        }}
      />
    </div>
  );
};

export default InverseArrowButton;
