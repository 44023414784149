import React from "react";

export default function Data() {
  
  return (
    <>
      <p>
        Data Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Maiores, assumenda nihil et explicabo eius odio ullam itaque, tempore
        similique adipisci earum natus dolore necessitatibus distinctio harum
        quod? Ad accusamus beatae exercitationem odio doloribus sapiente vero
        nostrum fugiat corporis, excepturi inventore facilis et eos possimus
        distinctio sed, voluptatibus autem atque totam?
      </p>

      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores,
        assumenda nihil et explicabo eius odio ullam itaque, tempore similique
        adipisci earum natus dolore necessitatibus distinctio harum quod? Ad
        accusamus beatae exercitationem odio doloribus sapiente vero nostrum
        fugiat corporis, excepturi inventore facilis et eos possimus distinctio
        sed, voluptatibus autem atque totam?
      </p>

      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores,
        assumenda nihil et explicabo eius odio ullam itaque, tempore similique
        adipisci earum natus dolore necessitatibus distinctio harum quod? Ad
        accusamus beatae exercitationem odio doloribus sapiente vero nostrum
        fugiat corporis, excepturi inventore facilis et eos possimus distinctio
        sed, voluptatibus autem atque totam?
      </p>

      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores,
        assumenda nihil et explicabo eius odio ullam itaque, tempore similique
        adipisci earum natus dolore necessitatibus distinctio harum quod? Ad
        accusamus beatae exercitationem odio doloribus sapiente vero nostrum
        fugiat corporis, excepturi inventore facilis et eos possimus distinctio
        sed, voluptatibus autem atque totam?
      </p>

      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores,
        assumenda nihil et explicabo eius odio ullam itaque, tempore similique
        adipisci earum natus dolore necessitatibus distinctio harum quod? Ad
        accusamus beatae exercitationem odio doloribus sapiente vero nostrum
        fugiat corporis, excepturi inventore facilis et eos possimus distinctio
        sed, voluptatibus autem atque totam?
      </p>

      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores,
        assumenda nihil et explicabo eius odio ullam itaque, tempore similique
        adipisci earum natus dolore necessitatibus distinctio harum quod? Ad
        accusamus beatae exercitationem odio doloribus sapiente vero nostrum
        fugiat corporis, excepturi inventore facilis et eos possimus distinctio
        sed, voluptatibus autem atque totam?
      </p>
    </>
  );
}
