import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// IMPORT BUS SLICE
import { selectDisplayedBusPath, selectPathBus } from './PathBusSlice';

// IMPORT SELECTED PATH SLICE
import { selectDisplayedPath, setDisplayedPath, setActivePath, setHoveredPath, selectActivePath} from '../../PathListSlice';

// IMPORT UTILS
import { listIcons } from '../Util/listIcons';
import { calculateDuration } from '../Util/calculateDuration';

// IMPORT COMPONENTS
import {CO2andpercentCO2} from '../../Util/CO2andpercentCO2'

import { useTranslation } from 'react-i18next';


export const PathBusContainer = React.forwardRef(({onClick}, ref) => {

    const dispatch = useDispatch();

    // IMPORT THE STATES 
    const SelectedPath = useSelector(selectDisplayedPath);
    const displayedBusPath = useSelector(selectDisplayedBusPath);
    const PathBus = useSelector(selectPathBus);
    const activePath = useSelector(selectActivePath);

    const { t } = useTranslation();

    function listAllIcons(routes) {

        let AllIcons = [];

        for(let i=0; i < routes.steps.length; i++) {
            AllIcons.push(listIcons(routes.steps[i], i));
        }

        return AllIcons
    }

    return (
        
       
        <div className="PathAndAlternatives" ref={ref}>
            {PathBus.routes[displayedBusPath] &&       
            <div className={SelectedPath === 'bus' ? 'ActivePath' : 'Path'} >

            <div className="PathMain" 
                onMouseEnter={() => { if(SelectedPath !== 'bus'){dispatch(setHoveredPath(PathBus.routes[displayedBusPath]))}}}
                onMouseLeave={() => { dispatch(setHoveredPath(''))}}
                onClick={() => {
                    dispatch(setDisplayedPath("bus"));
                    if (activePath) {
                        if(activePath.id !== PathBus.routes[displayedBusPath].id){
                            dispatch(setActivePath(PathBus.routes[displayedBusPath])) 
                        }
                    } else {
                        dispatch(setActivePath(PathBus.routes[displayedBusPath]));
                    }
                }} 
                >

                <h2 className="TransportType" >{t('bus').charAt(0).toUpperCase() + t('bus').slice(1)}</h2>

                <div className='PathInformation' >                         

                    <div className="IconAndTypeAndCO2">

                        <div className="IconAndType" >

                            <div className="RouteType" >{displayedBusPath === 0 ? t('Best route') : `${t('Alternative')} ${displayedBusPath}`}</div>

                            <div className="Icon" >
                            
                                {listAllIcons(PathBus.routes[displayedBusPath])}

                            </div>

                        </div>

                        <CO2andpercentCO2 CO2={PathBus.routes[displayedBusPath].totalCO2} />
                        
                    </div>

                    <div className="DurationAndStartAt">

                        <p className="Duration">{calculateDuration(PathBus.routes[displayedBusPath], {hour: t('hours'), hours: t('hours'), minute: t('minute'), minutes: t("minutes")})}</p>

                    </div>
                   
                </div>

                {/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ?
                    <div className='SeeOptionsHandlerMobile'  onClick={onClick}>
                        <h4>{t('See options')}</h4>
                    </div>
                : null}

            </div>

            <div className="PathSecond">

                <div className='SeeOptionsHandler'  onClick={onClick}>
                    {t('See options')}
                </div>

            </div>
        </div> }

    </div>


    )
});