import { configureStore } from '@reduxjs/toolkit';

// REDUCER IMPORT
import userReducer from '../containers/Auth/AuthSlice';

import setFromAutosuggestionsReducer from '../containers/SearchBar/From/FromAutosuggestionsSlice';
import setToAutosuggestionsReducer from '../containers/SearchBar/To/ToAutosuggestionsSlice';


import setPathListReducer from '../containers/MapApp/Results/PathList/PathListSlice';
import setPathCarReducer from '../containers/MapApp/Results/PathList/Car/PathCarSlice';
import setPathBusReducer from '../containers/MapApp/Results/PathList/Transit/Bus/PathBusSlice';
import setPathBicycleReducer from '../containers/MapApp/Results/PathList/Bicycle/PathBicycleSlice';
import setPathTrainReducer from '../containers/MapApp/Results/PathList/Transit/Train/PathTrainSlice';
import setPathPlaneReducer from '../containers/MapApp/Results/PathList/Plane/PathPlaneSlice';

import setUserPathReducer from '../containers/User/UserPathSlice';

import setAddPathToBallanceReducer from '../containers/MapApp/AddPathToBallance/AddPathToBallanceSlice';

import setCalculationDetailsReducer from '../containers/MapApp/Results/PathList/CalculationDetails/CalculationDetailsSlice';

import setDateSliceReducer from '../containers/SearchBar/DateSlice';
import TrainCarbonCacheSlice from '../containers/MapApp/Results/PathList/Transit/Train/TrainCarbonCacheSlice';
import AppSlice from './AppSlice';

import LanguageSlice from '../containers/Header/LanguageSlice';

// REDUCER
const reducer = {
  user: userReducer,
  fromAutosuggestions: setFromAutosuggestionsReducer,
  toAutosuggestions: setToAutosuggestionsReducer,
  PathList: setPathListReducer,
  PathCar: setPathCarReducer,
  PathBus: setPathBusReducer,
  PathBicycle: setPathBicycleReducer,
  PathTrain: setPathTrainReducer,
  PathPlane : setPathPlaneReducer,
  UserPath: setUserPathReducer,
  AddPathToBallance: setAddPathToBallanceReducer,
  CalculationDetails: setCalculationDetailsReducer,
  date: setDateSliceReducer,
  trainCarbonCache: TrainCarbonCacheSlice,
  app: AppSlice,
  Language : LanguageSlice
}


export default configureStore({
  reducer,
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
});
