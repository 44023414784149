import React from 'react';

import { listIcons } from './Util/listIcons';
import walkIcon from  '../Util/vehicles_icons/walk.png';

export function PathDetailsContainer(props) {

    return(

        <div className="MoreDetails">

            <div className={props.isDetailsDisplayed === true ? "DetailsContainer" : "DetailsContainerHidden"} >
            
            <table>

                {props.route.steps.map((step, i, list) => {


                    if(step.travelTypeStep === 'pedestrian') {

                        if(i === 0 || i === list.length -1) {

                            return null
                        
                        } else {
                            return (
                                <tr key={i}>
                                    <td className='ImgPathDetails'><img className='walkIcon' src={walkIcon} alt="walk icon" key={`allIconsTrain` + i}  /></td>
                                    <td colSpan='2'>{step.distanceStep} m - {step.durationStep/60} min</td>
                                </tr>
                            )
                        }
  
            

                        } else {
                            return (
                                <>                                                            
                                    <tr>
                                        <td className="tg-0pky"><span className="TimeStep">{new Date (step.departureTimeStep).getHours()}:{(new Date (step.departureTimeStep).getMinutes()<10?'0':'') + new Date (step.departureTimeStep).getMinutes() }</span></td>
                                        <td></td>
                                        <td className="tg-0pky" style={{fontWeight:'600'}}>{step.departureStopStep}</td>
                                    </tr>
                                    <tr>
                                        <td className="tg-0pky"><div className="trait" style={{width:'1.5px',backgroundColor:'black',height:'1.5rem', marginLeft: '10px'}}></div></td>
                                        <td className="tg-0ys1"><span className='ImgPathDetails' style={{width:'10%'}}>{listIcons(step)}</span></td>
                                        <td><span>{step.vehicleCategoryStep} - {step.lineNameStep} - {step.headsign}{step.fares ? <> - <span style={{fontWeight: 600}}>{step.fares} €</span></> : null }</span></td>
                                    </tr>
                                    <tr>
                                        <td className="tg-0pky"><span className="TimeStep"> {new Date (step.arrivalTimeStep).getHours()}:{(new Date (step.arrivalTimeStep).getMinutes()<10?'0':'') + new Date (step.arrivalTimeStep).getMinutes()}</span></td>
                                        <td></td>
                                        <td className="tg-0pky" style={{fontWeight:'600'}}>{step.arrivalStopStep}</td>
                                    </tr>
                                </>                              
                    )
                }})}

            </table>                          

            </div>

        </div> 

    )

}


/*        

 
*/