import avatar1 from './avatar1.jpg';
import avatar2 from './avatar2.jpg';
import avatar3 from './avatar3.jpg';
import avatar4 from './avatar4.jpg';
import avatar5 from './avatar5.jpg';
import avatar6 from './avatar6.jpg';
import avatar7 from './avatar7.jpg';
import avatar8 from './avatar8.jpg';

const userImages = {
    avatar1: {
        color: "#4682B4",
        src: avatar1
    },
    avatar2: {
        src: avatar2,
        color: "rebeccapurple",
    },
    avatar3: {
        src: avatar3,
        color: "orange",
    },
    avatar4: {
        color: "brown",
        src: avatar4
    },
    avatar5: {
        color: "#da2b5b",
        src: avatar5
    },
    avatar6: {
        color: "#9d5b73",
        src: avatar6
    },
    avatar7: {
        color: "rebeccapurple",
        src: avatar7
    },
    avatar8: {
        color: "orange",
        src: avatar8
    },
}
export default userImages;