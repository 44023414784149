import { createAsyncThunk } from '@reduxjs/toolkit';
import store from '../../../../../../app/store';
import { setTrainCarbonData } from '../../Transit/Train/TrainCarbonCacheSlice';
import { getTranistCarbon } from '../../Transit/Util/getTransitCarbon';
const axios = require('axios');

export const askCarPath = createAsyncThunk("askCarPath", async (inValue,{rejectWithValue}) => {
  try {
    const resp = await axios.get('https://router.hereapi.com/v8/routes', {
    params: {
      apiKey:'j2YcVdp86ieJODQGLUDX2f6APJhkmjd9aG7RBZahvUQ',
      origin:`${inValue[0].position.lat},${inValue[0].position.lng}`,
      destination:`${inValue[1].position.lat},${inValue[1].position.lng}`,
      departureTime: `${inValue[2]}`,
      return: 'polyline,turnByTurnActions,travelSummary',
      alternatives: 6,
      transportMode: 'car',
      'ev[freeFlowSpeedTable]': '0,0.097,27,0.097,45,0.097,60,0.103,75,0.110,90,0.110,100,0.129,110,0.129,120,0.135,130,0.142,250,0.226',
      'ev[trafficSpeedTable]': '0,0.097,27,0.097,45,0.103,60,0.110,75,0.110,90,0.116,100,0.135,110,0.135,120,0.142,130,0.142,250,0.232',
      'ev[auxiliaryConsumption]': 1.4,
      'ev[ascent]': 8,
      'ev[descent]': 3.8
    }
  })
     
      if(resp.data.hasOwnProperty("notices")){ //if path not found
        return rejectWithValue(resp.data.notices[0].title);
      }
     
      // Create routes variable
      let routes = [];

      console.log(resp)
  
      // Read the response and create i routes
      for(let i=0; i < resp.data.routes.length; i++) {

        let route = {
          id: `route${i}`,
          type: 'car'
        };

          let sections = [];
          for (let key = 0; key < resp.data.routes[i].sections.length; key++) {
            const onesection = resp.data.routes[i].sections[key];
            let section = {
              id: `section${key}`,
              type: onesection.type,
              distance: onesection.travelSummary.length,
              duration: onesection.travelSummary.duration,
              consumption: onesection.travelSummary.consumption,
              polyline: onesection.polyline,
              tollCost: 0,
              vehicleModeStep: onesection.transport.mode
            }

            if(section.type === 'transit' && section.vehicleModeStep === 'ferry') {
              section.CO2 = getTranistCarbon("ferry",section.distance);
            } else if (section.type === 'transit' && section.vehicleModeStep === 'carShuttleTrain') {
              try {
                const geoData = await axios.get(`https://reverse.geocoder.ls.hereapi.com/6.2/reversegeocode.json?prox=${onesection.departure.place.location.lat},${onesection.departure.place.location.lng},250&mode=retrieveAddresses&maxresults=1&gen=9&apiKey=j2YcVdp86ieJODQGLUDX2f6APJhkmjd9aG7RBZahvUQ&language=en-US`);
                const countryName = geoData.data?.Response.View[0]?.Result[0].Location.Address.Label.trim().split(" ").pop();
                let interCounrtyCache = store.getState().trainCarbonCache // will cache the response
                if(!interCounrtyCache.hasOwnProperty(countryName)){ // not fetch again if already fetched before for the same country
                  const {data} = await axios.get(`${process.env.REACT_APP_API}/trains`, { params: { country: countryName } });
                  store.dispatch(setTrainCarbonData({data,country:countryName}));
                }
                const {operation, manufacture_maintenance, infrastructure} = store.getState().trainCarbonCache[countryName];
                const response = {
                  operation: operation,
                  manufacture: manufacture_maintenance,
                  infrastructure: infrastructure,
                  CO2: (operation + manufacture_maintenance + infrastructure) * (section.distance / 1000)
                }
                section.CO2 = response; 
              } catch (error) {
                section.CO2 = {
                  operation: 0.00529,
                  manufacture: 0.0007,
                  infrastructure:0.0162,
                  CO2: section.distance*(0.00529+0.0007+0.0162)/1000
                }
              }
            }
            sections.push(section);
          }
          route.steps = sections;
          
          routes.push(route);

          let distanceByCar = 0;

          for(let i=0; i<route.steps.length;i++) {
            if(route.steps[i].type === 'vehicle') {
              distanceByCar = distanceByCar + route.steps[i].distance
            }
          }

          let distanceByTransit = 0;

          for(let i=0; i<route.steps.length;i++) {
            if(route.steps[i].type === 'transit') {
              distanceByTransit = distanceByTransit + route.steps[i].distance
            }
          }

          route.distanceByTransit = distanceByTransit;
          route.distanceByCar = distanceByCar;

          let totalCarConsumption = 0;

          for(let i=0; i<route.steps.length;i++) {
            if(route.steps[i].type === 'vehicle') {
              totalCarConsumption = totalCarConsumption + route.steps[i].consumption
            }
          }

          route.totalCarConsumption = totalCarConsumption

          let totalDuration = 0;

          for(let i=0; i<route.steps.length;i++) {
            totalDuration = totalDuration + route.steps[i].duration
          }

          route.totalDuration = totalDuration

          let totalCO2Transit = 0;

          for(let i=0; i<route.steps.length;i++) {
            if(route.steps[i].type === 'transit') {
              totalCO2Transit = totalCO2Transit + route.steps[i].CO2.CO2
            }
          }

          route.totalCO2Transit = totalCO2Transit

      };
  
      // Create the NewPathCar
      let newPathCar = {
        id: 'car',
        routes: routes,
        start_country: 'France',
      };   
  
      
      return newPathCar;
  
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }

});