import React from "react";
import { useDispatch } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';

import { useTranslation } from 'react-i18next';
import ClickableLink from '../Util/ClickableLink.png'

import { setCalculationDetails } from '../CalculationDetails/CalculationDetailsSlice';

export function CO2andpercentCO2({CO2}) {

    const dispatch = useDispatch();
    const { t } = useTranslation();

            return (
                <div className="CO2andpercentCO2">

                                <div className="CO2">

                                    <p><span onClick={() => {dispatch(setCalculationDetails())}}>{CO2 > 10 ? Math.round(CO2) : Math.round(CO2*10)/10} kgCO<sub>2</sub></span>
                                    {/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ?
                                    <span className="ClickableLink"><img className='ClickableLinkImage' src={ClickableLink} alt='Clickable link' /></span>
                                    : null}
                                    </p>

                                </div>

                                <div className="percentCO2">

                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <span className='percentCO2Text'>{Math.round((CO2)/ (2200/365)*100)}% {t("daily carbon credit")}</span>
                                            </td>
                                            <td>
                                                <span className='tooltipCarbonCredit' >
                                                    <Grid item>
                                                        <Tooltip 
                                                            TransitionComponent={Fade} 
                                                            enterTouchDelay={0}
                                                            TransitionProps={{ timeout: 600 }} 
                                                            title={
                                                                <React.Fragment>
                                                                    <span dangerouslySetInnerHTML={{__html: t("If we don't want to emit more CO<sub>2</sub> than the earth can absorbs, we have to limit our annual emissions at 2,2 tonnes per person. It is 6 kgCO<sub>2</sub> per day.")}} />
                                                                </React.Fragment>
                                                            } 
                                                            placement="right" 
                                                            arrow>
                                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={16} height={16} viewBox="0 0 171 171" style={{fill: '#000000'}}><g fill="none" fillRule="nonzero" stroke="none" strokeWidth={1} strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit={10} strokeDasharray="true" strokeDashoffset={0} fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{mixBlendMode: 'normal'}}><path d="M0,171.99544v-171.99544h171.99544v171.99544z" fill="#f5f4f4" /><g><path d="M85.5,164.5875c-43.60927,0 -79.0875,-35.47822 -79.0875,-79.0875c0,-43.60927 35.47823,-79.0875 79.0875,-79.0875c43.60928,0 79.0875,35.47823 79.0875,79.0875c0,43.60928 -35.47822,79.0875 -79.0875,79.0875z" fill="#592689" /><path d="M85.5,8.55c42.42938,0 76.95,34.52062 76.95,76.95c0,42.42938 -34.52063,76.95 -76.95,76.95c-42.42937,0 -76.95,-34.52063 -76.95,-76.95c0,-42.42937 34.52062,-76.95 76.95,-76.95M85.5,4.275c-44.85757,0 -81.225,36.36743 -81.225,81.225c0,44.85758 36.36743,81.225 81.225,81.225c44.85758,0 81.225,-36.36742 81.225,-81.225c0,-44.85757 -36.36743,-81.225 -81.225,-81.225z" fill="#592689" /><path d="M85.5,42.75c-4.72203,0 -8.55,3.82797 -8.55,8.55c0,4.72203 3.82797,8.55 8.55,8.55c4.72203,0 8.55,-3.82797 8.55,-8.55c0,-4.72203 -3.82797,-8.55 -8.55,-8.55z" fill="#ffffff" /><g fill="#ffffff"><path d="M94.05,119.7v-51.3h-21.375v4.275h4.275v47.025h-4.275v4.275h25.65v-4.275z" /></g></g></g></svg>                         
                                                        </Tooltip>
                                                    </Grid>
                                                </span>  
                                            </td>
                                        </tr>

                                        {(CO2)/ (2200/365)*100 > 200 ?
                                        <tr>
                                            <td>
                                                <span className='percentCO2Text'>{Math.round((CO2)/ (2200/12)*100)}% {t("monthly carbon credit")}</span>
                                            </td>
                                        </tr>
                                        : null
                                        }

                                    </tbody>
                                    </table>             
                                
                                </div>


                            </div>
            )
    }

