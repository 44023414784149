import { createSlice } from '@reduxjs/toolkit';

import { askTransitPath } from '../Util/askTransitPath';

export const PathBusSlice = createSlice({
  name: 'PathBus',
  initialState: {
    path: 
      {
        id: 'bus',
        routes: [],
        CO2displayed : 0
      },
      displayedBusPath: 0,
      busLoading : false,
  },
  reducers: {
    setPathBus: (state, action) => {
      state.path = action.payload;
    },
    setdisplayedBusPath: (state, action) => {
      state.displayedBusPath = action.payload;
      state.path = {
        id: 'bus',
        routes: state.path.routes,
        CO2displayed : state.path.routes[action.payload].totalCO2
      };
    },
    setBackBestBusRoute: (state) => {
      state.displayedBusPath = 0;
      state.path = {
        id: 'bus',
        routes: state.path.routes,
        CO2displayed : state.path.routes[0].totalCO2
      };
    },
    setBackInitialBusStates: (state) => {
      state.displayedBusPath = 0;
    }
  },
  extraReducers: {
    [askTransitPath.fulfilled]: (state, action) => {
      switch (action.type) {
        default:
          return {
            ...state,
            busLoading : false,
            path : action.payload[0]
          }
      }
    },
    [askTransitPath.rejected]: (state, action) => {
      switch (action.type) {
        default:
          return {
            ...state,
            busLoading : false,
          }
      }
    },
    [askTransitPath.pending]: (state) => {
      return {
        ...state,
        busLoading : true
      }      
    }
  }
});

export const { setBackInitialBusStates, setPathBus, setdisplayedBusPath, setBackBestBusRoute } = PathBusSlice.actions;


export const selectPathBus = state => state.PathBus.path;
export const selectDisplayedBusPath = state => state.PathBus.displayedBusPath;
export const selectBusLoading = state => state.PathBus.BusLoading;

export default PathBusSlice.reducer;
