import { createSlice } from '@reduxjs/toolkit';

import { askTransitPath } from '../Util/askTransitPath';
import { askCO2Train } from './Util/askCO2Train';

export const PathTrainSlice = createSlice({
  name: 'PathTrain',
  initialState: {
    path: 
      {
        id: 'train',
        routes: [],
        CO2displayed : 0
      },
      displayedTrainPath: 0,
      trainLoading : false,
      detailCO2 : []
  },
  reducers: {
    setPathTrain: (state, action) => {
      state.path = action.payload;
    },
    setdisplayedTrainPath: (state, action) => {
      state.displayedTrainPath = action.payload;
      state.path = {
        id: 'train',
        routes: state.path.routes,
        CO2displayed : state.path.routes[action.payload].totalCO2
      };
    },
    setBackBestTrainRoute: (state) => {
      state.displayedTrainPath = 0;
      state.path = {
        id: 'train',
        routes: state.path.routes,
        CO2displayed : state.path.routes[0].totalCO2
      };
    },
    setBackInitialTrainStates: (state) => {
      state.displayedTrainPath = 0;
    }
  },
  extraReducers: {
    [askTransitPath.fulfilled]: (state, action) => {
      switch (action.type) {
        default:
          return {
            ...state,
            trainLoading : false,
            path : action.payload[1]
          }
      }
    },
    [askTransitPath.pending]: (state) => {
      return {
        ...state,
        trainLoading : true
      }      
    },
    [askTransitPath.rejected]: (state) => {
      return {
        ...state,
        trainLoading : false
      }      
    },
    [askCO2Train.fulfilled]: (state, action) => {
      switch (action.type) {
        default:
          return {
            ...state,
            detailCO2 : action.payload
          }
      }
    },
  }
});

export const { setBackInitialTrainStates, setPathTrain,  setdisplayedTrainPath, setBackBestTrainRoute } = PathTrainSlice.actions;


export const selectPathTrain = state => state.PathTrain.path;
export const selectDisplayedTrainPath = state => state.PathTrain.displayedTrainPath;
export const selectTrainLoading = state => state.PathTrain.trainLoading;

export default PathTrainSlice.reducer;
