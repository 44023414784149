import React from 'react'

import trainIcon from '../../Util/vehicles_icons/train.png';
import subwayIcon from '../../Util/vehicles_icons/metro.png';
import busIcon from '../../Util/vehicles_icons/bus.png';
import citybusIcon from '../../Util/vehicles_icons/citybus.png';
import boatIcon from '../../Util/vehicles_icons/boat.png';
import inclinedIcon from '../../Util/vehicles_icons/aerial.png';
import tramIcon from  '../../Util/vehicles_icons/tram.png';
import taxiIcon from  '../../Util/vehicles_icons/taxi.png';
import CarIcon from '../../Util/vehicles_icons/car.png'

export const listCarIcons = ((stepIn, i) => {

        if(stepIn.travelTypeStep !== 'pedestrian') {
          
            if(stepIn.vehicleModeStep === 'highSpeedTrain' || stepIn.vehicleModeStep === 'carShuttleTrain' || stepIn.vehicleModeStep === 'intercityTrain' || stepIn.vehicleModeStep === 'interRegionalTrain' || stepIn.vehicleModeStep === 'regionalTrain' || stepIn.vehicleModeStep === 'cityTrain' ) {
                return <img className='TrainIcon' src={trainIcon} alt="train icon" key={`allIconsTrain`+ i} />
            } else if (stepIn.vehicleModeStep === 'subway') {
               return <img className='SubwayIcon' src={subwayIcon} alt="subway icon"  key={`allIconsTrain` + i} />
            } else if (stepIn.vehicleModeStep === 'lightRail' || stepIn.vehicleModeStep === 'monorail'  ) {
                return <img className='TramIcon' src={tramIcon} alt="tram icon" key={`allIconsTrain` + i}  />
            } else if (stepIn.vehicleModeStep === 'bus') {
                return <img className='CitybusIcon' src={citybusIcon} alt="city bus icon" key={`allIconsTrain` + i}  />
            } else if (stepIn.vehicleModeStep === 'privateBus') {
                return <img className='TaxiIcon' src={taxiIcon} alt="taxi icon" key={`allIconsTrain` + i}  />
            } else if (stepIn.vehicleModeStep === 'busRapid') {
                return <img className='BusIcon' src={busIcon} alt="bus icon" key={`allIconsTrain` + i}  />
            } else if (stepIn.vehicleModeStep === 'ferry') {
                return <img className='FerryIcon' src={boatIcon} alt="ferry icon" key={`allIconsTrain` + i}  />
            } else if (stepIn.vehicleModeStep === 'inclined' || stepIn.vehicleModeStep === 'aerial') {
                return <img className='InclinedIcon' src={inclinedIcon} alt="funicular icon" key={`allIconsTrain` + i}  />
            } else if (stepIn.vehicleModeStep === 'car') {
                return <img className='CarIcon' src={CarIcon} alt='Car icon' key={`allIconsTrain` + i} />
            } else {
                return '?'
            }    
        }
})