import React from "react";

export default function Compensation() {
  return (
    <div className="Compensate">
      <p>How to compensate your emissions?</p>
      <p>Is it really a good solution?</p>
    </div>
  );
}
