import axiosService from "axios";
import { setAlert } from "../app/AppSlice";
import store from "../app/store";
import { logout } from "../containers/Auth/AuthSlice";
import i18n from '../i18n';

const axios = axiosService.create({
  baseURL: process.env.REACT_APP_API,
  responseType: "json",
  withCredentials: true
});


axios.interceptors.response.use(
  (response) => {
    if(response.headers?.authorization && (axios.defaults.headers?.Authorization !== response.headers?.authorization)){
      axios.defaults.headers = {
        Authorization: response.headers.authorization
      }
    }
    if(response.data.message === "logged out"){
      store.dispatch(logout());
      axios.defaults.headers = {
        Authorization: ''
      }
    }
    process.env.NODE_ENV === "development" && console.log(response);
    return response;
  },
  async (error) => {
    process.env.NODE_ENV === "development" && console.log({error});
    i18n.on("initialized", () => {
      if(!error.response) store.dispatch(setAlert({message: i18n.t('Network error'),showAlert: true, duration: 6000, severity:'error'}))
    })
    if(!error.response) store.dispatch(setAlert({message: i18n.t('Network error'),showAlert: true, duration: 6000, severity:'error'}))

    // Logout user if token didn't work or user is disabled
    if (
      error.response?.status === 401 &&
      ((error.response.data.message === "you must login first") ||
        (error.response.data.message === "Bad request reported!") ||
        (error.response.data.message === "Your session is expired! please login again") )
    ) {
      error.response.data.message = i18n.t(`${error.response.data.message}`)
      axios.defaults.headers = {}
      store.dispatch(logout())
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
);
export default axios;
