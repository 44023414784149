import React from "react";
import PropTypes from "prop-types";
import styles from "./OTPInput.module.css";

const OTPInput = React.forwardRef(({value, name, onChange, onKeyDown, onKeyUp, onKeyPress}, ref) => {
  return (
    <input
      type="number"
      onKeyUp={onKeyUp}
      ref={ref}
      maxLength={1}
      min={0}
      max={1}
      onKeyPress={onKeyPress}
      value={value}
      name={name}
      className={styles.OTPInput}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
})

OTPInput.defaultProps = {
  onChange: () => {}
}

OTPInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyPress: PropTypes.func
};

export default OTPInput;
