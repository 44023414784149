import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// IMPORT TRAIN SLICE
import { selectDisplayedTrainPath, selectPathTrain } from './PathTrainSlice';

// IMPORT UTILS
import { listIcons } from '../Util/listIcons';
import { calculateDuration } from '../Util/calculateDuration';


// IMPORT COMPONENTS
import {CO2andpercentCO2} from '../../Util/CO2andpercentCO2'

import { useTranslation } from 'react-i18next';

// IMPORT SELECTED PATH
import { selectDisplayedPath, setDisplayedPath, selectActivePath, setActivePath, setHoveredPath } from '../../PathListSlice';

export const PathTrainContainer = React.forwardRef(({onClick}, ref) => {

    function listAllIcons(routes) {

        let AllIcons = [];

        for(let i=0; i < routes.steps.length; i++) {
            AllIcons.push(listIcons(routes.steps[i], i));
        }

        return AllIcons
    }

    const dispatch = useDispatch();

    // IMPORT THE STATES
    const SelectedPath = useSelector(selectDisplayedPath);
    const displayedTrainPath = useSelector(selectDisplayedTrainPath);
    const PathTrain = useSelector(selectPathTrain);
    const activePath = useSelector(selectActivePath);
    const { t } = useTranslation();
    return (
        <div className="PathAndAlternatives" ref={ref} >
            {PathTrain.routes[displayedTrainPath] &&      
            <div className={SelectedPath === 'train' ? 'ActivePath' : 'Path'} >

            <div className="PathMain" 
                onMouseEnter={() => { if(SelectedPath !== 'train') {dispatch(setHoveredPath(PathTrain.routes[displayedTrainPath]))} }}
                onMouseLeave={() => { dispatch(setHoveredPath('')) }}
                    onClick={() => { 
                        dispatch(setDisplayedPath('train')); 
                        if(activePath){
                            if(activePath.id !== PathTrain.routes[displayedTrainPath].id){
                                dispatch(setActivePath(PathTrain.routes[displayedTrainPath])) 
                            }
                        } else {
                            dispatch(setActivePath(PathTrain.routes[displayedTrainPath]))
                        }
                    }} >

                <div className="TransportType">
                    <h2>{t('train').charAt(0).toUpperCase() + t('train').slice(1)}</h2>
                </div>

                <div className='PathInformation' >                      

                    <div className="IconAndTypeAndCO2">

                        <div className="IconAndType" >

                        <div className="RouteType" >{displayedTrainPath === 0 ? t('Best route') : `${t('Alternative')} ${displayedTrainPath}`}</div>

                            <div className="Icon" >
                                {listAllIcons(PathTrain.routes[displayedTrainPath])}
                            </div>

                        </div>

                        <CO2andpercentCO2 CO2={PathTrain.routes[displayedTrainPath].totalCO2} />
                        
                    </div>

                    <div className="DurationAndStartAt">

                        <p className="Duration">{calculateDuration(PathTrain.routes[displayedTrainPath], {hour: t('hours'), hours: t('hours'), minute: t('minute'), minutes: t("minutes")})}</p>

                    </div>

                </div>

                {/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ?
                    <div className='SeeOptionsHandlerMobile' ref={ref} onClick={onClick}>
                        <h4>{t('See options')}</h4>
                    </div>
                : null}

            </div>

            <div className="PathSecond"> 

                <div className='SeeOptionsHandler' ref={ref} onClick={onClick}>
                    {t('See options')}
                </div>


            </div>

            </div> }

    </div>


    )
});