import { createSlice } from "@reduxjs/toolkit";



export const Alert = createSlice({
  name: "app",
  initialState: {
    alert: {
      showAlert: false,
      message: "",
      severity: "success", // 'error','info','success','warning'
      duration: 60000,
    },
    openGoogleLoginModal: false,
  },
  reducers: {
    setAlert: (state, action) => ({
      ...state,
      alert: {...state.alert,...action.payload},
    }),
    setOpenGoogleLoginModal: (state, action) => ({
      ...state,
      openGoogleLoginModal: action.payload,
    }),

  },
});

export const { setAlert, setOpenGoogleLoginModal } = Alert.actions;
export const selectAlert = state => state.app.alert;
export const selectOpenGoogleLoginModal = state => state.app.openGoogleLoginModal;
export default Alert.reducer;