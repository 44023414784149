import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';
import { getCountryName } from '../../../utils/countries';

export const fetchToAutosuggestions = createAsyncThunk("autosuggestions/getto", async (valueIn, { rejectWithValue }) => {
  let lang = '';
  if (valueIn[1] === 'fr') {
    lang = "fr-FR";
  } else {
    lang = 'en-UK';
  }
  try {
    if (valueIn[0].length > 2) {
      const response = await Axios(`https://places.ls.hereapi.com/places/v1/autosuggest?result_types=address,place,category,chain&apiKey=j2YcVdp86ieJODQGLUDX2f6APJhkmjd9aG7RBZahvUQ&at=33.738045,73.084488&size=10&types=locality&lang=${lang}&q=${valueIn[0].trim()}`,{
        headers: {
          'Accept-Language': lang
        }
      });
      const json = response.data;
      return json;
    }
    return {results: []}
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});


export const toAutosuggestionsSlice = createSlice({
  name: 'toAutosuggestions',
  initialState: {
    value: [],
    isLoading: false,
    selectedToLocation: undefined
  },
  reducers: {
    setToAutosuggestions: (state, action) => {
      return {
        ...state,
        value: action.payload
      } || state;
    },
    setSelectedToLocation: (state, action) => ({
      ...state,
      selectedToLocation: action.payload
    })
  },
  extraReducers: {
    [fetchToAutosuggestions.fulfilled]: (state, action) => {
      if (action.payload) {
        const result = action.payload.results.filter(s => s.position).map((s) => {
          let label = "";
          let country = "";
          if (s.title === s.vicinity) {
            label = s.title.trim();
            // if country name was searched in english while language was set to fr
            let temp = s.title;
            if(s.title.includes('(')) {
              temp = temp.replace(' (', ', ');
              temp = temp.replace(')', '');
            }
            country = getCountryName(temp.split(', ').pop());
          } else {
            label = `${s.title}, ${s.vicinity ? s.vicinity.replace(/<br\/>/g, ", ") : ""
              }`
              country = getCountryName(label.split(', ').pop());
          }
          return {
            ...s,
            country,
            label,
            value: label.replace(/,/g, ""),
            position: { lat: s.position[0], lng: s.position[1] }
          };
        });
        state.value = result;
        state.isLoading = false;
        return state
      }
    },
    [fetchToAutosuggestions.pending]: (state, action) => {
      return {
        ...state,
        isLoading: true
      }
    },
    [fetchToAutosuggestions.rejected]: (state, action) => {
      return {
        ...state,
        isLoading: false
      }
    }
  }
});

export const { setToAutosuggestions , setSelectedToLocation } = toAutosuggestionsSlice.actions;
export const selectToAutosuggestions = state => state.toAutosuggestions.value;
export const selectSelectedToLocation = state => state.toAutosuggestions.selectedToLocation;
export const selectIsToAutosuggestionsLoading = state => state.toAutosuggestions.isLoading;
export default toAutosuggestionsSlice.reducer;