import { createSlice } from "@reduxjs/toolkit";
import { saveUserPath } from "./Util/PostPath";

export const AddPathToBallanceSlice = createSlice({
  name: "AddPathToBallance",
  initialState: {
    loading: false,
    pathAdded: false,
  },
  reducers: {
    setAddPathToBallance: (state, action) => {
      return {
        ...state,
        loading: action.payload,
        pathAdded: action.payload,
      };
    },
  },
  extraReducers: {
    [saveUserPath.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
        pathAdded : true
        }
    },
    [saveUserPath.rejected]: (state) => {
      return {
        ...state,
        loading: false,
      }
    },
    [saveUserPath.pending]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
  },
});


export const { setAddPathToBallance } = AddPathToBallanceSlice.actions;


export const selectAddPathToBallanceLoading = state => state.AddPathToBallance.loading;
export const selectAddPathToBallanceSelected = state => state.AddPathToBallance.pathAdded;

export default AddPathToBallanceSlice.reducer;
