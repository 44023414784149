import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { useSelector, useDispatch } from "react-redux";
import { deleteUserPath } from "../../Util/deleteUserPath";
import { selectUserPath, setPickedPath, selectPickedPath,  } from "../../UserPathSlice";
import { useTranslation } from 'react-i18next';
import moment from "moment";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function BasicTable(props) {

  const dispatch = useDispatch();
  const userPath = useSelector(selectUserPath);
  const pickedPath = useSelector(selectPickedPath);

  const userPathYearFiltered = userPath.filter(path => (new Date(path.date)).getFullYear() === props.year )

  const classes = useStyles();

  const { t, i18n } = useTranslation();

  return (

    <TableContainer component={Paper}>

      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t('Journey')}</TableCell>
            <TableCell align="right">{t('Date')}</TableCell>
            <TableCell align="right">{t("Transportation")}</TableCell>
            <TableCell align="right">kgCO<sub>2eq</sub></TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {userPathYearFiltered.map((path) => {
            const journeyName = path.journeyName ? path.journeyName : `${path.departure} - ${path.arrival}`;
            const journeyType = path.journeyType === 'REGULAR_JOURNEY';
            const date = !journeyType ? path.date.split(' ').slice(0, 3).join(' ') : `${path.dateSpan.startDate.split(' ').slice(0, 3).join(' ')} - ${path.dateSpan.endDate.split(' ').slice(0, 3).join(' ')}`;
            const tempCO2 = path.totalCO2 > 10 ? Math.round(path.totalCO2) : Math.round(path.totalCO2 * 10) / 10;
            let totalCO2 = '';
            let startDate = '';
            let endDate = '';
            if(journeyType) {
              startDate = moment(path.dateSpan.startDate);
              endDate = moment(path.dateSpan.endDate);
              const totalDays = endDate.diff(startDate, 'days');
              const numberOfWeeks = Math.round(totalDays / 7);
              const timesPerWeek = path.timesPerWeek
              const remainingDays = Math.round(totalDays % 7);
              const days = (numberOfWeeks * timesPerWeek) + (remainingDays > 0 && timesPerWeek <= remainingDays ? timesPerWeek : 0 );
              totalCO2 = tempCO2 * days;
            } else {
              totalCO2 = tempCO2;
            }
            return (
              <TableRow key={path._id}>
                <TableCell component="th" scope="row">{journeyName}</TableCell>
                <TableCell align="right">{journeyType === true ? `${(new Date(startDate)).toLocaleDateString(i18n.language === 'fr' ? 'fr-FR' : undefined, { weekday: 'long', month: 'long', day: 'numeric' })} ${t('to')} ${(new Date(endDate)).toLocaleDateString(i18n.language === 'fr' ? 'fr-FR' : undefined, { weekday: 'long', month: 'long', day: 'numeric' })}` : (new Date(date)).toLocaleDateString(i18n.language === 'fr' ? 'fr-FR' : undefined, { weekday: 'long', month: 'long', day: 'numeric' })}</TableCell>
                <TableCell align="right">{path.type}
                </TableCell>
                <TableCell align="right">{totalCO2}</TableCell>
                <TableCell align="right">
                  <IconButton aria-label="delete" disabled={path._id === pickedPath}
                    onClick={() => {
                      if (!pickedPath) {
                        dispatch(setPickedPath(path._id))
                        dispatch(deleteUserPath(path._id))
                      }
                    }} >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
          
        </TableBody>
      </Table>
    </TableContainer>
  );
}