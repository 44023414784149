import React, { useMemo } from "react";

const VehicalImage = React.memo(({ type, width, ...rest }) => {
    const icon = useMemo(() => {
        if (type === 'airport') {
            return require('../containers/MapApp/Results/PathList/Util/vehicles_icons/plane.png').default
        }
        if (type === 'public-transport') {
            return require('../containers/MapApp/Results/PathList/Util/vehicles_icons/bus.png').default
        }
        if (type === 'railway-station') {
            return require('../containers/MapApp/Results/PathList/Util/vehicles_icons/train.png').default
        }
        return require('../images/location2.png').default
    }, [type])
    return (
        <img width={width || 25} src={icon} alt="icon" {...rest} />
    );
});

export default VehicalImage