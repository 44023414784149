import React from 'react';
import '../../../style/ResultsContainer.css';
import { PathList } from './PathList/PathList';
import useQuery from '../../../hooks/useQuery';
import DetailedSelectedPath from './PathList/DetailedSelectedPath/DetailedSelectedPath';
import CalculationDetails from './PathList/CalculationDetails/CalculationDetails';

const paths = ["bus", "train", 'car', 'plane'];

export function ResultsContainer({ journeyType, hasValue }) {
  const {car, bus, train, plane} = hasValue;
  const query = useQuery();
  const selectedPath = React.useMemo(() => {
    return query.get("path")
      ? paths.some((p) => p === query.get("path"))
        ? query.get("path")
        : null
      : null;
  }, [query]);
  
  const showDetailedPath = React.useMemo(() => {
    if(selectedPath === 'car' && car) {
      return true;
    } else if(selectedPath === 'bus' && bus){
      return true;
    } else if(selectedPath === 'train' && train){
      return true;
    } else if(selectedPath === 'plane' && plane){
      return true;
    }
    return false;
  }, [car, bus, plane, train, selectedPath])

  return (
    <>
    <div className="ResultsContainer" id='Result'>
      {showDetailedPath ? <DetailedSelectedPath journeyType={journeyType} title={selectedPath} /> : <PathList journeyType={journeyType} />}
    </div>
    <CalculationDetails />
    </>
  );
}