import React from 'react'
import PropTypes from 'prop-types'
import Styles from "./PrimaryButton.module.css"
import ThreeDotLoader from '../Loaders/ThreeDotLoader'

function PrimaryButton({children, onClick, disabled, isLoading, style, className}) {
    return (
    <button style={style} disabled={disabled} type="button" className={`${Styles.btn} ${className}`} onClick={()=>{!isLoading && onClick()}}>
        {isLoading && <div className={Styles.loader} style={{visibility: isLoading ? "visible" : "hidden"}}><ThreeDotLoader /></div>}
        <span style={{visibility: isLoading ? "hidden" : "visible"}}>{children}</span>
     </button>
    )
}

PrimaryButton.defaultProps = {
    style: {},
    className: "",
    isLoading: false,
    onClick: ()=>{}
}

PrimaryButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    style: PropTypes.object,
    className: PropTypes.string
}

export default PrimaryButton

