import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/CloseRounded";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Select, { components } from "react-select";
import { useTranslation } from 'react-i18next';
import VehicalImage from '../../../VehicalImage'
import styles from "./MobileViewInput.module.css";

const { Option } = components;

const MobileViewInput = ({
  isLoading = false,
  data = [],
  onInputChange = () => { },
  title = "No title provided",
  onSelect = () => { },
  onClose = () => { },
  placeholder = '',
  inputId = null
}) => {
  const mSelectRef = useRef(null);
  const mHeadingRef = useRef(null);
  const selectRef = useRef(null)
  const [inputHeight, _inputHeight] = useState(0);
  const [headerHeight, _headerHeight] = useState(0);
  const { i18n } = useTranslation();
  const language = i18n.language;
  const formatedData = useMemo(() => {
    if (data.some((e) => e.resultType === "address")) {
      const addressMaxCount = 3;
      const address = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].resultType === "address") {
          if (address.length <= addressMaxCount - 1) {
            address.push(data[i]);
          } else {
            break;
          }
        }
      }
      const removeAddress = data.filter(e => e.resultType !== 'address');
      const newArray = [
        {
          label: 'Addresses',
          options: address,
        },
        {
          label: 'Places',
          options: removeAddress,
        },
      ]
      return newArray
    } else {
      return data
    }
  }, [data]);
  useEffect(() => {
    if (mHeadingRef.current) {
      _headerHeight(mHeadingRef.current.offsetHeight);
    }
    if (mSelectRef.current) {
      _inputHeight(mSelectRef.current.offsetHeight);
    }
    if(selectRef.current){
      setTimeout(()=>{
        selectRef.current.select.focus()
      },100)
    }
  }, [mSelectRef, mHeadingRef]);
  
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add(`${styles['msearchView-open']}`);
    return () => {body.classList.remove(`${styles['msearchView-open']}`)};
  },[])

  const close = useCallback(() => {
    onClose();
  }, [onClose])

  return (
    <div className={styles.msearchView}>
      <div className={styles["m-heading"]} ref={mHeadingRef}>
        <div className={styles["m-heading__title"]}>
          <span>{title} </span>
        </div>
        <IconButton
          aria-label="close"
          onClick={close}
          style={{ backgroundColor: "rgb(238 238 238 / 59%)" }}
        >
          <DeleteIcon />
        </IconButton>
      </div>
      <div className={styles["m-select"]} ref={mSelectRef}>
        <Select
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "#592689",
              primary50: "rgb(128 128 128 / 50%)",
              primary25: "rgb(128 128 128 / 25%)",
            },
          })}
          autoFocus={true}
          ref={selectRef}
          inputId={inputId}
          cacheOptions
          styles={{
            option: (base) => ({
              ...base,
              padding: "16px 12px 16px 6px",
              display: "flex",
              alignItems: "center",
              'span': {
                textAlign: 'left'
              }
            }),
            menuList: (base) => ({
              ...base,
              maxHeight: `calc(100vh - ${headerHeight + inputHeight + 20}px)`,
            }),
            menu: (base) => ({
              ...base,
              backgroundColor: "unset",
              boxShadow: "unset",
            }),
            input:(base) => ({
              ...base,
              input: {
                boxShadow: "unset !important",
              },
            }),
            valueContainer: (base) => ({ ...base, padding: "16px 12px" }),
          }}
          options={formatedData}
          menuIsOpen
          defaultMenuIsOpen
          onInputChange={(e) => {
            if (e && e.length > 2) {
              onInputChange(e, language);
            }
          }}
          onChange={(e) => {
            onSelect(e);
            onClose();
          }}
          isSearchable
          placeholder={placeholder}
          isLoading={isLoading}
          loadingMessage={() => "loading..."}
          isOptionSelected
          components={{
            Option: (props) => (
              <Option {...props}>
                <div
                  style={{
                    objectFit: "contain",
                    marginRight: 20,
                  }}
                >
                  <VehicalImage
                    type={props.data.category}
                    style={{ margin: 5 }}
                  />
                </div>
                <span>{props.label}</span>
              </Option>
            ),
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            NoOptionsMessage: () => null
          }}
        />
      </div>
    </div>
  );
}

export default React.memo(MobileViewInput);