import React, { useEffect  } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setLanguage, selectLanguage } from './LanguageSlice';

export function SelectLanguageComponent() {
    const { i18n } = useTranslation();

    const dispatch = useDispatch();
    const language = useSelector(selectLanguage)

    useEffect(() => {
        let languageStoredInLocalStorage = i18n.language
 
        if (languageStoredInLocalStorage) {
            dispatch(setLanguage(languageStoredInLocalStorage === 'fr' ? 'French' : 'English'));
            document.getElementsByTagName('html')[0].setAttribute('lang', languageStoredInLocalStorage === 'fr' ? 'fr' : 'en');
            document.getElementsByTagName('html')[0].setAttribute('translate', languageStoredInLocalStorage === 'fr' ? 'no' : 'yes');
        } else {
            let Navigatorlang = navigator.language || navigator.userLanguage
            if( Navigatorlang === "fr" || Navigatorlang === "fr-BE" || Navigatorlang === "fr-CA" || Navigatorlang === "fr-CH" || "fr-LU") {
                Navigatorlang = 'French'
            } else {
                Navigatorlang = 'English'
            }
            localStorage.setItem('language', navigator === 'French' ? 'fr' : 'en');
            dispatch(setLanguage(Navigatorlang === 'fr' ? 'French' : 'English'));
            document.getElementsByTagName('html')[0].setAttribute('lang', Navigatorlang === 'French' ? 'fr' : 'en');
            document.getElementsByTagName('html')[0].setAttribute('translate', Navigatorlang === 'French' ? 'no' : 'yes');
        }
      }, [dispatch]);

  return (
    <div className="Select-Language-Container" >
        <select
            className="custom-select"
            value={language}
            onChange={(e) => {
                if(e.target.value === 'French'){
                    i18n.changeLanguage('fr');
                } else {
                    i18n.changeLanguage('en');
                }
                dispatch(setLanguage(e.target.value));
                localStorage.setItem("language", e.target.value === 'French' ? 'fr' : 'en');
                document.getElementsByTagName('html')[0].setAttribute('lang', e.target.value === 'French' ? 'fr' : 'en');
                document.getElementsByTagName('html')[0].setAttribute('translate', e.target.value === 'French' ? 'no' : 'yes');
            }
            }
        >
            <option value="English">EN</option>
            <option value="French">FR</option>
        </select>
    

</div>
  );
}

