import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from 'lodash'
import "../../style/MapApp.css";

import { SearchbarLittle } from "./SearchbarLittle/SearchbarLittle";
import { ResultsContainer } from "./Results/ResultsContainer";
import HereMap from "./HereMap/HereMap";
import { selectDisplayedPath } from "./Results/PathList/PathListSlice";

import { selectPathBus} from './Results/PathList/Transit/Bus/PathBusSlice';
import { selectPathTrain } from './Results/PathList/Transit/Train/PathTrainSlice';
import { selectPathBicycle } from "./Results/PathList/Bicycle/PathBicycleSlice";
import { selectPathCar } from "./Results/PathList/Car/PathCarSlice";
import { selectPathPlane } from "./Results/PathList/Plane/PathPlaneSlice";

import { AddPathToBallance } from "./AddPathToBallance/AddPathToBallance";

import { selectSelectedToLocation } from '../SearchBar/To/ToAutosuggestionsSlice';
import { selectSelectedFromLocation } from '../SearchBar/From/FromAutosuggestionsSlice';

import { useHistory } from "react-router";

// const viewportContext = React.createContext({});
// const breakpoint = 10;
/*
const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  function fullwidthResults(inValue) {
    if (inValue < breakpoint) {
      document.getElementById("Result").style.width = "100%";
    }
    if (inValue > breakpoint) {
      document.getElementById("Result").style.width = "40%";
    }
  }

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    fullwidthResults(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  });

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};
*/

/*
const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};
*/

/*
const MyMapComponent = () => {
  const { width } = useViewport();

  return width < breakpoint ? null : <HereMap />;
};
*/

export function MapApp() {
  const history = useHistory();
  
  const tempNavState = React.useMemo(
    () => (history.location.state ? history.location.state : null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const navigationState = {
    journeyType: tempNavState
      ? tempNavState.journeyType
        ? tempNavState.journeyType
        : null
      : null,
      journeyName: tempNavState
      ? tempNavState.journeyName
        ? tempNavState.journeyName
        : null
      : null
  };

  const displayedPath = useSelector(selectDisplayedPath);
  const fromValue = useSelector(selectSelectedFromLocation);
  const toValue = useSelector(selectSelectedToLocation);
  const [{from, to}, _coords] = useState({from: fromValue ? {...fromValue.position, label: fromValue.label} : '', to: toValue ? {...toValue.position,label: toValue.label } : ''});
  const pathBus = useSelector(selectPathBus);
  const pathTrain = useSelector(selectPathTrain);
  const pathCar = useSelector(selectPathCar);
  const pathBicycle = useSelector(selectPathBicycle);
  const pathPlane = useSelector(selectPathPlane);
  /**
   * this side-effect is only effected when path results are empty
   * -it is expected to happen on '/map' page refresh/reload or when
   * let's go button is clicked.
   */
  useEffect(() => {
    if (
      !(
        pathBus.routes.length ||
        pathTrain.routes.length ||
        pathCar.routes.length ||
        pathBicycle.routes.length ||
        pathPlane.routes.length
      ) 
    ) {
      if (fromValue && fromValue.position && !_.isEqual(fromValue.position, {lat: from.lat, lng: from.lng})) {
        _coords({to, from: {...fromValue.position, label: fromValue.label}});
      }
      if (toValue && toValue.position && !_.isEqual(toValue.position, {lat: to.lat, lng: to.lng})) {
        _coords({from, to: {...toValue.position, label: toValue.label}})
      }
    }
  }, [pathBus, pathTrain, pathCar, pathBicycle, pathPlane, fromValue, toValue, from, to]);
  
  useEffect(() => {
    history.replace('/map', null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if(fromValue && toValue) {
      document.getElementById('map').scrollIntoView();
      var scrolledY = window.scrollY;
      if(scrolledY){
      window.scroll(0, scrolledY - 47);
      }
    }
      document.getElementsByClassName("osano-cm-window__widget osano-cm-widget osano-cm-widget--position_right")[0].style.bottom = "25px";
    return () => {
      document.getElementsByClassName("osano-cm-window__widget osano-cm-widget osano-cm-widget--position_right")[0].style.bottom = "12px";
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="MapApp">
      <div className="MapAppSearchLittle">
        <SearchbarLittle journeyType={navigationState.journeyType} />
      </div>

      <div className="MapAndResults">
        <ResultsContainer
          {...navigationState}
          hasValue={{
            car: pathCar.routes.length ? true : false,
            bus: pathBus.routes.length ? true : false,
            train: pathTrain.routes.length ? true : false,
            plane: pathPlane.routes.length ? true : false,
          }}
        />
        <HereMap from={from} to={to} />
      </div>

      {displayedPath ? (
        <AddPathToBallance
          {...navigationState}
          from={from.label}
          to={to.label}
        />
      ) : null}
    </div>
  );
}
