import React from "react";
import styles from "./DetailedSelectedPath.module.css";
import ArrowRight from "../../../../../images/ArrowToRight.svg";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import { PathDetailsContainer } from "../../PathList/Transit/pathDetailsContainer";
import { listIcons } from "../../../Results/PathList/Transit/Util/listIcons";
import { listCarIcons } from '../Car/Util/listIcons';
import PlaneIcon from "../../PathList/Util/vehicles_icons/plane.png";

import useQuery from "../../../../../hooks/useQuery";
import { useTranslation } from "react-i18next";

import ClickableLink from '../Util/ClickableLink.png'


const Card = ({
  showDetails,
  clickOnShowDetails,
  selected,
  onClick,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  smallTitle,
  co2Text,
  route,
  onClickCo2,
  departureTime = "N/A",
  arrivalTime = "N/A",
  journeyType = ''
}) => {
  const query = useQuery();
  const path = query.get("path");
  const { t } = useTranslation();

  const getIcon = () => {
    if (path === "plane") {
      return (
        <div className="Icon">
          <img className="CarIcon" src={PlaneIcon} alt="Car icon" />
        </div>
      );
    } else if (path === "car") {
      return <>{route && route.steps.map((o, i) => listCarIcons(o, i))}</>;
    } else if (path === "bus" || path === "train") {
      return <>{route && route.steps.map((o, i) => listIcons(o, i))}</>;
    }
    return "";
  };
  return (
    <div className={`${styles["path-detailed-container"]}`}>
      <div
        className={`${styles["path-card"]} ${
          selected ? styles["path-card--active"] : ""
        }`}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={path==="plane"? {paddingBottom:"10px"} : null }
      >
        <div className={styles["path-card__heading-container"]}>
          <div className={styles["path-card__route-textAndIcon"]}>
            <span className={styles["path-card__small-text"]}>
              {smallTitle}
            </span>
            <div className={styles["path-card__transport-icon"]}>
              {getIcon()}
            </div>
          </div>
          <div className={styles["path-card__co2Container"]}>
            <span
              className={styles["path-card__co2-text"]}
              onClick={onClickCo2}
            >
              {co2Text > 10
                ? Math.round(co2Text)
                : Math.round(co2Text * 10) / 10}{" "}
              kgCO<sub>2</sub>
            </span>
            {/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ?
              <span className="ClickableLink"><img className='ClickableLinkImage' src={ClickableLink} alt='Clickable link' /></span>
            : null}
          </div>
        </div>
        <div
          className={styles["path-card__row"]}
          style={{ borderBottom: showDetails ? "1.7px dashed grey" : "", display: path==='plane' ? "block" : "flex" }}
        >
        {path !== "plane" ?
          <div className={styles["path-card__time-container"]}>
          {departureTime && <>
          <span>{departureTime}</span>
          <div className={styles["arrow"]}>
            <img src={ArrowRight} alt="icon" className={styles["icon"]} />
          </div>
          </>}  
          <span>{arrivalTime}</span>
        </div>
        : 
        <div 
          className={styles["path-card__time-container"]}
          style={{justifyContent: path==='plane' ? "space-between" : "", alignItems: path==='plane' ? "flex-start" : "center"}}
        >
          <div>
          {t('from').charAt(0).toUpperCase() + t('from').slice(1)} {route.Departure} {t('to à')} {route.Arrival}<br/>
          {t('with').charAt(0).toUpperCase() + t('with').slice(1)} {route.Carrier}<br />
          </div>
        <div style={{fontWeight:"600"}}>
          {route.Price}€
          </div>
        </div>
        }

          {route && route.type !== 'car' && path !== 'plane' ? 
            <div className={styles["path-card__DropDownBtn"]}>
              <IconButton onClick={clickOnShowDetails}>
                <KeyboardArrowDownOutlinedIcon
                  className={
                    showDetails ? styles["rotate180"] : styles["rotateBack"]
                  }
                />
              </IconButton>
            </div>
          :null}
        </div>
        {showDetails && (
          <div className={styles["detailed_section"]}>
            <PathDetailsContainer
              route={route}
              isDetailsDisplayed={showDetails}
            />
          </div>
        )}
      </div>
      <div className={`${styles["cost"]}`}>

            {journeyType !== 'PAST_JOURNEY' && 
              <>
                {route && route.type !== 'car' ? 
                  <p onClick={() => {document.getElementById("container-booking").scrollIntoView();}} style={{marginRight: "10px", marginLeft: "10px"}}>
                    {t("Book your ticket")} ↓
                  </p> 
                :null}
              </>
            }
      </div>
    </div>
  );
};

export default React.memo(Card);
