import { createSlice } from '@reduxjs/toolkit';

export const CalculationDetailsSlice = createSlice({
  name: 'CalculationDetails',
  initialState: {
    open: false,
  },
  reducers: {
    setCalculationDetails(state, action) {
      if(state.open === true) {
        return {
          ...state,
          open : false    
        }
      } else {
        return {
          ...state,
          open : true    
        }
      }
    }
  }
});


export const { setCalculationDetails } = CalculationDetailsSlice.actions;


export const selectCalculationDetails = state => state.CalculationDetails.open;


export default CalculationDetailsSlice.reducer;
