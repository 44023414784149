import React, { useEffect } from "react";
import "../../style/Privacy.css";

export function PrivacyPolicy() {
  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);
  return (
    <div className="PrivacyPolicy">
      <p>(Last updated October 28, 2020)</p>

      <h3>1. Overview</h3>

      <p>Welcome to Moveco website.</p>
      <p>
        Moveco (“we”, “us”, or “Our”) respects the privacy rights of its users
        and understands the importance of protecting and handling information
        collected about you in accordance with both the law and best practice.
        This Privacy Notice has been composed for providing you with the
        information that you are entitled to (in case you are a private person,
        i.e. a data subject) according to Article 13 and Article 14 of EU
        General Data Protection Regulation, i.e., Regulation (EU) 2016/679
        (“GDPR”), the French Data Protection” Act.
      </p>
      <p>
        This Privacy Policy and statement is created to explain to you how we
        (www.Bigup.community, or one of our other website and platform) collect
        your data, how we process them, how we secure them, how we might share
        them as regards to providing our services and how we store them. This
        Privacy Statements also describe what right you have as regards to your
        personal data, how you give your consent and how you withdraw your
        consent. If you are user who resides in the European Union Region, we
        also explain what rights you have as a data subject.
      </p>

      <ol>
        <li>Overview</li>
        <li>Acceptance And Use </li>
        <li>Who We are?</li>
        <li>Summary of Personal Information and Use</li>
        <li>Purposes of Data Collection</li>
        <li>Data Protection Principles</li>
        <li>Types Of Data We Collect From You</li>
        <li>Non-Personal Information</li>
        <li>UK and EU Cookie Law</li>
        <li>Information Collected Through Technology</li>
        <li>Other Information You May Submit</li>
        <li>Information We Collect From You Automatically</li>
        <li>How We Use Your Data</li>
        <li>Remarketing On The Website And Services</li>
        <li>Google Adwords</li>
        <li>With Whom We Share Your Data</li>
        <li>Do Not Track</li>
        <li>Interest-Based Advertising Technologies</li>
        <li>Data of EEA and Non EEA Subjects</li>
        <li>Your Data Protection Rights</li>
        <li>Our Policy Towards Children</li>
        <li>Privacy Shield</li>
        <li>Legal Bases For Processing (For EEA Users)</li>
        <li>Third-Party Site And Links</li>
        <li>California / Delaware Do Not Track Disclosures</li>
        <li>Data Security</li>
        <li>GDPR Statement For (EEA Users)</li>
        <li>Terms and Conditions</li>
        <li>Jurisdiction</li>
        <li>Changes to our Privacy Policy</li>
        <li>Contact Us</li>
      </ol>

      <h3>2. Acceptance And Use</h3>

      <p>
        This privacy policy covers Moveco and its subsidiaries, including
        (collectively, "Moveco we, us, or our). By using our website, you
        signify acceptance to the terms of this Privacy Policy which is
        considered as your official consent to process your personal
        information, your consent to the collection, use, and disclosure of your
        personal information as described further below. We may request you to
        provide additional disclosures or information about the data processing
        practices of specific Services. These notices may supplement or clarify
        our privacy practices or may provide you with other choices about how we
        process your data.
      </p>
      <p>
        If you do not agree with or you are not comfortable with any aspect of
        this Privacy Policy, you should immediately discontinue access or use of
        the Moveco website and its related services.
      </p>

      <h3>3. Who We are?</h3>

      <p>
        The Sites are owned and operated by Moveco, we provide an online web app
        portal that allows users to enjoy the best traveling experience.
      </p>

      <h3>4. Summary of Personal Information and Use</h3>

      <p>
        We may collect or obtain Personal Data about you, and this information
        may be stored or processed within the France Region through the
        following methods:{" "}
      </p>

      <ul>
        <li>
          directly from you (e.g., where you contact us); in the course of our
          relationship with you (e.g., if you make a booking);
        </li>
        <li>
          when you visit our website; when you sign-in or sign up to use any
          part of the Services;{" "}
        </li>
      </ul>

      <p>
        We may also receive Personal Information about you from third parties.
      </p>

      <h3>5. Purposes of Data Collection</h3>

      <p>
        For data collection methods as described by Moveco in these privacy
        statement the following examples of uses of this Personal Information
        may be used for:
      </p>

      <ul>
        <li>Provision of Services to you</li>
        <li>Offering and Improving the website</li>
        <li>Surveys</li>
        <li>Communication</li>
        <li>Advertising</li>
        <li>User Engagement</li>
        <li>Marketing</li>
        <li>Lead Generation</li>
        <li>Legal Compliance</li>
      </ul>

      <h3>6. Data Protection Principles</h3>

      <p>
        We collect, process and use personal data (in accordance with the
        definitions of the European General Data Protection Regulation (GDPR),
        the French Data Protection” Act. in order to provide our services.
        Personal data means any information that enables us to identify you,
        directly or indirectly, such as name, e-mail, address, telephone number,
        any form of identification number, or one or more factors specific to
        you or your beneficiary's identity.
      </p>

      <p>
        We are committed to complying with applicable data protection laws and
        will ensure that personal data is:
      </p>

      <ul>
        <li>Used lawfully, fairly and in a transparent way;</li>
        <li>
          Collected only for valid purposes that we have clearly explained to
          you and not used in any way that is incompatible with those purposes;
        </li>
        <li>
          Relevant to the purposes we have told you about and limited only to
          those purposes;
        </li>
        <li>Accurate and kept up to date;</li>
        <li>
          Kept only as long as necessary for the purposes we have told you
          about;
        </li>
      </ul>

      <h3>7. Types Of Data We Collect From You</h3>

      <p>
        We receive personal information about you that you give to us, that we
        collect from your use of our Site and registration pages and that we
        obtain from other sources. We only collect personal information which we
        need and that is relevant for the purposes for which we intend to use
        it.
      </p>

      <ol>
        <li>
          Personal Identifiable Information (PII). We acquire PII that may
          include, in specific contexts, your Full name, e-mail and password.
        </li>
        <li>
          Financial Information: Bank account information, payment card primary
          account number (PAN), billing information.
        </li>
        <li>
          Sensitive PII: Information about the transactions you make on our
          Services, such as the names, mobile number, e-mail address, and other
          information about the directly connected accounts to your account,
          which are attracted to our service by you.
        </li>
        <li>
          Correspondence: Survey responses, the information provided to our
          support team or user research team.
        </li>
      </ol>

      <h3>8. Non-Personal Information</h3>

      <p>
        Non-personal information refers to information that, by itself, does not
        identify you as a specific individual (e.g., demographic information or
        web site visitations). Moveco may collect non-personal information
        through any of the methods discussed above, as well as automatically
        through the use of industry-standard technologies described further
        below.
      </p>

      <h3>9. UK and EU Cookie Law</h3>

      <p>
        The Privacy and Electronic Communications (EC Directive) Regulations
        2003 as amended by the Privacy and Electronic Communications (EC
        Directive) (Amendment) Regulations 2011; Cookies is a small text file
        placed on your computer by this Website and services when you visit
        certain parts of the Website and services and/or when you use certain
        features of the Website and services.
      </p>

      <h3>10. Information Collected Through Technology</h3>

      <p>
        While you visit the Moveco site and decide not to register an account on
        the website. Our website or its third-party service providers may still
        collect non-personal information about your use of the website (e.g.,
        Your internet browser, operating system, IP address, connection speed,
        and the domain name of your internet service provider). The following
        methods may gather such information:
      </p>

      <ul>
        <li>
          (a) Cookies. Cookies, including local shared objects, are small pieces
          of information that are stored by your browser on your device's hard
          drive, which works by assigning to your computer a unique number that
          has no meaning outside of the Moveco site. Cookies do not generally
          contain any personal information. Most web browsers automatically
          accept cookies, but you can usually configure your browser to prevent
          this. Not taking cookies may make certain features of the Moveco
          materials unavailable to you.
        </li>
        <li>
          (b) IP address. You may visit many areas of the Moveco site
          anonymously without the need to become a registered user. Even in such
          cases, Moveco may collect IP addresses automatically. An IP address is
          a number that is automatically assigned to your computer whenever you
          begin services with an internet services provider. Each time you
          access the Moveco site and each time you request one of Moveco' pages,
          the server logs your IP address.
        </li>
        <li>
          (c) Beacons. Beacons are small pieces of data embedded in web pages
          and e-mails. Moveco may use these technical methods in html e-mails
          that Moveco sends to users to determine whether they have opened those
          e-mails or clicked on links in those e-mails.
        </li>
      </ul>

      <h3>11. Other Information You May Submit</h3>

      <p>
        You may submit data to us for limited purposes such as requesting
        customer support, answering a questionnaire, participating in a study,
        entering contests or sweepstakes, or signing up to receive
        communications from another user or us.
      </p>

      <h3>12. Information We Collect From You Automatically</h3>

      <p>
        We receive and store certain types of information automatically, such as
        whenever you interact with or use the Moveco website. This information
        helps us address customer support issues, improve the performance of the
        Moveco website, providing you with a streamlined and personalized
        experience, and protect your account from fraud by detecting
        unauthorized access. Information collected automatically includes:
      </p>

      <ul>
        <li>
          Online Identifiers: Geo location/tracking details, browser
          fingerprint, operating system, browser name, and version, or personal
          IP addresses.
        </li>
        <li>
          Usage Data: Authentication data, security questions, click-stream
          data, public social networking posts, and other data collected via
          cookies and similar technologies.
        </li>
      </ul>

      <p>
        For example, we may automatically receive and record the following
        information on our server logs:
      </p>
      <ul>
        <li>How you came to and used the Moveco website;</li>
        <li>Device type and unique device identification numbers;</li>
        <li>
          Device event information (such as crashes, system activity, and
          hardware settings, browser type, browser language, the date and time
          of your request and referral URL);
        </li>
        <li>
          How your device interacts with Moveco website and Services, including
          pages accessed and links clicked;
        </li>
        <li>
          Broad geographic location (e.g., country or city-level area); and
        </li>
        <li>
          Other technical data collected through cookies, pixel tags, and other
          similar technologies that uniquely identify your browser.
        </li>
      </ul>

      <p>
        We may also use identifiers to recognize you when you access our website
        via an external link, such as a link appearing on a third party site.
      </p>

      <h3>13. How We Use Your Data</h3>

      <p>We may use your data for the following purposes:</p>

      <ul>
        <li>
          Identification and authentication: to register and set you up as a
          member on our Site;
        </li>
        <li>
          Communicating with you: we use your data when we communicate with you
          (e.g., when we respond to customer support or other inquiry).
        </li>
        <li>
          Improving our services: we use your data to understand how you use our
          services and how we can improve them. In general, we analyze
          aggregated data rather than specific user data. We may, however, need
          to analyze a particular case to address a problem (e.g., a bug that
          affects only a few accounts).
        </li>
        <li>
          Customizing your experience: we use your data to personalize the
          service to you. May include remembering your preferences for language
          or volume or displaying site that you might enjoy, based upon your
          viewing choices.
        </li>
        <li>
          To ensure network and data security: We process your personal
          information to enhance security, monitor and verify identity or
          service access, combat spam or other malware or security risks, and to
          comply with applicable security laws and regulations. The threat
          landscape on the internet is continuously evolving, which makes it
          more critical than ever that we have accurate and up-to-date
          information about your use of Moveco Services.
        </li>
        <li>
          Marketing and advertising: we use your data to display ads and send
          you offers. We may also use your data in delivering third-party
          advertisements to you. These may include "targeted ads" based upon
          your activities.
        </li>
        <li>
          Exercising our rights: Where reasonably necessary, we use your data to
          exercise our legal rights and prevent abuse of our service. For
          example, we may use your data to detect and prevent fraud, spam, or
          content that violates our terms of service.
        </li>
        <li>
          Legal compliance: we use your data where we are legally required to do
          so. For example, we may need to gather your data to respond to a
          subpoena or court order.
        </li>
        <li>
          Protecting your information: where appropriate, we may anonymize,
          backup, and delete specific data.
        </li>
        <li>
          For research and development: We process your personal information to
          understand better the way you use and interact with Moveco' Services.
          Also, we use such information to customize and improve Moveco features
          and the content and layout of our site and applications, and to
          develop new services.
        </li>
      </ul>

      <p>
        We may use algorithms and other automated means to implement any of the
        above.
      </p>

      <h3>14. Remarketing On The Website And Services</h3>

      <p>
        If you have provided your consent by accepting Targeting Cookies on the
        site, we may use Google AdWords (remarketing and Similar Audience
        features) and Facebook Ads. You can review the Moveco cookie policy,
        which includes details on how to customize your cookie settings.
      </p>

      <h3>15. Google Adwords</h3>

      <p>
        Google AdWords enables Google, through the use of cookies, to identify
        the fact that you have visited the website, to identify aspects of your
        usage of the site, and combine that with what it knows about your usage
        of another website in the Google ad network.
      </p>

      <p>
        We use these services to advertise to visitors of Google ad network site
        who have previously visited our website or who Google deems to have
        shared interests with visitors to our website. The Google privacy policy
        covers Google's collection and use of your personal data. You can set
        preferences for how Google advertises to you using the Google Ad
        Preferences page.
      </p>

      <h3>16. With Whom We Share Your Data</h3>

      <p>
        We share data with third parties as follows: With your consent, we may
        share your data with third parties where we have obtained your express
        permission to do so. You may revoke these consents.
      </p>

      <ul>
        <li>
          Authorized vendors: We may share your data with third-party vendors
          that help us operate our website, process payments, and deliver our
          services and also comply with your instructions and our contractual
          obligations. These include payment processors, content delivery
          networks (CDNs), cloud-based hosting services, monitoring services,
          e-mail service providers, quality assurance and testing vendors, fraud
          and abuse prevention vendors, customer relations management (CRM),
          vendors
        </li>
        <li>
          Advertising: We may share your data with advertising companies to
          display relevant ads to you. Unless you expressly agree, we will not
          share or sell your name, e-mail address, or physical address with such
          persons.
        </li>
        <li>
          Analytics: We may share your data with persons who provide analytics
          showing how customers are using our services.
        </li>
        <li>
          Certain legal obligations: we may share your data where we believe
          disclosure is necessary to comply with a legal obligation or in
          connection with a corporate transaction.
        </li>
      </ul>

      <h3>17. Do Not Track</h3>

      <p>
        Do Not Track ("DNT") is a privacy preference that users can set in
        specific web browsers and devices. DNT is a way for users to inform
        websites and services that they do not want certain information about
        their webpage visits collected over time and across websites or online
        services. Please note that we do not respond to or honor DNT signals or
        similar mechanisms transmitted by web browsers.
      </p>

      <h3>18. Interest-Based Advertising Technologies</h3>

      <p>
        As noted above, you may limit advertising tracking using advertising
        identifiers through your device's privacy settings.
      </p>

      <p>
        The online advertising industry also provides websites from which you
        may opt-out of receiving targeted ads from our data partners and our
        other advertising partners that participate in self-regulatory programs.
        You can access these, and also learn more about targeted advertising and
        consumer choice and privacy, at
        http://www.networkadvertising.org/managing/opt_out.asp, or and
        http://www.aboutads.info/choices/.
      </p>

      <h3>19. Data of EEA and Non EEA Subjects</h3>

      <p>Legal Bases for Processing your Information:</p>

      <p>
        For individuals who reside in the European Economic Area, data collected
        from you may be transferred or processed in the United State of America
        (Collectively "EEA Residents"). At the time their data is collected, we
        rely on legal bases for processing your information under Article 6 of
        the EU General Data Protection Regulation ("GDPR"). We generally only
        process your data where we are required to, where the processing is
        necessary to perform any contracts we entered with you (or to take steps
        at your request before entering into a contract with you). For our
        legitimate interests to operate our business or to protect the Moveco
        site or your property, rights, or safety, or where we have obtained your
        consent to do so.{" "}
      </p>

      <p>Shine the Light (For California Citizens) CCPA</p>

      <p>
        California’s “Shine the Light” law permits customers in California to
        request certain details about how certain types of their information are
        shared with third parties and, in some cases, affiliates, for those
        third parties’ and affiliates’ own direct marketing purposes. Under the
        law, a business should either provide California customers certain
        information upon request or permit California customers to opt out of
        this type of sharing.
      </p>

      <p>
        To exercise a Shine the Light request, please contact us. You must put
        the statement “Your California Privacy Rights” in the body of your
        request, as well as your name, street address, city, state, and zip
        code. In the body of your request, please provide enough information for
        us to determine if this applies to you. Please note that we will not
        accept inquiries via the telephone, email, and we are not responsible
        for notices that are not labelled or sent properly, or that do not have
        complete information.
      </p>

      <p>Your Nevada Privacy Rights</p>

      <p>
        If you are a resident of Nevada, you have the right to opt-out of the
        sale of certain Personal Data to third parties who intend to license or
        sell that Personal Data. You can exercise this right by contacting us
        here or email us with the subject line “Nevada Do Not Sell Request” and
        providing us with your name and the email address associated with your
        account.
      </p>

      <h3>20. Your Data Protection Rights</h3>

      <p>
        We enable you to make numerous choices about your data: Below are the
        following data protection rights you have when you use our services.{" "}
      </p>

      <ul>
        <li>You may choose not to provide us with certain information.</li>
        <li>
          If you wish to access, correct, update or request deletion of your
          personal information, you can do so at any time by contacting us
          through the customer services portal or at the information provided at
          the bottom of this Privacy Policy.
        </li>
        <li>
          If you request access to your personal data we will confirm whether we
          hold your information and provide you with a copy, explaining how we
          process it and why, how long we store it for and your rights
          associated with it.
        </li>
        <li>
          If you request deletion of your personal information, we will erase
          it. Please do note that we will need to retain any information that we
          require to fulfil our legal obligations or to establish, exercise or
          defend a legal claim.
        </li>
        <li>
          You can object to processing of your personal information, ask us to
          restrict processing of your personal information or request
          portability of your personal information. Again, you can exercise
          these rights by contacting us through the customer services portal or
          at the information provided at the bottom of this Privacy Policy.
        </li>
        <li>
          You have the right to opt-out of Email marketing communications we
          send you at any time. You can exercise this right by clicking on the
          “unsubscribe” or “opt-out” link in the marketing e-mails we send you.
        </li>
        <li>
          Opting Out Of Messages from Us: To opt-out of any future promotional
          messages from us, you should unsubscribe in the body of the
          promotional message that was sent to you (found at the bottom of the
          email) or send an unsubscribe request to us. We will process your
          request within a reasonable time after receipt.
        </li>
        <li>
          Similarly, if we have collected and process your personal information
          with your consent, then you can withdraw your consent at any time.
          Withdrawing your consent will not affect the lawfulness of any
          processing we conducted prior to your withdrawal, nor will it affect
          processing of your personal information conducted in reliance on
          lawful processing grounds other than consent.
        </li>
        <li>
          You have the right to complain to a data protection authority about
          our collection and use of your personal information. For more
          information, please contact us and we will send you a link or our data
          protection officer address.
        </li>
        <li>
          The easiest way to submit your request to us is through our Customer
          support email found at the bottom of this Privacy Policy. We respond
          to all requests we receive from individuals wishing to exercise their
          data protection rights in accordance with applicable data protection
          laws.
        </li>
      </ul>

      <h3>21. Our COPPA Policy Towards Children</h3>

      <p>
        Moveco complies with both the GDPR Children's Online Privacy Protection
        and, concerning EU data subjects, with GDPR. We do not knowingly request
        to collect personal information required for the Moveco user account
        from any person under the age of 18. Please notify us if you know of any
        individuals under the age of 18 using our website so we can take action
        to prevent access to our Services.
      </p>

      <h3>22. Privacy Shield</h3>

      <p>
        Moveco adheres to the seven privacy shield principles of notice, choice,
        and accountability for onward transfer, security, data integrity,
        access, and recourse, enforcement, and liability as they relate to
        personal data. Moveco verifies compliance with the Principles through
        self-assessment. The privacy policy covering human resources data can be
        accessed on our intranet site by all employees.
      </p>

      <h3>23. Legal Bases For Processing (For EEA Users)</h3>

      <p>
        If you are an individual in the European economic area (EEA), we collect
        and process information about you only where we have legal bases for
        doing so under applicable EU laws. The legal bases depend on the
        services you use and how you use them. These means we collect and use
        your information only where:
      </p>

      <ul>
        <li>
          We need it to provide you the services, including to operate the
          process your orders, provide customer support and personalized
          features and to protect the safety and security of the services;
        </li>
        <li>
          It satisfies a legitimate interest (which is not overridden by your
          data protection interests), such as for research and development, to
          market and promote the services and to protect our legal rights and
          interests;
        </li>
        <li>You give us consent to do so for a specific purpose; or</li>
        <li>We need to process your data to comply with a legal obligation.</li>
      </ul>

      <p>
        If you have consented to our use of information about you for a specific
        purpose, you have the right to change your mind at any time, but this
        will not affect any processing that has already taken place. Where we
        are using your information because a third party (e.g. your employer) or
        we have a legitimate interest to do so, you have the right to object to
        that use though, in some cases, and this may mean no longer using the
        services
      </p>

      <h3>24. Third-Party Site And Links</h3>

      <p>
        The Moveco contents may contain links to third parties who may collect
        personal information and non-personal information directly from you.
        Additionally, Moveco may use third parties to provide components of the
        Moveco contents. In either case, such third parties may have separate
        privacy policies and data collection practices, independent of Moveco.
        Moveco: (a) has no responsibility or liability for these independent
        policies or actions; (b) is not responsible for the privacy practices or
        the content of such site, and (c) does not make any warranties or
        representations about the materials, products or services offered on
        such website or the security of any information you provide to them
      </p>

      <h3>25. Delaware Do Not Track Disclosures</h3>

      <p>
        Various third parties have developed signals or other mechanisms for the
        expression of consumer choice regarding the collection of information
        about an individual consumer's online activities over time and across
        third-party web sites or online services (e.g., browser do not track
        signals). Currently, Moveco do not monitor or take any action concerning
        these signals or other mechanisms.
      </p>

      <h3>26. Data Security</h3>

      <p>
        We are committed to maintaining the security of your data. And have
        measures in place to protect against the loss, misuse, and alteration of
        the data under our control. We employ modern and secure techniques to
        protect our systems from intrusion by unauthorized individuals, and we
        regularly upgrade our security as better methods become available. Our
        data centers and those of our partners utilize new physical security
        measures to prevent unauthorized access to the facility. Also, all
        personal data protected in a location behind firewalls and other
        sophisticated security systems with limited (need-to-know)
        administrative access.{" "}
      </p>

      <p>
        Please be aware that no security measures are perfect or impenetrable.
        Therefore, although we use industry-standard practices to protect your
        privacy, we cannot (and do not) guarantee the absolute security of
        personal data.
      </p>

      <h3>27. GDPR Statement For (EEA Users)</h3>

      <p>
        These apply only to natural persons residing in the European economic
        area (for this section only, "you" or "you're" shall be limited
        accordingly). It is Moveco' policy to comply with the EU'S general data
        protection regulation (GDPR). Following the GDPR, we may transfer your
        personal information from your home country to the United States (or
        other countries) based upon the following legal frameworks:
      </p>

      <ul>
        <li>
          Legitimate business interests: We could not provide our services or
          comply with our obligations to you without transferring your personal
          information to the United States
        </li>
        <li>
          Consent: we may transfer your personal information when we receive
          your express, revocable consent.
        </li>
        <li>
          You have the right to opt-out of non-essential cookies (see our cookie
          policy) access, correct, delete, restrict, or object to our use of
          your personal information; be forgotten; port your data, and withdraw
          consents.
        </li>
      </ul>

      <p>
        We enable exercise of these rights primarily through our services (which
        we reserve the right to modify). We also fulfill our obligations in
        response to direct requests. We will endeavor to process applications
        within one month. Please note that we may not be able to comply with
        requests to the extent that they would cause us to violate any law or
        infringe any other person's rights. We reserve the right to request
        appropriate identification. We will process requests free of charge
        unless they impose an unreasonable cost on us.
      </p>

      <p>
        If you have a request, complaint, or inquiry, please contact our data
        protection officer at the address listed below. We are committed to
        working with you to obtain a fair resolution of any issue. You also have
        the right to file a complaint with the supervisory data protection
        authority of your jurisdiction.
      </p>

      <h3>28. Terms and Conditions</h3>

      <p>
        To learn more about our terms and conditions, please visit our Terms and
        Conditions page establishing the use, disclaimers, and limitations of
        liability governing the use of our website.
      </p>

      <h3>29. Jurisdiction</h3>

      <p>
        This privacy policy, and terms of service, cookies, and other policies
        of the website and software is governed by The French Court of Cassation
        laws.
      </p>

      <h3>30. Changes To our Privacy Policy</h3>

      <p>
        Moveco continually strives to implement new technologies and processes
        to protect you better, your privacy, and your use of the Moveco service.
        As a result, changes to this privacy policy may be made by Moveco from
        time to time. In no event will these changes result in the degradation
        of any of the security measures designed to protect you from
        unauthorized disclosure.
      </p>

      <h3>31. Contact Us</h3>

      <p>
        If you have any questions about this privacy policy, please contact us
        at moveco@gomoveco.com
      </p>
    </div>
  );
}
