import React, { useEffect, useState, useRef } from 'react'
import '../../style/Register.css';
import Axios from '../../api/index';
import { useHistory } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import { setLogin } from './AuthSlice';
import { useDispatch } from 'react-redux';
import registerImage from "../Pages/actions.png"

import checkicon from "./check.png";
import OTPInput from '../../components/OtpInputBox/OTPInput';
import CustomModal from '../../components/Modal/CustomModal';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import { setAlert, setOpenGoogleLoginModal } from '../../app/AppSlice';
import { useTranslation } from 'react-i18next';
import GoogleSignInTerm from '../../components/GoogleSignInTerm';


export default function Register(props) {
  const [error, setError] = useState("");
  const [state, setState] = useState({
    name: "",
    email: "",
    password: "",
  })
  const dispatch = useDispatch();
  const timerRef = useRef(null);
  const [showOTPModal, _showOTPModal] = useState(false);
  const [countDownText, _countDownText] = useState(null);
  const [expiresIn, _expiresIn] = useState(null);
  const [resend, _resend] = useState(false);
  const [isLoading, _isLoading] = useState(false);
  const [otp, _otp] = useState({1:'',2:'',3:'',4:''});
  const inputRefOne = useRef(null);
  const inputRefTwo = useRef(null);
  const inputRefThree = useRef(null);
  const inputRefFour = useRef(null);
  const { t , i18n } = useTranslation();
  const [isShowGoogleTerm, _isShowGoogleTerm] = useState(false);
  const [currentGoogleToken, _currentGoogleToken] = useState(null);

  const responseGoogle = async (response, withTerm = null) => {
    try {
      let payload = {
        type: 'google',
        accessToken: response.accessToken
      }
      if(withTerm === 'accept') {
        payload.confirmation = 'accept';
      }
      const {data} = await Axios.post('/auth/google-login',{accessToken: response.accessToken,type:"google"});
      if(data.message === 'accept or decline the sign up with google term') {
        _currentGoogleToken(response.accessToken);
        _isShowGoogleTerm(true);
        return;
      }
      if(data.user){
        dispatch(setLogin({user: data.user}));
        dispatch(setAlert({message: t("Successfully logged in"), showAlert: true, duration: 6000, severity:'success'}));
      }
      if(data.message === "You are already logged in with email, We have merged with your google account"){
        dispatch(setAlert({message: t("We have merged your account with your Google account"),showAlert: true, duration: 6000, severity:'success'}));
      }
    } catch (err) {
      if(err.response){
        dispatch(setAlert({message: err.response.data.message,showAlert: true, duration: 6000, severity:'error'}));
        setError(err.response.data.message);
      }
    }
  }
  
  useEffect(()=>{
    if(expiresIn){
    timerRef.current = setInterval(()=>{
      const now = new Date().getTime();
        const distance = expiresIn - now;
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);
        let displaySeconds = seconds < 10 ? `0${seconds}` : seconds;
        const displayText = `${minutes}:${displaySeconds}`
        _countDownText(displayText)
        if(minutes === -1){
          _countDownText(null);
          _expiresIn(null);
          return () => clearInterval(timerRef.current);
        }
    },1000)
  } else {
    return () => clearInterval(timerRef.current);
  }
  return () => clearInterval(timerRef.current);
  },[expiresIn]);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  const closeModal = () => {
    _showOTPModal(false);
    _expiresIn(null);
    _otp({1:'',2:'',3:'',4:''});
    _countDownText(null);
  }

 
  const handleResend = () => {
    _otp({1:'',2:'',3:'',4:''});
    _countDownText(null);
    _expiresIn(null);
    _resend(true);
    handleSubmit();
  }

  const handleOnChange = (e) => {
    if(e.target.name === 'passwordConfirm') {
      setState({
        ...state
      })
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value
      })
    }
  }

  const handleSubmit = async (e = null) => {
    const language = i18n.language === 'fr' ? 'French': 'English';
    if(e) e.preventDefault();
    if(scorePassword(pass) > 60 && check() === 'Matching' ) {
      try {
      _isLoading(true);
        const { data } = await Axios.post('/auth/register', {...state, language});
        if(data.expiresIn){
          _expiresIn(new Date(data.expiresIn));
          _showOTPModal(true);
          if(inputRefOne.current)inputRefOne.current.focus();
        }
        if(data.message === "You previously logged in with your Google account."){
          dispatch(setOpenGoogleLoginModal(true));
        }
      } catch (err) {
        if(err.response){
          dispatch(setAlert({message: err.response.data.message,showAlert: true, duration: 6000, severity:'error'}));
          setError(err.response.data.message);
        }
      }
      _isLoading(false);
      _resend(false);
    } else {
      setError('Password to weak or not matching');
    }
  }

  const otpChangeEvent = (e) => {
    let {key} = e;
    const {name} = e.target;
    if(key === "Backspace" || key === "Delete"){
      _otp({...otp,[Number(name)]:''})
      if(name!== "1"){
        if(name === "2")inputRefOne.current.focus(); 
        if(name === '3')inputRefTwo.current.focus();
        if(name === "4")inputRefThree.current.focus();
      }
      return
    }
    if (!key.match(/[0-9]/)) {
      // if(key === "Shift" || key === "CapsLock") return;
        _otp({...otp,[name]: ''});
        e.preventDefault();
        e.target.value = ''
        return
    }
    key = Number(key)
    if(key === 0 && otp[4] === 0){
      e.preventDefault();
      e.target.value = 0
      return;
    }
    if((key >= 0 && key < 10)){
      _otp({...otp,[name]: key});
      if(name === "1")inputRefTwo.current.focus(); 
      if(name === '2')inputRefThree.current.focus();
      if(name === "3")inputRefFour.current.focus();
      if(name === "4")inputRefFour.current.blur();
    }
  }
  
  const submitRegistration = async () => {
    // OTP and email
    const temp = Number(`${otp[1]}${otp[2]}${otp[3]}${otp[4]}`);
    const email = state.email
    try {
      _isLoading(true);
      const {data} = await Axios.post('/auth/register/verify&register',{otp:temp, email});
       _isLoading(false);
       _showOTPModal(false);
       _otp({1:'',2:'',3:'',4:''});
       dispatch(setLogin({user: data.user}));
       dispatch(setAlert({message: t("Your account is successfully registered and verified"), showAlert: true, duration: 6000, severity:'success'}));
    } catch (error) {
      if(error.response){
        setError(error.response.data.message);
       if(error.response.data.message === "Too Many attempts, Please try again tomorrow!"){
          _showOTPModal(false);
          dispatch(setAlert({message: t("Too Many attempts, please try again later!"),showAlert: true, duration: 6000, severity:'error'}));
       }
       if(error.response.data.message === "Invalid OTP"){
        // let user know
        _otp({1:'',2:'',3:'',4:''});
        dispatch(setAlert({message: t("Invalid code"),showAlert: true, duration: 6000, severity:'error'}));
       }
       if(error.response.data.message === "OTP Expired"){
        // let user know
        dispatch(setAlert({message: t("Code expired"),showAlert: true, duration: 6000, severity:'error'}));
       }
      }
      _isLoading(false);
    }
  }

  const history = useHistory();

  let pass;
  let passelement = document.getElementById('pass');
  if (passelement !== null) {
      pass = passelement.value;
  }
  else {
      pass = null;
  }

  let confirm_password;
  let confirmpasswordelement = document.getElementById('passconfirm');
  if (confirmpasswordelement !== null) {
    confirm_password = confirmpasswordelement.value;
  }
  else {
    confirm_password = null;
  }

  function check () {

    if (pass === confirm_password && confirm_password !== null && pass !== null && pass !== "" && confirm_password !== "" ) {
      return ('Matching')
    } else if (confirm_password !== null || pass !== null) {
      return ('Not Macthing')
    }
  }

  function scorePassword(pass) {
    var score = 0;
    if (!pass)
        return score;

    // award every unique letter until 5 repetitions
    var letters = {};
    for (var i=0; i<pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 5.0 / letters[pass[i]];
    }

    // bonus points for mixing it up
    var variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass),
    }

    var variationCount = 0;
    for (var check in variations) {
        variationCount += (variations[check] === true) ? 1 : 0;
    }
    score += (variationCount - 1) * 10;

    return parseInt(score);
  }

  function checkPassStrength() {
    var score = scorePassword(pass);
    if (score > 80) {
      if (document.getElementById("PassStrenghtLittleContainer3") !== null) {
        document.getElementById("PassStrenghtLittleContainer3").style.background="#2C2D40";
      }
      if (document.getElementById("PassStrenghtLittleContainer1") !== null) {
        document.getElementById("PassStrenghtLittleContainer1").style.background="#592689";
      }
      if (document.getElementById("PassStrenghtLittleContainer2") !== null) {
        document.getElementById("PassStrenghtLittleContainer2").style.background="#699D51";
      }
    } else if (score > 60) {
      if (document.getElementById("PassStrenghtLittleContainer2") !== null) {
        document.getElementById("PassStrenghtLittleContainer2").style.background="#699D51";
      }
      if (document.getElementById("PassStrenghtLittleContainer1") !== null) {
        document.getElementById("PassStrenghtLittleContainer1").style.background="#592689";
      }
      if (document.getElementById("PassStrenghtLittleContainer3") !== null) {
        document.getElementById("PassStrenghtLittleContainer3").style.background="none";
      }
    } else if (score >= 30) {
      if (document.getElementById("PassStrenghtLittleContainer1") !== null) {
        document.getElementById("PassStrenghtLittleContainer1").style.background="#592689";
      }
      if (document.getElementById("PassStrenghtLittleContainer2") !== null) {
        document.getElementById("PassStrenghtLittleContainer2").style.background="none";
      }
      if (document.getElementById("PassStrenghtLittleContainer3") !== null) {
        document.getElementById("PassStrenghtLittleContainer3").style.background="none";
      }
    } else {
      if (document.getElementById("PassStrenghtLittleContainer3") !== null) {
        document.getElementById("PassStrenghtLittleContainer3").style.background="none";
      }
      if (document.getElementById("PassStrenghtLittleContainer2") !== null) {
        document.getElementById("PassStrenghtLittleContainer2").style.background="none";
      }
      if (document.getElementById("PassStrenghtLittleContainer1") !== null) {
        document.getElementById("PassStrenghtLittleContainer1").style.background="none";
      }
    }
  }

  function validateEmail() {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(state.email);
  }

  return (
    <>
    <div className="RegisterContainer">

        <div className="container">

        <div className='GoogleLogin' style={{marginLeft:'30px'}} >
                <GoogleLogin
                  clientId={"91140303370-4bek32qk73h5fpfe2mlcmhmpbi9c4ndu.apps.googleusercontent.com"}
                  buttonText={t("Login with Google")}
                  onSuccess={responseGoogle}
                  className="g-btn"
                  icon={true}
                  onFailure={(err)=>{console.log(err)}}
                  cookiePolicy={'single_host_origin'}
                  theme={"light"}
                  prompt="select_account"
                />
                </ div>

          <div className="signup-content">
            <div className="signup-form">
              <h2 className="signup-form-title">{t("Sign up")}</h2>
              <form method="POST" className="register-form" action="#" id="register-form">
                <div className="form-group">
                  <label htmlFor="name"><i className="zmdi zmdi-account material-icons-name"></i></label>
                  <input type="text" autocomplete="off" name="name" required onChange={handleOnChange} id="name" placeholder={t("Name")} />
                  <span className='Confirmation'>{state.name.length > 2 ? <img src={checkicon} alt="checkicon" /> : null }</span>
                </div>
                <div className="form-group">
                  <label htmlFor="email"><i className="zmdi zmdi-email"></i></label>
                  <input type="email" name="email" autocomplete="off" required onChange={handleOnChange} id="email" placeholder="Email" />
                  <span className='Confirmation'>{validateEmail() ? <img src={checkicon} alt="checkicon" /> : null }</span>
                </div>
                <div className="form-group">
                  <label htmlFor="pass"><i className="zmdi zmdi-lock"></i></label>
                  <div className='PassandCountainer'>
                    <input type="password" autocomplete="new-password" name="password" required onChange={handleOnChange} id="pass" placeholder={t("Password")} />
                    <div className='PassStrenghtContainer'>
                      <div className='PassStrenghtLittleContainer1' id='PassStrenghtLittleContainer1'></div>
                      <div className='PassStrenghtLittleContainer2'id='PassStrenghtLittleContainer2'></div>
                      <div className='PassStrenghtLittleContainer3'id='PassStrenghtLittleContainer3'></div>
                    </div>
                  </div>
                  {checkPassStrength()}
                  <span className='Confirmation'>{scorePassword(state.password) > 60 ? <img src={checkicon} alt="checkicon" /> : null }</span>
                </div>

                <div className="form-group">
                  <label htmlFor="confirm_password"><i className="zmdi zmdi-lock"></i></label>
                  <input type="password" autoComplete="off" name="passwordConfirm" required onChange={handleOnChange} id="passconfirm" placeholder={t("Password")} />
                  <span className='Confirmation'>{check() === 'Matching' ? <img src={checkicon} alt="checkicon" /> : null}</span>
                </div>


                <div className="form-group form-button">
                  <PrimaryButton isLoading={isLoading} onClick={handleSubmit}>{t("Sign up")}</PrimaryButton>
                </div>

              </form>

              <a href="" onClick={(e) => { e.preventDefault(); history.push("/login")}}>{t("Already registered?")}</a>
            </div>

          </div>
        </div>

        <div className='registerImageContainer'>
          <img src={registerImage} alt="resigerImage" />
        </div>
        

    </div>
    {showOTPModal && <CustomModal onClose={closeModal}>
      <div className="modal-content">
        <div className="main-text">
          <span>{t("We have sent you a code to your email to verify your account")}</span>
          <p><i>{t("Remember to also check your spam folder")}</i></p>
          <div>
            <p>{t("Please enter the code")}</p>
            <p>{expiresIn && countDownText}</p>
          </div>
        </div>
        <div className="otp-container">
            <OTPInput ref={inputRefOne}   value={otp[1]} name={1} onKeyUp={otpChangeEvent} />
            <OTPInput ref={inputRefTwo}   value={otp[2]} name={2} onKeyUp={otpChangeEvent} />
            <OTPInput ref={inputRefThree} value={otp[3]} name={3} onKeyUp={otpChangeEvent} />
            <OTPInput ref={inputRefFour}  value={otp[4]} name={4} onKeyUp={otpChangeEvent} />
        </div>
          <div className="btn-container">
            <PrimaryButton isLoading={resend} disabled={Boolean(expiresIn)} onClick={handleResend}>{t('Resend the code')}</PrimaryButton>
              <PrimaryButton
                isLoading={
                  isLoading &&
                  otp[1] !== "" &&
                  otp[2] !== "" &&
                  otp[3] !== "" &&
                  otp[4] !== ""
                }
                disabled={
                  otp[1] === "" ||
                  otp[2] === "" ||
                  otp[3] === "" ||
                  otp[4] === ""
                }
                onClick={submitRegistration}
              >
                {t("Sign up")}
            </PrimaryButton>
          </div>
        </div>
    </CustomModal>}
    <GoogleSignInTerm
        title={t("Confirm your account creation")}
        description={t("You are about to create an account on moveco. We will use your email and name from your Google account. You can delete your moveco account and all the data linked whenever you want.")}
        show={isShowGoogleTerm}
        onAccept={async () => {
          _isShowGoogleTerm(false);
          await responseGoogle({
            accessToken: currentGoogleToken,
          }, 'accept');
        }}
        onCancel={() => {
          _isShowGoogleTerm(false);
          _currentGoogleToken(null);
        }}
      />
    </>
  )
}
