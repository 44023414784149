import React from 'react';

import '../../style/Header.css';
import logo from './logo.png';


// IMPORT USER IMG
// import userImg from './user.png'

import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setPaths } from '../User/UserPathSlice';
import { setSelectedToLocation, setToAutosuggestions } from '../SearchBar/To/ToAutosuggestionsSlice';
import { setFromAutosuggestions, setSelectedFromLocation } from '../SearchBar/From/FromAutosuggestionsSlice';
import { setDisplayedPath } from '../MapApp/Results/PathList/PathListSlice'
import {setPathCar} from '../MapApp/Results/PathList/Car/PathCarSlice';
import { setPathBus, setBackInitialBusStates } from '../MapApp/Results/PathList/Transit/Bus/PathBusSlice';
import { setPathTrain, setBackInitialTrainStates } from '../MapApp/Results/PathList/Transit/Train/PathTrainSlice';
import { setAddPathToBallance } from '../MapApp/AddPathToBallance/AddPathToBallanceSlice';
import DropDownUser from './DropDownUser';
import { SelectLanguageComponent } from './Language.js';

export function Header(props) {

    const dispatch = useDispatch();
    // const history = useHistory();
    
    return (

        <header>

            <div className="Header-Container" >

                <div className='LogoAndHeadbar'>

                    <Link to="/" className='HeadBarLink' onClick={()=>{
                        dispatch(setPaths([]));
                        dispatch(setSelectedFromLocation(null));
                        dispatch(setSelectedToLocation(null));
                        dispatch(setFromAutosuggestions([]));
                        dispatch(setToAutosuggestions([]));
                        dispatch(setAddPathToBallance(false));
                        dispatch(setDisplayedPath(''));
                        dispatch(setPathCar({id: 'car',routes: [],CO2displayed: 0 }));
                        dispatch(setPathBus({id: 'bus',routes: [],CO2displayed: 0 }));
                        dispatch(setBackInitialBusStates());
                        dispatch(setPathTrain({id: 'train',routes: [],CO2displayed: 0 }));
                        dispatch(setBackInitialTrainStates());
                    }}>
                        <img src={logo} alt="Logo" />
                    </Link>


                </div>

                <div className='SelectLanguageAndUser'>  
                    <SelectLanguageComponent />

                    <DropDownUser />     

                </div>

         

            </div>

        </header >

    );

}


export default Header;


/*
                    <div className='HeadBarLink' onClick={(e) => { e.preventDefault(); history.push("/discover")}} >Discover</div>

                    <div className='HeadBarLink' onClick={(e) => { e.preventDefault(); history.push("/blog")}} >Blog</div>
*/