import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../api";

export const saveUserPath = createAsyncThunk(
  "saveUserPath",
  
  async (payload, { rejectWithValue }) => {
    try {
      await axios.post("/userpath/postuserpath/", payload);
    } catch (error) {
      console.log(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  
  }
);