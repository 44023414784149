import { createSlice } from "@reduxjs/toolkit";

export const TrainCarbonCacheSlice = createSlice({
    name: "TrainCarbonCacheSlice",
    initialState: {},
    reducers: {
        setTrainCarbonData: (state, action) => {
            return {
                ...state,
                [action.payload.country]: action.payload.data
            }
        }
    }
})

export const state = state => state;
export const { setTrainCarbonData } = TrainCarbonCacheSlice.actions;
export default TrainCarbonCacheSlice.reducer;