import { createAsyncThunk } from '@reduxjs/toolkit';
const axios = require('axios');

export const askBicyclePath = createAsyncThunk("askBicyclePath", async (inValue,{rejectWithValue}) => {

  try {
    const resp = await axios.get('https://router.hereapi.com/v8/routes', {
    params: {
      apiKey:'j2YcVdp86ieJODQGLUDX2f6APJhkmjd9aG7RBZahvUQ',
      origin:`${inValue[0].position.lat},${inValue[0].position.lng}`,
      destination:`${inValue[1].position.lat},${inValue[1].position.lng}`,
      departureTime: `${inValue[2]}`,
      return: 'polyline,turnByTurnActions,travelSummary',
      alternatives: 4,
      transportMode: 'bicycle',
    }
  })
     
      if(resp.data.hasOwnProperty("notices")){ //if path not found
        return rejectWithValue(resp.data.notices[0].title);
      }
     
      // Create routes variable
      let routes = [];
  
      // Read the response and create i routes
      for(let i=0; i < resp.data.routes.length; i++) {
        resp.data.routes[i].sections.forEach((section)=>{
          let route = {
            id: `route${i}`,
            distance: section.travelSummary.length,
            duration: section.travelSummary.duration,
            polyline: section.polyline,
            vehicleModeStep: 'bicycle'
              }
          routes.push(route);
        })    
      };
  
      // Create the NewPathCar
      let newPathBicyle = {
        id: 'bicycle',
        routes: routes
      };   
  
      
      return newPathBicyle;
  
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }

});