import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useSelector, useDispatch } from 'react-redux';
import { isLoggedIn, logout, selectIsAppLoading, userData } from '../Auth/AuthSlice';
import { Link } from 'react-router-dom';
import { setPaths } from '../User/UserPathSlice';
// import Loading from '../MapApp/Results/loading.svg'
import loadinglogo from '../MapApp/Results/PathList/loading.svg'
import userImages from "../User/user_images";

import Login from '../Auth/LoginLittle';
import { useTranslation } from 'react-i18next';


import userImg from './user.png'
import { setFromAutosuggestions, setSelectedFromLocation } from '../SearchBar/From/FromAutosuggestionsSlice';
import { setSelectedToLocation, setToAutosuggestions } from '../SearchBar/To/ToAutosuggestionsSlice';
import { setDisplayedPath } from '../MapApp/Results/PathList/PathListSlice';
import { setPathCar } from '../MapApp/Results/PathList/Car/PathCarSlice';
import { setPathBus } from '../MapApp/Results/PathList/Transit/Bus/PathBusSlice';
import { setPathTrain } from '../MapApp/Results/PathList/Transit/Train/PathTrainSlice';
import axios from '../../api';
import { useGoogleLogout } from 'react-google-login';


export default function SimpleMenu() {

    const isLogin = useSelector(isLoggedIn);
    const dispatch = useDispatch();
    const userDetail = useSelector(userData);
    const isAppLoading = useSelector(selectIsAppLoading);
    const { t } = useTranslation();
    const {signOut } = useGoogleLogout({ clientId: '91140303370-4bek32qk73h5fpfe2mlcmhmpbi9c4ndu.apps.googleusercontent.com'});

    useEffect(() => {
      handleClose();
    }, [isLogin])

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clearSession = async () => {
    try {
      await axios.post('/auth/logout');
      dispatch(logout());
    } catch (error) {
      dispatch(logout());
    }
    dispatch(setPaths([]));
    dispatch(setSelectedToLocation(null));
    dispatch(setSelectedFromLocation(null));
    dispatch(setFromAutosuggestions([]));
    dispatch(setToAutosuggestions([]));
    dispatch(setDisplayedPath(''));
    dispatch(setPathCar({id: 'car',routes: [],CO2displayed: 0 }));
    dispatch(setPathBus({id: 'bus',routes: [],CO2displayed: 0 }));
    dispatch(setPathTrain({id: 'train',routes: [],CO2displayed: 0 }));
  }

  const letLogout = async () => {
    handleClose();
    await clearSession();
    await signOut();
  }
  return (
    <div className='DropDownUser' >
      {isAppLoading ? <img id="loadingLogo" style={{ width: 50 }} src={loadinglogo} alt="loading"/> : <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
      <img src={!isLogin ? userImg : userDetail ? userImages[userDetail.avtar].src : userImg} alt="userimage" style={{border : ` ${userDetail ? userImages[userDetail.avtar]?.color : '#000'} 2px solid`}} className={!isLogin ? 'UserImgHeaderIncognito' : 'UserImgHeader'}/>
            {!isLogin ? 
                <div className='HeadBarLink'>{t('Sign in')}</div>
            : userDetail.name
            }        
        <ArrowDropDownIcon />
      </Button>}
        {!isLogin ? 
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            disableScrollLock={true}
            getContentAnchorEl={null}
          >
            <MenuItem
            disableGutters={true}
            disableRipple
            style={{
              backgroundColor: "transparent",
              paddingTop: 0,
              paddingBottom: 0,
              whiteSpace: "unset",
              lineHeight: "unset",
            }}
          >
            <Login closeDropDown={handleClose} />
          </MenuItem>
          </Menu>
        :
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          disableScrollLock={true}
       >
            <MenuItem onClick={handleClose} component={Link} to={`/user/balance`}>{t('My Balance')}</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to={`/user/journey`}>{t('My Journeys')}</MenuItem>
            <hr />
            <MenuItem onClick={handleClose} component={Link} to={`/user/parameter`}>{t('Parameters')}</MenuItem>
            <MenuItem onClick={letLogout}>{t('Logout')}</MenuItem>
          </Menu>
        }



    </div>
  );
}