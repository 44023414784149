import React, { useEffect, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import './i18n';
import loadinglogo from "./containers/MapApp/Results/PathList/loading.svg"
import axios from "./api";
import { selectAlert, setAlert } from "./app/AppSlice";
import Alert from "./components/Alert/Alert";
import GoogleLoginModal from "./components/GoogleLoginModal/GoogleLoginModal";
import { logout, setAppLoading, setLogin } from "./containers/Auth/AuthSlice";
import Routes from "./routes";


const App = () => {
  const dispatch = useDispatch();
  const alert = useSelector(selectAlert);

  useEffect(() => {
    document.documentElement.style.setProperty('--vh', window.innerHeight/100 + 'px');
    window.addEventListener('resize', () => { 
      document.documentElement.style.setProperty('--vh', window.innerHeight/100 + 'px');
    })
  }, []);
  
  useEffect(() => {
    const appLoad = async () => {
      try {
        const response = await axios.post("/auth/userinfo");
        if (response?.data) {
          dispatch(
            setLogin({
              user: response.data.user,
            })
          );
          dispatch(setAppLoading(false));
        }
      } catch (error) {
        if (error.response) {
          dispatch(logout());
        }
        dispatch(setAppLoading(false));
      }
    };
    appLoad();

  }, [dispatch]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setAlert({ showAlert: false }))
  };

  return (
    <>
      <Suspense fallback={
        <div style={{display: "flex",height: "100vh",flexDirection: "column",alignItems: "center",justifyContent: "center"}} >
        <img id="loadingLogo" style={{ width: 100 }} src={loadinglogo} alt="loading"/>
      </div>
      }>
        <Routes />
        <Alert open={alert.showAlert} onClose={handleClose} autoHideDuration={alert.duration} severity={alert.severity}>{alert.message}</Alert>
        <GoogleLoginModal />
      </Suspense>
    </>
  );
};

export default App;
