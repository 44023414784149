import React, { useLayoutEffect, useCallback, useRef, useState, useMemo } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectActivePath, selectHoveredPath } from "../Results/PathList/PathListSlice";
import { getGreatCurveLineString } from "../../../utils/calc";

const MapAssets = {
  BUS_ROUTE_COLOR: "#3E1A60",
  TRAIN_ROUTE_COLOR: "#699D51",
  SUBWAY_ROUTE_COLOR: "#699D51",
  FERRY_ROUTE_COLOR: "#592689",
  LIGHT_RAIL_ROUTE_COLOR: "#699D51",
  AERIAL_ROUTE_COLOR: "#699D51",
  DEFAULT_ROUTE_COLOR: "rgba(0, 128, 255, 0.7)",
  CAR_ROUTE_COLOR: "#52547A",
  WALKING_ROUTE_COLOR: "#C2C3D6",
  //ICONS
  BUS_ICON: "https://image.flaticon.com/icons/svg/66/66509.svg",
  TRAIN_ICON: "https://image.flaticon.com/icons/svg/66/66273.svg",
  SUBWAY_ICON: "https://cdn.onlinewebfonts.com/svg/img_10666.png",
  FERRY_ICON: "https://image.flaticon.com/icons/svg/66/66896.svg",
  LIGHT_RAIL_ICON: "https://image.flaticon.com/icons/svg/66/66462.svg",
  AERIAL_ICON: "https://image.flaticon.com/icons/svg/66/66439.svg",
  WALKING_ICON: "https://www.flaticon.com/svg/static/icons/svg/76/76865.svg",
  FROM_ICON: `<svg width="18" height="18" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="8" fill="#592689" stroke="white" stroke-width="1"  /></svg>`,
  TO_ICON: `<svg width="18" height="18" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="8" fill="#592689" stroke="white" stroke-width="1"  /></svg>`,
};
Object.freeze(MapAssets);

export default function HereMap({ from, to, }) {
  const mapRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [platform, _platform] = useState(null);
  const [activeGroup, _activeGroup] = useState(null);
  const [hoveredGroup, _hoveredGroup] = useState(null);
  const [fromToMarkerGroup, _fromToMarkerGroup] = useState(null);
  const [map, _map] = useState(null);
  const activePath = useSelector(selectActivePath);
  const hoveredPath = useSelector(selectHoveredPath);

  const planePathLine = useMemo(() => {
    if (from && to && activePath && (activePath.type === 'plane')) {
      return getGreatCurveLineString({ coord1: from, coord2: to });
    }
    return '';
  }, [activePath, from, to]);

  const planePathHoverdLine = useMemo(() => {
    if (from && to && hoveredPath && (hoveredPath.type === 'plane')) {
      return getGreatCurveLineString({ coord1: from, coord2: to }, "#808080");
    }
    return '';
  }, [from, to, hoveredPath]);

  useLayoutEffect(() => {
    if (!mapRef.current) return;
    const H = window.H;
    const activeGroup = new H.map.Group();
    const hoveredGroup = new H.map.Group();
    const fromToMarkerGroup = new H.map.Group();
    const platform = new H.service.Platform({
      apikey: "j2YcVdp86ieJODQGLUDX2f6APJhkmjd9aG7RBZahvUQ",
    });
    const defaultLayers = platform.createDefaultLayers();
    const map = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
      center: { lat: 50, lng: 5 },
      zoom: 4,
      pixelRatio: window.devicePixelRatio || 1,
      padding: { top: 100, left: 50, bottom: 50, right: 50 },
    });

    function handleResize() {
      map.getViewPort().resize()
    }

    window.addEventListener("resize", handleResize);
    // eslint-disable-next-line no-unused-vars
    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
    const ui = H.ui.UI.createDefault(map, defaultLayers);
    _platform(platform);
    _map(map);
    _hoveredGroup(hoveredGroup);
    _activeGroup(activeGroup);
    _fromToMarkerGroup(fromToMarkerGroup);

    var mapSettings = ui.getControl('mapsettings');
    var zoom = ui.getControl('zoom');
    var scalebar = ui.getControl('scalebar');

    mapSettings.setVisibility(false);
    zoom.setVisibility(false);
    scalebar.setVisibility(false);

    return () => {
      window.removeEventListener("resize", handleResize);
      map.dispose();
    };
  }, [mapRef]);

  const getFromToMarker = useCallback(() => {
    if (!fromToMarkerGroup || !map) return;
    const H = window.H;
    fromToMarkerGroup.removeAll();
    let fromIcon = new H.map.Icon(MapAssets.FROM_ICON, {
      anchor: { x: 8, y: 8 },
    });
    let fromMarker = new H.map.Marker(from, { icon: fromIcon });
    let toIcon = new H.map.Icon(MapAssets.TO_ICON, { anchor: { x: 8, y: 8 } });
    let toMarker = new H.map.Marker(to, { icon: toIcon });
    fromToMarkerGroup.addObject(fromMarker);
    fromToMarkerGroup.addObject(toMarker);
    map.addObject(fromToMarkerGroup);
    map.getViewModel().setLookAtData({
      bounds: fromToMarkerGroup.getBoundingBox()
    });
  }, [from, fromToMarkerGroup, map, to])

  const getMapAsset = useCallback((section, type) => {
    // type = color or marker
    const typeEnum = {
      color: "color",
      marker: "marker",
    };
    if (section.travelTypeStep === "pedestrian") {
      return typeEnum.color === type
        ? MapAssets.WALKING_ROUTE_COLOR
        : MapAssets.WALKING_ICON;
    } else {
      if (
        section.vehicleModeStep === "bus" ||
        section.vehicleModeStep === "privateBus" ||
        section.vehicleModeStep === "busRapid"
      ) {
        return typeEnum.color === type
          ? MapAssets.BUS_ROUTE_COLOR
          : MapAssets.BUS_ICON;
      } else if (
        section.vehicleModeStep === "highSpeedTrain" ||
        section.vehicleModeStep === "intercityTrain" ||
        section.vehicleModeStep === "interRegionalTrain" ||
        section.vehicleModeStep === "regionalTrain" ||
        section.vehicleModeStep === "cityTrain"
      ) {
        return typeEnum.color === type
          ? MapAssets.TRAIN_ROUTE_COLOR
          : MapAssets.TRAIN_ICON;
      } else if (section.vehicleModeStep === "subway") {
        return typeEnum.color === type
          ? MapAssets.SUBWAY_ROUTE_COLOR
          : MapAssets.SUBWAY_ICON;
      } else if (
        section.vehicleModeStep === "lightRail" ||
        section.vehicleModeStep === "monorail"
      ) {
        return typeEnum.color === type
          ? MapAssets.LIGHT_RAIL_ROUTE_COLOR
          : MapAssets.LIGHT_RAIL_ICON;
      } else if (section.vehicleModeStep === "ferry") {
        return typeEnum.color === type
          ? MapAssets.FERRY_ROUTE_COLOR
          : MapAssets.FERRY_ICON;
      } else if (
        section.vehicleModeStep === "inclined" ||
        section.vehicleModeStep === "aerial"
      ) {
        return typeEnum.color === type
          ? MapAssets.AERIAL_ROUTE_COLOR
          : MapAssets.AERIAL_ICON;
      } else if (section.vehicleModeStep === "car") {
        return MapAssets.CAR_ROUTE_COLOR;
      } else {
        return typeEnum.color === type
          ? MapAssets.DEFAULT_ROUTE_COLOR
          : MapAssets.WALKING_ICON;
      }
    }
  }, []);

  // when select a path
  useEffect(() => {
    const H = window.H;
    if (!H || !fromToMarkerGroup || !activeGroup || !map) return;
    if (activePath && H) {
      if (activePath.type === 'plane') {
        if (planePathLine) {
          activeGroup.addObject(planePathLine);
          map.addObject(activeGroup);
        }
      } else {
        activePath.steps.forEach((section) => {
          let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
          let polyline = new H.map.Polyline(linestring, {
            style: {
              lineWidth: 4,
              strokeColor: getMapAsset(section, "color"),
            },
          });
          activeGroup.addObject(polyline);
          map.addObject(activeGroup);
        });
      }
      map.getViewModel().setLookAtData({
        bounds: fromToMarkerGroup.getBoundingBox()
      });
    }
    return () => {
      if (map && activeGroup) {
        activeGroup.removeAll();
      }
    }
  }, [activeGroup, activePath, fromToMarkerGroup, getMapAsset, map, planePathLine]);

  // when hoverd a path
  useEffect(() => {
    const H = window.H;
    if (!H || !hoveredGroup || !map) return;
    if (hoveredPath) {
      if (hoveredPath.type === 'plane') {
        if (planePathHoverdLine) {
          hoveredGroup.addObject(planePathHoverdLine);
          map.addObject(hoveredGroup);
        }
      } else {
        const H = window.H;
        hoveredPath.steps.forEach((section) => {
          let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
          let polyline = new H.map.Polyline(linestring, {
            style: {
              lineWidth: 4,
              strokeColor: "#808080",
            },
          });
          hoveredGroup.addObject(polyline);
          map.addObject(hoveredGroup);
        });
      }
      map.getViewModel().setLookAtData({
        bounds: fromToMarkerGroup.getBoundingBox()
      });
    }
    return () => {
      if (map && hoveredGroup) {
        hoveredGroup.removeAll();
      }
    }
  }, [fromToMarkerGroup, getMapAsset, hoveredGroup, hoveredPath, map, planePathHoverdLine]);


  // when user selects from searchbar
  useEffect(() => {
    if (!map) return;
    const H = window.H;
    if (from && !to) {
      // group.removeAll(); // remove all existing objects on the map
      fromToMarkerGroup.removeAll();
      let svgIcon = new H.map.Icon(MapAssets.FROM_ICON, {
        anchor: { x: 8, y: 8 },
      });
      let marker = new H.map.Marker(from, { icon: svgIcon });
      fromToMarkerGroup.addObject(marker);
      map.addObject(fromToMarkerGroup);
      map.getViewModel().setLookAtData({ position: from, zoom: 7 }, true);
    }
    if (!from && to) {
      // group.removeAll(); // remove all existing objects on the map
      fromToMarkerGroup.removeAll();
      let svgIcon = new H.map.Icon(MapAssets.TO_ICON, {
        anchor: { x: 8, y: 8 },
      });
      let marker = new H.map.Marker(to, { icon: svgIcon });
      fromToMarkerGroup.addObject(marker);
      map.addObject(fromToMarkerGroup);
      map.getViewModel().setLookAtData({ position: to, zoom: 7 }, true);
    }

    if (from && to) {
      // group.removeAll(); // remove all existing objects on the map
      // const H = window.H;
      if (from && to) {
        // group.removeAll(); // remove all existing objects on the map
        getFromToMarker();
      }
    }
  }, [from, fromToMarkerGroup, getFromToMarker, map, to]);

  // when reversed
  useEffect(() => {
    if (from && to) {
      getFromToMarker();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to]);


  return <div id="map" ref={mapRef} className="Map"></div>;
}
