import { createSlice } from '@reduxjs/toolkit';
import { askBicyclePath } from './Util/askBicyclePath';

export const PathBicycleSlice = createSlice({
  name: 'PathBicycle',
  initialState: {
    path: 
      {
        id: 'bicycle',
        routes: [],
        CO2displayed: 0
      },
    CO2: 
      {
        manufacture: 0.005,
        infrastructure: 0,
        operation: 0.016
      },
    bicycleLoading: false
  },
  reducers: {
    setPathBicycle: (state, action) => {
      return {
        ...state,
        path : action.payload
      }
    },
  },
  extraReducers: {
    [askBicyclePath.fulfilled]: (state, action) => {
      switch (action.type) {
        default:
          return {
            ...state,
            bicycleLoading : false,
            path : {
              id: action.payload.id,
              routes: action.payload.routes,
              CO2displayed: action.payload.routes[0].distance /1000 * (state.CO2.infrastructure + state.CO2.operation + state.CO2.manufacture)
            }
          }
      }
    },
    [askBicyclePath.rejected]:(state) => {
      return {
        ...state,
        path: {
          id: 'bicycle',
          routes: [],
        },
        bicycleLoading: false
      }
    },
    [askBicyclePath.pending]: (state) => {
      return {
        ...state,
        bicycleLoading : true
      }      
    },
  }
});

export const { setPathBicycle } = PathBicycleSlice.actions;


export const selectPathBicycle = state => state.PathBicycle.path;
export const selectBicycleLoading = state => state.PathBicycle.bicycleLoading;
export const selectBicycleCO2 = state => state.PathBicycle.CO2;

export default PathBicycleSlice.reducer;
