import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTranistCarbon } from './getTransitCarbon';
import axios from "axios";
import {setTrainCarbonData} from '../Train/TrainCarbonCacheSlice';
import store from '../../../../../../app/store';

export const askTransitPath = createAsyncThunk("askTransitPath", async (inValue,{rejectWithValue}) => {
  const originCountry = inValue[0].country;
  const destinationCountry = inValue[1].country;
  const isCountrySame = originCountry === destinationCountry ? true : false
  try {
    const response = await axios.get('https://transit.router.hereapi.com/v8/routes', {
      params: {
        apiKey:'j2YcVdp86ieJODQGLUDX2f6APJhkmjd9aG7RBZahvUQ',
        origin:`${inValue[0].position.lat},${inValue[0].position.lng}`,
        destination:`${inValue[1].position.lat},${inValue[1].position.lng}`,
        departureTime: inValue[2],
        return: 'fares,travelSummary,polyline',
        alternatives: 6,
        routeattributes: 'waypoints,summary,shape,legs',
      }
    })
      // Create an empty path Transit variable
      const pathTransit = {
        id: 'transit',
        routes: [],
      };
  
      // Create a newPathTransit variable
      let newPathTransit = [];
      
      // Read and store the response data
      for(let i=0; i < response.data.routes.length; i++) {
  
        // Creat an empty stepS variable
        let steps = [];
            
        for(let j=0; j < response.data.routes[i].sections.length; j++) {

          let travelTypeStep = response.data.routes[i].sections[j].type;
          // Store data into a step variable
          let step = {
            id: `step${j}`,
            distanceStep: response.data.routes[i].sections[j].travelSummary.length,
            durationStep: response.data.routes[i].sections[j].travelSummary.duration,
            travelTypeStep: travelTypeStep,
            departureTimeStep: response.data.routes[i].sections[j].departure.time,
            arrivalTimeStep: response.data.routes[i].sections[j].arrival.time,
            departureLocation: response.data.routes[i].sections[j].departure.place.location,
            arrivalLocation: response.data.routes[i].sections[j].arrival.place.location,
            polyline: response.data.routes[i].sections[j].polyline,
          }

          if(travelTypeStep === 'transit'){
            step.departureStopStep = response.data.routes[i].sections[j].departure.place.name;
            step.arrivalStopStep = response.data.routes[i].sections[j].arrival.place.name;
            step.companyNameStep = response.data.routes[i].sections[j].agency.name;
            step.lineNameStep = response.data.routes[i].sections[j].transport.name;
            step.vehicleCategoryStep = response.data.routes[i].sections[j].transport.category;
            step.vehicleModeStep = response.data.routes[i].sections[j].transport.mode;
            step.vehicleModeStep = response.data.routes[i].sections[j].transport.mode;
            step.headsign = response.data.routes[i].sections[j].transport.headsign;

            if(response.data.routes[i].sections[j].hasOwnProperty('fares')){
              step.fares = response.data.routes[i].sections[j].fares[0].price.value;
            }

            // ADD CARBON VALUES
            if(response.data.routes[i].sections[j].transport.mode === 'bus') {
              step.CO2 = getTranistCarbon("bus",step.distanceStep);
            } else if (response.data.routes[i].sections[j].transport.mode === 'busRapid') {
              step.CO2 = getTranistCarbon("busRapid", step.distanceStep);
            } else if(response.data.routes[i].sections[j].transport.mode === 'privatebus'){
              step.CO2 = getTranistCarbon("privatebus",step.distanceStep);
            } else if(response.data.routes[i].sections[j].transport.mode === 'cityTrain') {
              step.CO2 = getTranistCarbon("cityTrain",step.distanceStep);
            } else if(response.data.routes[i].sections[j].transport.mode === 'monorail'){
              step.CO2 = getTranistCarbon("monorail",step.distanceStep);
            } else if(response.data.routes[i].sections[j].transport.mode === 'aerial' ){
              step.CO2 = getTranistCarbon("aerial",step.distanceStep);
            } else if (response.data.routes[i].sections[j].transport.mode === 'subway'){
              step.CO2 = getTranistCarbon("subway",step.distanceStep);
            } else if(response.data.routes[i].sections[j].transport.mode === 'lightRail'){
              step.CO2 = getTranistCarbon("lightRail",step.distanceStep);
            } else if(response.data.routes[i].sections[j].transport.mode === 'inclined'){
              step.CO2 = getTranistCarbon("inclined",step.distanceStep);
            } else if (response.data.routes[i].sections[j].transport.mode === 'regionalTrain' ||
              (response.data.routes[i].sections[j].transport.mode === 'intercityTrain') ||
              (response.data.routes[i].sections[j].transport.mode === 'interRegionalTrain') ||
              (response.data.routes[i].sections[j].transport.mode === 'highSpeedTrain')) {
              let interCounrtyCache = store.getState().trainCarbonCache // will cache the response
                if(isCountrySame){
                  if(!interCounrtyCache.hasOwnProperty(originCountry)){ // not fetch again if already fetched before for the same country
                    const {data} = await axios.get(`${process.env.REACT_APP_API}/trains`, { params: { country: originCountry } });
                    store.dispatch(setTrainCarbonData({data, country:originCountry}));
                  }
                  const {infrastructure,manufacture_maintenance,operation} = store.getState().trainCarbonCache[originCountry];
                  const response = {
                    operation: operation,
                    manufacture: manufacture_maintenance,
                    infrastructure: infrastructure,
                    CO2: (operation + manufacture_maintenance + infrastructure) * (step.distanceStep / 1000)
                  }
                  step.CO2 = response; 
                } else {
                  const geoData = await axios.get(`https://reverse.geocoder.ls.hereapi.com/6.2/reversegeocode.json?prox=${step.departureLocation.lat},${step.departureLocation.lng},250&mode=retrieveAddresses&maxresults=1&gen=9&apiKey=j2YcVdp86ieJODQGLUDX2f6APJhkmjd9aG7RBZahvUQ&language=en-US`);
                  const countryName = geoData.data?.Response.View[0]?.Result[0].Location.Address.Label.trim().split(" ").pop();
                  if(!interCounrtyCache.hasOwnProperty(countryName)){ // not fetch again if already fetched before for the same country
                    const {data} = await axios.get(`${process.env.REACT_APP_API}/trains`, { params: { country: countryName } });
                    store.dispatch(setTrainCarbonData({data,country:countryName}));
                  }
                  const {operation, manufacture_maintenance, infrastructure} = store.getState().trainCarbonCache[countryName];
                  const response = {
                    operation: operation,
                    manufacture: manufacture_maintenance,
                    infrastructure: infrastructure,
                    CO2: (operation + manufacture_maintenance + infrastructure) * (step.distanceStep / 1000)
                  }
                  step.CO2 = response; 
                }
            } 
          };
          // Push the step into the StepS variable
          steps.push(step);             
        };


        // SPLIT THE TRANSIT VARIABLE INTO TWO BUS AND TRAIN VARIABLE

        // Init distance
        let distanceByBus = 0;
        let distanceByTrain = 0;

        // Calculate for each step if the bus or the train distance is longer
        for(let k=0; k < steps.length; k++) {

          if(steps[k].travelTypeStep === 'transit') {

            if(steps[k].vehicleModeStep === 'bus' || steps[k].vehicleModeStep === 'privatebus') {

              distanceByBus = distanceByBus + steps[k].distanceStep;

            } else if (steps[k].vehicleModeStep === 'busRapid') {

              distanceByBus = distanceByBus + steps[k].distanceStep;

            }

            if(steps[k].vehicleModeStep === 'cityTrain' || steps[k].vehicleModeStep === 'subway' || steps[k].vehicleModeStep === 'lightRail' || steps[k].vehicleModeStep === 'inclined' || steps[k].vehicleModeStep === 'aerial' || steps[k].vehicleModeStep === 'monorail') {
              distanceByTrain = distanceByTrain + steps[k].distanceStep;
            } else if (steps[k].vehicleModeStep === 'regionalTrain' || steps[k].vehicleModeStep === 'intercityTrain') {
              distanceByTrain = distanceByTrain + steps[k].distanceStep;
            } else if (steps[k].vehicleModeStep === 'interRegionalTrain') {
              distanceByTrain = distanceByTrain + steps[k].distanceStep;
            } else if (steps[k].vehicleModeStep === 'highSpeedTrain') {
              distanceByTrain = distanceByTrain + steps[k].distanceStep;
            }
          }
        }

        // Push the routes into NewPathTransit with type Bus or Train
        if (distanceByBus > distanceByTrain) {
          newPathTransit.push({
            id: `transitroute${i}`,
            type: 'bus',
            steps: steps,
            totalCO2: steps.map(obj => obj?.CO2?.CO2 ? obj.CO2.CO2 : 0).reduce((a,b)=>a+b,0),
          });
        } else {
          newPathTransit.push({
            id: `transitroute${i}`,
            type: 'train',
            steps: steps,
            totalCO2: steps.map(obj => obj?.CO2?.CO2 ? obj.CO2.CO2 : 0).reduce((a,b)=>a+b,0),
          });
        }
      };

      // Update the pathTransit variable
      pathTransit.routes = newPathTransit;
  
      // Count the number of Bus and Train path
      let nbBusPath = 0;
      let nbTrainPath = 0;
  
      for(let p=0; p < pathTransit.routes.length; p++) {
  
        if(pathTransit.routes[p].type === 'train') {
  
          nbTrainPath = nbTrainPath + 1;
  
        } else {
  
          nbBusPath = nbBusPath + 1;
  
        }
      }
  
      let newPathTrain = {
        id: 'train',
        routes: [],
        CO2displayed : 0
      };
  
      let newPathBus = {
        id: 'bus',
        routes: [],
        CO2displayed : 0
      };
  
      for(let k=0; k < pathTransit.routes.length; k++) {
  
        if(pathTransit.routes[k].type === 'train') {
          newPathTrain.routes.push(pathTransit.routes[k]);
        } else if( pathTransit.routes[k].type === 'bus' ) {
          newPathBus.routes.push(pathTransit.routes[k]);   
        }
  
      }
  
      newPathBus.CO2displayed = newPathBus.routes.length ? newPathBus.routes[0].totalCO2 : 0;
      newPathTrain.CO2displayed = newPathTrain.routes.length ? newPathTrain.routes[0].totalCO2 : 0;
     
      return [newPathBus, newPathTrain]    
  } catch (error) {
    // console.log(error);
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});