import React from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import { Home } from "./containers/Home";
import { MapApp } from "./containers/MapApp/MapApp";
import { Header } from "./containers/Header/Header";
import climatechange from "./containers/Pages/ClimateChange";
import transport from "./containers/Pages/Transport";
import data from "./containers/Pages/Data";
import { User } from "./containers/User/User";
import { Footer } from './containers/Footer/Footer';
import { PrivacyPolicy } from "./containers/Footer/Privacy";
import { TermsOfUse } from "./containers/Footer/TermsOfUse";
import { CookiesPolicy } from "./containers/Footer/CookiesPolicy";
import Register from "./containers/Auth/Register";
import Login from "./containers/Auth/Login";
import PrivateRoute from "./components/PrivateRoute";
import ExcludableRoute from "./components/ExcludableRoute";
import { selectIsAppLoading } from "./containers/Auth/AuthSlice";
import { useSelector } from "react-redux";
import loadinglogo from "./containers/MapApp/Results/PathList/loading.svg"
import ForgotPassword from "./containers/Auth/ForgotPassword/ForgotPassword";
import CancelForgotPassword from "./containers/Auth/CancelForgotPassword/CancelForgotPassword";

const Routes = () => {
  const isAppLoading = useSelector(selectIsAppLoading);
  // const user = useSelector(userData);
  // let pathname = window.location.pathname; 
  return (
    <div>
      <BrowserRouter>
        <Header />
        {isAppLoading ? (
          <div style={{display: "flex",height: "100vh",flexDirection: "column",alignItems: "center",justifyContent: "center"}} >
            <img id="loadingLogo" style={{ width: 100 }} src={loadinglogo} alt="loading"/>
          </div>
        ) : (
          <>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/forgotpassword/" component={ForgotPassword} />
            <Route path="/cancelforgotpassword/" component={CancelForgotPassword} />
            <Route path="/map" component={MapApp} />
            <Route path="/climate-change" component={climatechange} />
            <Route path="/transport" component={transport} />
            <Route path="/data" component={data} />
            <ExcludableRoute path="/register" component={Register} />
            <ExcludableRoute path="/login" component={Login} />
            <PrivateRoute path="/user" component={User} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/terms-of-use" component={TermsOfUse} />
            <Route path="/cookies-policy" component={CookiesPolicy} />
          </Switch>
          <Switch>
          <Route exact path="/" component={Footer} />
            <ExcludableRoute path="/register" component={Footer} />
            <ExcludableRoute path="/login" component={Footer} />
            <Route path="/data" component={Footer} />
            <PrivateRoute path="/user" component={Footer} />
            <Route path="/climate-change" component={Footer} />
            <Route path="/transport" component={Footer} />
            <Route path="/privacy-policy" component={Footer} />
            <Route path="/terms-of-use" component={Footer} />
            <Route path="/cookies-policy" component={Footer} />
            <Route path="*">
              <Redirect to='/' />
            </Route>
          </Switch>
          </>         
        )}
      </BrowserRouter>
    </div>
  );
};

export default Routes;
