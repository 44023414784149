import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Axios from '../../../api';
import { setAlert } from '../../../app/AppSlice';
import { isLoggedIn } from '../AuthSlice';
import { useTranslation } from 'react-i18next';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const CancelForgotPassword = () => {
    const { i18n } = useTranslation();
    let history = useHistory();
    const isAuthenticated = useSelector(isLoggedIn);
    const language = i18n.language === 'fr' ? 'French': 'English';
    const dispatch = useDispatch();
    let query = useQuery();
    let params = {
        token: query.get('token'),
        email: query.get('email')
    };

    useEffect(() => {
        if(params.token && params.email){
            checkToken();     
        } else {
            dispatch(setAlert({message: language === 'French' ? 'Ce lien est erroné' : 'This link is broken',showAlert: true, duration: 2000, severity:'error'}));
            history.replace('/');
        }
    }, []);

    const checkToken = async () => {
        try {
            const {data} = await Axios.post('/auth/revoke-forgotpassword', params);
            if(data.message === "resolved"){
                if (isAuthenticated) {
                    dispatch(setAlert({message: language === 'French' ? 'Merci de revenir parmis nous.' : 'Thank you for coming back to us.',showAlert: true, duration: 1500, severity:'success'}));
                } else {
                    dispatch(setAlert({message: language === 'French' ? 'Merci de revenir parmis nous. Tu peux te connecter avec ton mot de passe' :'Thank you for coming back to us. You can log in with your password.',showAlert: true, duration: 1500, severity:'success'}));
                }
                
                setTimeout(() => {
                    history.replace('/');
                }, 1000);
            }
        } catch (error) {
            console.log({error})
            dispatch(setAlert({message: language === 'French' ? 'Ce lien est erroné' : 'This link is broken',showAlert: true, duration: 2000, severity:'error'}));
            setTimeout(() => {
                history.replace('/');
            }, 1000);
        }
    }

    return (
        <div>
            
        </div>
    )
}

export default CancelForgotPassword
