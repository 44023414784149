import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectDisplayedCarPath, selectPathCar, selectPassengersNumber, decrementPassengerNumber, incrementPassengerNumber, switchToElectric, selectElectric, selectelectricCar, selectconventionalCar  } from '../Car/PathCarSlice';

import { secondsToHm } from '../Util/secondsToHm';

import SwitchToElectricIcon from './switchElectric.png';
import { listCarIcons } from './Util/listIcons';

import {CO2andpercentCO2} from '../Util/CO2andpercentCO2'

import { useTranslation } from 'react-i18next';

// IMPORT SELECTED PATH
import { selectDisplayedPath, setActivePath, setDisplayedPath, setHoveredPath, } from '../PathListSlice';


export const PathCarContainer = React.forwardRef(({onClick}, ref) => {

    const dispatch = useDispatch();

    // IMPORT THE STATES
    const SelectedPath = useSelector(selectDisplayedPath);
    const Path = useSelector(selectPathCar);
    const PassengersNumber = useSelector(selectPassengersNumber);
    const ElectricChosen = useSelector(selectElectric);
    const ElectricCar = useSelector(selectelectricCar);
    const ConventionalCar = useSelector(selectconventionalCar);
    const DisplayedCarPath = useSelector(selectDisplayedCarPath)
    const { t } = useTranslation();

    function listAllIcons(routes) {

        let AllIcons = [];

        for(let i=0; i < routes.steps.length; i++) {
            AllIcons.push(listCarIcons(routes.steps[i], i));
        }

        return AllIcons
    };

    return (
          
        <div className="PathAndAlternatives" ref={ref} >

            {Path.routes.length ?
            <>
            <div className={SelectedPath === 'car' ? 'ActivePath' : 'Path'}  >

                <div className="PathMain" 
                    onMouseEnter={() => { if(SelectedPath !== 'car') { dispatch(setHoveredPath(Path.routes[DisplayedCarPath]))}}}
                    onMouseLeave={() => { dispatch(setHoveredPath('')) }}
                    onClick={() => {
                        if(SelectedPath !== 'car'){
                            dispatch(setDisplayedPath("car"));
                            dispatch(setActivePath(Path.routes[DisplayedCarPath]));
                        }   
                    }}>
                    <div className="TransportType">

                        <h2>{ElectricChosen ? t("Electric car") : t('car').charAt(0).toUpperCase() + t('car').slice(1)}</h2>

                        <div className='FrameForMobile'>
                            {ElectricChosen ?
                                <span style={{marginRight:'10px', fontWeight:'500'}}>{Math.round(((Path.routes[0].totalCarConsumption * ElectricCar.electricity_price.price)/PassengersNumber))}€ {t("electricity")}</span>
                            :
                                <span style={{marginRight:'10px', fontWeight:'500'}}>{ Math.round(((Path.routes[0].distanceByCar / 1000 * 0.0589285714285714 * ConventionalCar.gazoline_price.price)/PassengersNumber))}€ {t('petrol')}</span>   
                            }
                        </div>  
                    </div>
                    

                    <div className="PathInformation">     

                        <div className="IconAndTypeAndCO2">

                            <div className="IconAndType" >

                            <div className="RouteType" >{DisplayedCarPath === 0 ? t('Best route') : `${t('Alternative')} ${DisplayedCarPath}`}</div>

                            <div className='switchToElectricCountainer'>
                                        
                                        <label className="switchToElectric">
                                            <input id='electricCarCheck' type="checkbox" 
                                            onClick = {() => dispatch((switchToElectric())) }                              
                                            />
                                            <span className="slider round" />
                                        </label>
                
                                        <img alt="currenticon" id='currentIcon' src={SwitchToElectricIcon} />
                                        <span style={{top: '50%', marginTop: '-4.5px'}}>{t("Switch to electric")}</span>                                           
                
                            </div>

                                <div className="RouteType" >{t('Best route')}</div>

                                <div className="Icon" >
                                    {listAllIcons(Path.routes[DisplayedCarPath])}
                                </div>                                  

                            </div>

                            {<CO2andpercentCO2 CO2={Path.CO2displayed} />}


                        </div>    

                        <div className="DurationContainer">

                        <p className="Duration">{secondsToHm(Path.routes[0].totalDuration, {hour: t('hours'), hours: t('hours'), minute: t('minute'), minutes: t("minutes")})}</p>

                        {
                        ElectricChosen && Math.floor((Path.routes[0].distanceByCar/1000)/(500*0.8)) > 0 ? 
                            <p className="DurationBatteryRefueling">(+{Math.floor((Path.routes[0].distanceByCar/1000)/(500*0.8))*35} {t("minutes battery charging")})</p> 
                            : null
                        }

{
                        ElectricChosen === false && Math.floor(((Path.routes[0].distanceByCar/1000)/(500*0.8))) > 0 ? 
                            <p className="DurationBatteryRefueling">(+{Math.floor(((Path.routes[0].distanceByCar/1000)/(500*0.8)))*5} {t("minutes refueling")})</p> 
                            : null
                        }


                        </div>

                        <div className="NumberOfPassengersHandler" style={{marginTop:'10px'}}>

                            <h4 style={{margin:'0'}}>{t("Number of travelers")}</h4>    
                            <button className="PlusButton" onClick={() => {dispatch(decrementPassengerNumber())}} >-</button>
                            <div className="NumberOfPassengers">{PassengersNumber}</div>
                            <button className="PlusButton" onClick={() => {dispatch(incrementPassengerNumber())}} >+</button>  
                            
                        </div>

                    </div>    

                </div>


                    <div className="PathSecond">

                        {ElectricChosen ?

                        <div className="Price">
                            
                            <div className='TotalPrice'>
                                { Math.round(((Path.routes[0].totalCarConsumption * ElectricCar.electricity_price.price)/PassengersNumber))}€ {t('electricity')}
                            </div>
                            
                        </div>
                        :
                        <div className="Price">
                            <div className='TotalPrice'>
                                { Math.round(((Path.routes[0].distanceByCar / 1000 * 0.0589285714285714 * ConventionalCar.gazoline_price.price)/PassengersNumber))}€ {t('petrol')}
                            </div>
                        </div>
                        }

                        <div className='SeeOptionsHandler' onClick={onClick}>
                            {t('See options')}
                        </div>
    
                    </div>

            </div>
            </>
            
        : null}



        </div>
    )
});
