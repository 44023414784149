import { createSlice } from "@reduxjs/toolkit";

export const LanguageSlice = createSlice({
  name: "Language",
  initialState: {
    lang: 'English'
  },
  reducers: {
    setLanguage(state, action) {
      return {
        ...state,
        lang: action.payload,
      };
    },
  },
});

export const { setLanguage } = LanguageSlice.actions;

export const selectLanguage = (state) => state.Language.lang;

export default LanguageSlice.reducer;
