import React, { useEffect, useMemo, useRef, useState } from "react";
import Select, { components } from "react-select";
import { useLocation } from 'react-router-dom';
import VehicalImage from "../../../VehicalImage";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@material-ui/core";

const { Option } = components;


const RegularViewInput = ({
  isLoading = false,
  onFocus = () => { },
  data = [],
  onInputChange = () => { },
  onSelect = () => { },
  placeholder = "",
  value,
  onBlur = () => { }
}) => {
  let location = useLocation();
  const matches = useMediaQuery("(max-width: 600px)");
  const { i18n } = useTranslation();
  const language = i18n.language;
  
  const [inputValue, _inputValue] = useState('');

  const selectRef = useRef(null);
  const isTyping = useRef(true);
  const isTypingBackSpace = useRef(false);
  const autoCompleteRefernce = useRef(0);

  useEffect(() => {
    if (selectRef.current) {
      if (!value) {
        selectRef.current.select.clearValue()
      } 
    }
  }, [selectRef, value])


  const formatedData = useMemo(() => {
    if (data.some((e) => e.resultType === "address")) {
      const addressMaxCount = 3;
      const address = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].resultType === "address") {
          if (address.length <= addressMaxCount - 1) {
            address.push(data[i]);
          } else {
            break;
          }
        }
      }
      const removeAddress = data.filter(e => e.resultType !== 'address');
      const newArray = [
        {
          label: 'Addresses',
          options: address,
        },
        {
          label: 'Places',
          options: removeAddress,
        },
      ]
      return newArray
    } else {
      return data
    }
  }, [data]);

  // select text when option is being focused using arrow keys
  useEffect(() => {
    if(!isTyping.current) {
      selectRef.current.select.inputRef.select()
    } else {
      if(inputValue && autoCompleteRefernce.current && !isTypingBackSpace.current) {
        selectRef.current.select.inputRef.setSelectionRange(autoCompleteRefernce.current, inputValue.length)
      }
    }
  }, [inputValue]);
  useEffect(() => {
    const controllerClick = (e) => {
      e.stopPropagation()
      if(value) {
        isTyping.current = false
        _inputValue(value.label);
      }
    }
    let controller = undefined;
    if(selectRef.current && value) {
      controller = selectRef.current.select.controlRef;
      controller.addEventListener("click", controllerClick);
    }
    return () => {
      if(controller) {
        controller.removeEventListener("click", controllerClick);
      }
    }
  },[selectRef, value]);


  return (
    <Select
      openMenuOnClick={false}
      ref={selectRef}
      noOptionsMessage={() => {
        return null
      }}
      onBlur={() => {
        onBlur();
        isTyping.current = true;
        _inputValue('')
        autoCompleteRefernce.current = 0;
      }}
      isLoading={isLoading}
      value={value}
      inputValue={(() => {
        if(value && !isTyping.current) {
          return inputValue
        } else if(autoCompleteRefernce.current){
          return inputValue
        }
        return value ? undefined : inputValue
      })()}
      onFocus={onFocus}
      filterOption={null}
      onKeyDown={(e) => {
        if(e.key === "Backspace"){
          isTypingBackSpace.current = true;
        } else {
          isTypingBackSpace.current = false;
        }
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
          isTyping.current = false;
        } else {
          isTyping.current = true;
        }
      }}
      onInputChange={(e) => {
        const inputValue = e
        _inputValue(inputValue)
        if (inputValue && inputValue.length > 2) {
          onInputChange(inputValue, language);
        }
        return inputValue
      }}
      options={formatedData}
      onChange={(e) => {
        onSelect(e);
        if (selectRef.current) {
          selectRef.current.select.blur();
        }
      }}
      placeholder={placeholder}
      theme={(theme) => ({
        ...theme,
        spacing: {
          ...theme.spacing,
          controlHeight: location.pathname === '/map' ? 33 : 40,
        },
        colors: {
          ...theme.colors,
          primary: "#592689",
          primary75: "rgb(128 128 128 / 75%)",
          primary50: "rgb(128 128 128 / 50%)",
          primary25: "rgb(128 128 128 / 25%)",
          neutral20: "grey",
        },
      })}
      styles={{
        container: (base) => ({
          ...base,
          flex: 1,
        }),
        valueContainer: (base) => ({
          ...base,
          fontWeight: 600,
        }),
        singleValue: (base) => ({
          ...base,
          fontSize: location.pathname === '/map' ? "13px" : "0.9rem",
          paddingRight: 4
        }),
        placeholder: (base) => {
          return {
            ...base,
            fontSize: "0.9rem",
            left: matches ? '50%' : base.left,
            transform: matches ? 'translate(-50%, -50%)' : base.transform
          };
        },
        control: (base, { isFocused }) => ({
          ...base,
          cursor: 'text',
          boxShadow: isFocused
            ? "1px -2px 10px -1px rgb(145 145 145 / 43%)"
            : null,
        }),
        input: (base) => ({
          ...base,
          fontSize: location.pathname === '/map' ? "13px" : "0.9rem",
          fontWeight: 600,
          color: "grey",
          borderWidth: 0,
          width: '100% !important',
          div:{
            display: 'block !important'
          },
          input: {
            boxShadow: "unset !important",
            textOverflow: "ellipsis",
            width: '100% !important',
          },
        }),
        noOptionsMessage: (base) => ({
          ...base,
          fontSize: "14px",
          fontWeight: 600,
          padding: "4px 8px",
        }),
        menuList: (base) => ({
          ...base,
          fontSize: "13px",
        }),
        groupHeading: (base) => ({
          ...base,
          textAlign: 'left',
          textAlignLast: 'left'
        }),
        option: (base, state) => {
          return ({
            ...base,
            padding: "8px 8px",
            display: "flex",
            alignItems: "center",
            fontSize: state.isSelected ? 13 : base.base,
            fontWeight: state.isSelected ? 700 : null,
            color: state.isSelected ? "hsl(0, 0%, 0%)" : base.color,
            cursor: "pointer",
            backgroundColor: state.isSelected
              ? "rgb(128 128 128 / 50%)"
              : base.backgroundColor,
            ":active": {
              ...base[":active"],
              color: state.isSelected ? '#fff' : base.color
            },
            'span': {
              textAlign: 'left'
            }
          })
        },
      }}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Option: (props) => {
          if (props.isFocused && !props.isSelected && !isTyping.current) {
            _inputValue(props.children);
          }
          if(props.isFocused && isTyping.current) {
            if(!isTypingBackSpace.current && inputValue && props.children && (inputValue.toLowerCase() !== props.children.toLowerCase())) {
              const temp = props.children.slice(0, inputValue.length)
              if(temp.toLowerCase() === inputValue.toLowerCase()){
                if(`${inputValue}${props.children.slice(inputValue.length)}`.toLowerCase() === props.children.toLowerCase()){
                  autoCompleteRefernce.current = inputValue.length;
                  _inputValue(inputValue + props.children.slice(inputValue.length));
                }
              } else {
                autoCompleteRefernce.current = 0;
              }
            } 
          }
          return (
            <Option {...props}>
              <div
                style={{
                  objectFit: "contain",
                  display: "inline-block",
                }}
              >
                <VehicalImage
                  type={props.data.category}
                  style={{ margin: "0 5px 0 0px" }}
                  width={20}
                />
              </div>
              <span>{props.children}</span>
            </Option>
          )
        },
      }}
    />
  );
};

export default RegularViewInput;
