import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Styles from "./PrimaryButton.module.css"
import ThreeDotLoader from '../Loaders/ThreeDotLoader'

function PrimaryButtonAsync({children, onClick, disabled, style, className}) {
    const [isLoading, _isLoading] = useState(false);
    return (
    <button style={style} disabled={disabled} type="button" className={`${Styles.btn} ${className}`} onClick={ async ()=>{
        if(!isLoading){
            _isLoading(true);
            try {
              await onClick();  
            } catch (error) {
                _isLoading(false);    
            } finally{
                _isLoading(false);
            }
        }
    }}>
        {isLoading && <div className={Styles.loader} style={{visibility: isLoading ? "visible" : "hidden"}}><ThreeDotLoader /></div>}
        <span style={{visibility: isLoading ? "hidden" : "visible"}}>{children}</span>
     </button>
    )
}

PrimaryButtonAsync.defaultProps = {
    style: {},
    className: "",
    isLoading: false,
    onClick: ()=>{}
}

PrimaryButtonAsync.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    style: PropTypes.object,
    className: PropTypes.string
}

export default PrimaryButtonAsync

