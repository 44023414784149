export const calculateDuration = ((path, contentIn) => {

    let timeDeparture = new Date (path.steps[0]?.departureTimeStep);
    let timeArrival = new Date (path.steps[path.steps.length - 1]?.departureTimeStep);

    let diff = (timeArrival.getTime() - timeDeparture.getTime()) / 1000;

    diff /= (60 * 60);
    let hours = Math.abs(Math.trunc(diff));
    let rest = ((timeArrival.getTime() - timeDeparture.getTime()) / 1000) - (hours * 60 * 60);

    rest /= 60;

    if(Math.abs(Math.trunc(diff)) === 0) {
        return `${Math.abs(Math.trunc(rest))} ${contentIn.minutes}`;
    } else {
        return `${Math.abs(Math.trunc(diff))} ${contentIn.hours} ${Math.abs(Math.trunc(rest))} ${contentIn.minutes}`;
    }

    
})