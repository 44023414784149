import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import PrimaryButton from "../../../../components/PrimaryButton/PrimaryButtonAsync";
import {
    Slider,
    Select,
    MenuItem,
    TextField,
    Input,
    Box,
} from "@material-ui/core";
import frLocale from "date-fns/locale/fr";
//
import DateFnsAdapter from "@material-ui/lab-next/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab-next/LocalizationProvider";
//
import DateFnsUtils from "@date-io/date-fns";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import MobileDateRangePicker from "@material-ui/lab-next/MobileDateRangePicker";
import { setPaths } from "../../../../containers/User/UserPathSlice";
import { selectSelectedFromLocation } from "../../../SearchBar/From/FromAutosuggestionsSlice";
import { selectSelectedToLocation } from "../../../SearchBar/To/ToAutosuggestionsSlice";

import { setPathBus } from "../../../MapApp/Results/PathList/Transit/Bus/PathBusSlice";
import { setPathTrain } from "../../../MapApp/Results/PathList/Transit/Train/PathTrainSlice";
import { setPathPlane } from "../../../MapApp/Results/PathList/Plane/PathPlaneSlice";
import { setActivePath, setDisplayedPath, setHoveredPath } from "../../../MapApp/Results/PathList/PathListSlice";
import { askCarPath } from "../../../MapApp/Results/PathList/Car/Util/askCarPath";
import { askBicyclePath } from "../../../MapApp/Results/PathList/Bicycle/Util/askBicyclePath";
import { askElectricityCO2Factor } from "../../../MapApp/Results/PathList/Util/askElectricityCO2Factor";
import {
    // selectAddPathToBallanceLoading,
    setAddPathToBallance,
} from "../../../MapApp/AddPathToBallance/AddPathToBallanceSlice";
import { askElectricityPrice } from "../../../MapApp/Results/PathList/Car/Util/askElectricityPrice";
import { askGazolinePrice } from "../../../MapApp/Results/PathList/Car/Util/askGazolinePrice";
import { askTransitPath } from "../../../MapApp/Results/PathList/Transit/Util/askTransitPath";
import { askPlanePath } from "../../../MapApp/Results/PathList/Plane/Util/askPlanePath";
import {
    setPathCar,
    resetPassengerNumber,
    resetElectric,
} from "../../../MapApp/Results/PathList/Car/PathCarSlice";
import {
    selectDate,
    setDate,
    setTimesPerWeek,
    selectTimesPerWeek,
    selectRegularDate,
    setRegularDate,
} from "../../../SearchBar/DateSlice";
import { FromInput } from "../../../SearchBar/From/FromInput";
import { ToInput } from "../../../SearchBar/To/ToInput";
import { setAlert } from "../../../../app/AppSlice";
import CustomModal from "../../../../components/Modal/CustomModal";
import { getDistanceFromLatLonInKm } from '../../../../utils/calc';
import styles from "./RegularJourneyModal.module.css";
import { useTranslation } from "react-i18next";
import InverseArrowButton from "../../../../components/InverseArrowButton";

const slidertext = (value) => {
    return `${value}`;
};


function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}


const RegularJourneyModal = ({ show, onClose }) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [openYearPicker, _openYearPicker] = React.useState(false);
    const [journeyName, _journeyName] = React.useState('');
    const from = useSelector(selectSelectedFromLocation);
    const to = useSelector(selectSelectedToLocation);
    const selectedDate = new Date(useSelector(selectDate));
    const selectedRegularDate = useSelector(selectRegularDate);
    const selectedTimesPerWeek = useSelector(selectTimesPerWeek)
    const endDate = React.useMemo(() => new Date(selectedRegularDate.endDate), [selectedRegularDate.endDate]);
    const tempStartDate = selectedRegularDate.startDate;
    const startDate = React.useMemo(() => new Date(selectedRegularDate.startDate), [selectedRegularDate.startDate]);
    const history = useHistory();

    let months = [
        {
            value: 0,
            label: t('JAN')
        },
        {
            value: 1,
            label: t('FEB')
        },
        {
            value: 2,
            label: t('MAR')
        },
        {
            value: 3,
            label: t('APR')
        },
        {
            value: 4,
            label: t('MAY')
        },
        {
            value: 5,
            label: t('JUN')
        },
        {
            value: 6,
            label: t('JUL')
        },
        {
            value: 7,
            label: t('AUG')
        },
        {
            value: 8,
            label: t('SEPT')
        },
        {
            value: 9,
            label: t('OCT')
        },
        {
            value: 10,
            label: t('NOV')
        },
        {
            value: 11,
            label: t('DEC')
        },
    ];

    const valueLabelFormat = (value) => {
        const index = months.findIndex((month) => month.value === value);
        return `${months[index].label}`;
    };

    React.useEffect(() => {
        return () => {
            dispatch(setRegularDate({
                startDate: new Date(),
                endDate: new Date().setMonth(new Date().getMonth() + 1),
            }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    React.useEffect(() => {
        if (endDate < startDate) {
            dispatch(
                setRegularDate({
                    ...selectedRegularDate,
                    endDate: startDate,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endDate < startDate]);

    React.useEffect(() => {
        dispatch(setDate(tempStartDate));
    }, [dispatch, tempStartDate]);

    const handleYearChange = (newDate) => {
        if (isValidDate(newDate)) {
            dispatch(
                setRegularDate({
                    ...selectedRegularDate,
                    startDate: startDate.setFullYear(newDate.getFullYear()),
                    endDate: endDate.setFullYear(newDate.getFullYear()),
                })
            );
        }
    };

    const getPossiblePastDate = React.useMemo(() => {
        if (show) {
            const currentTime = moment().startOf("day").hour(12);
            const tempDate = moment(startDate);
            const tempSelectedDate = moment(
                `${tempDate.year()}-${tempDate.month() + 1}-${tempDate.date()}`
            );
            if (currentTime.diff(tempSelectedDate, "days") < 7) {
                return tempSelectedDate.toDate().toISOString();
            } else {
                for (let index = 0; index < 7; index++) {
                    const previousPossibleDay = moment().subtract(index, "days");
                    if (previousPossibleDay.day() === tempSelectedDate.day()) {
                        return previousPossibleDay.toDate().toISOString();
                    }
                }
                return currentTime.toDate().toISOString();
            }
        }
    }, [startDate, show]);

    const getPossibleFutureDate = React.useMemo(() => {
        if (show) {
            const tempDate = moment(startDate);
            const tempSelectedDate = moment(
                `${tempDate.year()}-${tempDate.month() + 1}-${tempDate.date()}`
            );
            for (let index = 1; index < 8; index++) {
                const upcomingPossibleDay = moment().add(index, "days");
                if (upcomingPossibleDay.day() === tempSelectedDate.day()) {
                    return upcomingPossibleDay.toDate().toISOString();
                }
            }
            return moment().toDate().toISOString();
        }
    }, [startDate, show]);

    return (
        <>
            {show ? (
                <CustomModal
                    className={styles.regularModal}
                    onClose={() => {
                        onClose(false);
                    }}
                >
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === "fr" ? frLocale : null}>
                        <div className={styles.container}>
                            <h2 className={styles.title}>{t('Add your regular journey')}</h2>
                            <div className={styles["input-container"]}>
                                <div className={styles["year-picker"]}>
                                    <label>{t("Year")}</label>
                                    <KeyboardDatePicker
                                        InputProps={{ readOnly: true }}
                                        margin="normal"
                                        id="date-picker-dialog"
                                        openTo="year"
                                        views={["year"]}
                                        value={startDate}
                                        open={openYearPicker}
                                        onClick={() => {
                                            _openYearPicker(true);
                                        }}
                                        onClose={() => {
                                            _openYearPicker(false);
                                        }}
                                        onChange={handleYearChange}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />

                                </div>
                                <div className={styles["month-slider"]}>
                                    <label>{t("Month")}</label>
                                    <div className={styles["slider"]}>
                                        <Slider
                                            onChange={(_e, value) => {
                                                dispatch(
                                                    setRegularDate({
                                                        ...selectedRegularDate,
                                                        startDate: startDate.setMonth(value[0]),
                                                        endDate: endDate.setMonth(value[1]),
                                                    })
                                                );
                                            }}
                                            valueLabelFormat={valueLabelFormat}
                                            getAriaValueText={slidertext}
                                            valueLabelDisplay="auto"
                                            value={[startDate.getMonth(), endDate.getMonth()]}
                                            defaultValue={[startDate.getMonth(), endDate.getMonth()]}
                                            aria-labelledby="range-slider"
                                            min={0}
                                            max={11}
                                            step={null}
                                            marks={months}
                                        />
                                    </div>
                                </div>
                                <LocalizationProvider dateAdapter={DateFnsAdapter} locale={i18n.language === "fr" ? frLocale : null}>
                                    <div className={styles["year-picker"]}>
                                        <label>{t("Date")}</label>
                                        <MobileDateRangePicker
                                            allowSameDateSelection={true}
                                            value={[
                                                startDate,
                                                endDate
                                                    ? endDate
                                                    : new Date(
                                                        moment({
                                                            month: endDate.getMonth(),
                                                            year: startDate.getFullYear(),
                                                        })
                                                            .clone()
                                                            .endOf("month")
                                                            .format("YYYY-MM-DD hh:mm")
                                                    ),
                                            ]}
                                            onChange={(newValue) => {
                                                if (
                                                    isValidDate(newValue[0]) &&
                                                    isValidDate(newValue[1])
                                                ) {
                                                    dispatch(
                                                        setRegularDate({
                                                            ...selectedRegularDate,
                                                            startDate: newValue[0],
                                                            endDate: newValue[1],
                                                        })
                                                    );
                                                }
                                            }}
                                            minDate={
                                                    moment({
                                                        month: 0,
                                                        year: startDate.getFullYear(),
                                                    })
                                                        .clone()
                                                        .startOf("month").toDate()
                                            }
                                            maxDate={
                                                moment({
                                                    month: 11,
                                                    year: endDate.getFullYear(),
                                                })
                                                    .clone()
                                                    .endOf("month").toDate()
                                            }
                                            startText={""}
                                            endText={""}
                                            // allowSameDateSelection={false}
                                            showToolbar={false}
                                            renderInput={(startProps, endProps) => {
                                                startProps.helperText = "";
                                                endProps.helperText = "";
                                                startProps.inputProps.placeholder = "start date";
                                                endProps.inputProps.placeholder = "end date";
                                                return (
                                                    <>
                                                        <TextField
                                                            {...startProps}
                                                            InputProps={{ readOnly: true }}
                                                            variant="standard"
                                                        />
                                                        <Box style={{ margin: "0 20px" }} sx={{ mx: 2 }}>
                                                            {t('to')}
                                                        </Box>
                                                        <TextField
                                                            {...endProps}
                                                            InputProps={{ readOnly: true }}
                                                            variant="standard"
                                                        />
                                                    </>
                                                );
                                            }}
                                        />
                                    </div>
                                </LocalizationProvider>
                                <div className={styles["input-controller"]}>
                                    <label>{t("Times per week")}</label>
                                    <Select
                                        className={styles["input-select"]}
                                        labelId="demo-controlled-open-select-label"
                                        id="demo-controlled-open-select"
                                        value={selectedTimesPerWeek}
                                        onChange={(e) => {
                                            dispatch(setTimesPerWeek(e.target.value))
                                        }}
                                    >
                                        <MenuItem value={1}>{t("one")}</MenuItem>
                                        <MenuItem value={2}>{t("two")}</MenuItem>
                                        <MenuItem value={3}>{t("three")}</MenuItem>
                                        <MenuItem value={4}>{t("four")}</MenuItem>
                                        <MenuItem value={5}>{t("five")}</MenuItem>
                                        <MenuItem value={6}>{t("six")}</MenuItem>
                                        <MenuItem value={7}>{t("seven")}</MenuItem>
                                    </Select>
                                </div>
                                <div className={styles["input-controller"]}>
                                    <label>{t("Regular journey name")}</label>
                                    <Input
                                        error={(journeyName.length > 0) && (journeyName.trim().length < 3)}
                                        onChange={(e) => {
                                            _journeyName(e.target.value);
                                        }}
                                        value={journeyName}
                                        className={styles["input-select"]}
                                        placeholder={t("My regular journey")}
                                    />
                                </div>
                                <div className={styles["fromToInput"]}>
                                    <FromInput />
                                    <InverseArrowButton />
                                    <ToInput />
                                </div>
                                <div className={`SearchBar-submit-container ${styles['bottom-btn']}`}>
                                    <PrimaryButton
                                        onClick={() => {
                                            if (
                                                from &&
                                                to &&
                                                journeyName.trim().length > 2
                                            ) {
                                                dispatch(setActivePath(''));
                                                dispatch(setHoveredPath(''));
                                                dispatch(setPaths([]));
                                                dispatch(setAddPathToBallance(false));
                                                dispatch(setDisplayedPath(""));
                                                dispatch(setPathCar({ id: "car", routes: [], CO2displayed: 0 }));
                                                dispatch(resetElectric());
                                                dispatch(resetPassengerNumber());
                                                dispatch(setPathBus({ id: "bus", routes: [], CO2displayed: 0 }));
                                                dispatch(setPathTrain({ id: "train", routes: [], CO2displayed: 0 }));
                                                dispatch(
                                                    askCarPath([
                                                        from,
                                                        to,
                                                        new Date(selectedDate).toISOString(),
                                                    ])
                                                ).then(() => {
                                                    dispatch(askElectricityPrice(from.country))
                                                        .then(dispatch(askElectricityCO2Factor(from.country)))
                                                        .then(dispatch(askGazolinePrice(from.country)));
                                                });
                                                if (
                                                    getDistanceFromLatLonInKm(
                                                        from.position.lat,
                                                        from.position.lng,
                                                        to.position.lat,
                                                        to.position.lng
                                                    ) < 70
                                                ) {
                                                    dispatch(
                                                        askBicyclePath([
                                                            from,
                                                            to,
                                                            new Date(selectedDate).toISOString(),
                                                        ])
                                                    );
                                                }
                                                if (
                                                    getDistanceFromLatLonInKm(
                                                        from.position.lat,
                                                        from.position.lng,
                                                        to.position.lat,
                                                        to.position.lng
                                                    ) < 500
                                                ) {
                                                    dispatch(setPathPlane(["CO2", 0.258]));
                                                } else if (
                                                    getDistanceFromLatLonInKm(
                                                        from.position.lat,
                                                        from.position.lng,
                                                        to.position.lat,
                                                        to.position.lng
                                                    ) < 5000
                                                ) {
                                                    dispatch(setPathPlane(["CO2", 0.187]));
                                                } else {
                                                    dispatch(setPathPlane(["CO2", 0.152]));
                                                }
                                                dispatch(
                                                    askTransitPath([
                                                        from,
                                                        to,
                                                        getPossiblePastDate,
                                                    ])
                                                );
                                                dispatch(
                                                    askPlanePath([
                                                        from,
                                                        to,
                                                        getPossibleFutureDate,
                                                        getDistanceFromLatLonInKm(
                                                            from.position.lat,
                                                            from.position.lng,
                                                            to.position.lat,
                                                            to.position.lng
                                                        ),
                                                    ])
                                                );
                                                history.push("/map", { journeyType: "REGULAR_JOURNEY", journeyName: journeyName.trim() });
                                            } else if (journeyName.trim().length < 3) {
                                                dispatch(
                                                    setAlert({
                                                        message:
                                                            t("Please enter journey name"),
                                                        showAlert: true,
                                                        duration: 6000,
                                                        severity: "error",
                                                    })
                                                );
                                                return;
                                            } else if (!from && !to) {
                                                dispatch(setAlert({ message: t("We haven't found your departure and arrival points"), showAlert: true, duration: 6000, severity: 'error' }));
                                            } else if (!from && to) {
                                                dispatch(setAlert({ message: t("We haven't found your departure point"), showAlert: true, duration: 6000, severity: 'error' }));
                                            } else {
                                                dispatch(setAlert({ message: t("We haven't found your arrival point"), showAlert: true, duration: 6000, severity: 'error' }));
                                            }
                                        }}
                                        className="SearchBar-submit">
                                        <span role="button" href="#" style={{ textDecoration: "none" }}>
                                            {t('LetsGo')}
                                        </span>
                                    </PrimaryButton>
                                </div>
                            </div>
                        </div>
                    </MuiPickersUtilsProvider>
                </CustomModal>
            ) : null}
        </>
    );
};

RegularJourneyModal.prototype = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

RegularJourneyModal.defaultProps = {
    show: false,
    onClose: () => { },
};

export default RegularJourneyModal;
