import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ticket from '../ticket.png';
import PlaneIcon from '../Util/vehicles_icons/plane.png'

import { selectPathPlane, selectPlaneCO2 } from './PathPlaneSlice';
// import { selectDate } from '../../../../SearchBar/DateSlice';
import {CO2andpercentCO2} from '../Util/CO2andpercentCO2'

import { useTranslation } from 'react-i18next';

// IMPORT SELECTED PATH
import { selectDisplayedPath, setActivePath, setDisplayedPath, setHoveredPath } from '../PathListSlice';

export const PathPlaneContainer = React.forwardRef(({onClick}, ref) => {

    const dispatch = useDispatch();

    // IMPORT THE STATES
    const SelectedPath = useSelector(selectDisplayedPath);
    const Path = useSelector(selectPathPlane);
    const PlaneCO2 = useSelector(selectPlaneCO2);
    const { t } = useTranslation();

    return (
          
        <div className="PathAndAlternatives" ref={ref}>

            {Path.routes.length ? 
            <div className={SelectedPath === 'plane' ? 'ActivePath' : 'Path'}  >

                <div className="PathMain" style={{paddingBottom:"35px"}}
                    onMouseEnter={() => { if(SelectedPath !== 'plane') {dispatch(setHoveredPath({type: 'plane'}))} }}
                    onMouseLeave={() => { dispatch(setHoveredPath('')) }}
                    onClick={() => { if(SelectedPath !== 'plane') {dispatch(setDisplayedPath('plane')); dispatch(setActivePath({type: 'plane'}))} }}  
                    >

                    <div className="TransportType">
                        <h2>{t('plane').charAt(0).toUpperCase() + t('plane').slice(1)}</h2>

                        <div className='FrameForMobile'>
                        <span style={{marginRight:'10px', fontWeight:'500'}}>From {Path.routes[0].Price}€</span> 
                        </div>

                    </div>
                    

                    <div className="PathInformation">     

                        <div className="IconAndTypeAndCO2">

                            <div className="IconAndType" >

                                <div className="RouteType" >{t('Best route')}</div>

                                    <div className="Icon" >
                                        <img className='PlaneIcon' src={PlaneIcon} alt='PaneIcon' />
                                    </div>                                  

                                </div>

                            <CO2andpercentCO2 CO2={(Path.routes[0].distance * (PlaneCO2.infrastructure + PlaneCO2.manufacture + PlaneCO2.operation))} />

                        </div>    

                        <div className="DurationContainer">
                        </div>

                    </div>    

                    {/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ?
                    <div className='SeeOptionsHandlerMobile' ref={ref} onClick={onClick}>
                        <h4>{t('See options')}</h4>
                    </div>
                    : null}

                </div>


                    <div className="PathSecond">

                    <div className="Price">

                        <div className='TotalPrice'>
                            <img src={ticket} alt="ticket" />
                            <p>{t('From price')} {Path.routes[0].Price}€</p> 
                        </div>
                   
                    </div>

                    <div className='SeeOptionsHandler' onClick={onClick}>
                            {t('See options')}
                    </div>
                        
                    </div>

            </div>
            : null}

        </div>
    )
});
