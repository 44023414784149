import React, { useState } from 'react'
import '../../style/Register.css';
import Axios from '../../api/index';

import checkicon from "../Auth/check.png";
import { setAlert } from '../../app/AppSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';


export default function ChangePassword(props) {
  const { t } = useTranslation();
  const [error, setError] = useState(false)
  const [state, setState] = useState({
    oldPass: "",
    newPass: "",
    confirmPass: "",
  })
  const dispatch = useDispatch();
  const handleOnChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (state.newPass === state.confirmPass) {
      let data = { ...state };
      try {
        await Axios.post('/auth/change-password', data)
        dispatch(setAlert({message: t('Your password has been updated'), showAlert: true, duration: 6000, severity:'success'}));
        // props.history.replace('/')
      } catch (error) {
        if(error.response){
          setError(error.response.data.message)
        }
      }
    } else {
      setError(t("Password and confirm password fields don't match"))
    }

  }

  function check () {

    if (state.newPass === state.confirmPass && state.confirmPass !== null && state.newPass !== null && state.newPass !== "" && state.confirmPass !== "" ) {
      return ('Matching')
    } else if (state.confirmPass !== null || state.newPass !== null) {
      return ('Not Macthing')
    }
  }

  function scorePassword(pass) {
    var score = 0;
    if (!pass)
        return score;

    // award every unique letter until 5 repetitions
    var letters = {};
    for (var i=0; i<pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 5.0 / letters[pass[i]];
    }

    // bonus points for mixing it up
    var variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass),
    }

    var variationCount = 0;
    for (var check in variations) {
        variationCount += (variations[check] === true) ? 1 : 0;
    }
    score += (variationCount - 1) * 10;

    return parseInt(score);
  }

  function checkPassStrength() {
    var score = scorePassword(state.newPass);
    if (score > 80) {
      if (document.getElementById("PassStrenghtLittleContainer3") !== null) {
        document.getElementById("PassStrenghtLittleContainer3").style.background="#2C2D40";
      }
      if (document.getElementById("PassStrenghtLittleContainer1") !== null) {
        document.getElementById("PassStrenghtLittleContainer1").style.background="#592689";
      }
      if (document.getElementById("PassStrenghtLittleContainer2") !== null) {
        document.getElementById("PassStrenghtLittleContainer2").style.background="#699D51";
      }
    } else if (score > 60) {
      if (document.getElementById("PassStrenghtLittleContainer2") !== null) {
        document.getElementById("PassStrenghtLittleContainer2").style.background="#699D51";
      }
      if (document.getElementById("PassStrenghtLittleContainer1") !== null) {
        document.getElementById("PassStrenghtLittleContainer1").style.background="#592689";
      }
      if (document.getElementById("PassStrenghtLittleContainer3") !== null) {
        document.getElementById("PassStrenghtLittleContainer3").style.background="none";
      }
    } else if (score >= 30) {
      if (document.getElementById("PassStrenghtLittleContainer1") !== null) {
        document.getElementById("PassStrenghtLittleContainer1").style.background="#592689";
      }
      if (document.getElementById("PassStrenghtLittleContainer2") !== null) {
        document.getElementById("PassStrenghtLittleContainer2").style.background="none";
      }
      if (document.getElementById("PassStrenghtLittleContainer3") !== null) {
        document.getElementById("PassStrenghtLittleContainer3").style.background="none";
      }
    } else {
      if (document.getElementById("PassStrenghtLittleContainer3") !== null) {
        document.getElementById("PassStrenghtLittleContainer3").style.background="none";
      }
      if (document.getElementById("PassStrenghtLittleContainer2") !== null) {
        document.getElementById("PassStrenghtLittleContainer2").style.background="none";
      }
      if (document.getElementById("PassStrenghtLittleContainer1") !== null) {
        document.getElementById("PassStrenghtLittleContainer1").style.background="none";
      }
    }
  }

  return (
    <div className="ChangePasswordContainer">
      <section className="sign-in">
        <div className="container">
          <div className="signin-content" style={{ paddingBottom: 25, paddingTop: 5 }}>

            <div className="signin-form">
              <p>{error}</p>
              <form method="POST" className="register-form" id="login-form" onSubmit={handleSubmit} >
                <div className="form-group">
                  <label htmlFor="pass">{t('Current Password')}</label>
                  <input type="password" autoComplete={"false"} name="oldPass" required onChange={handleOnChange} id="pass" placeholder="**********" />
                </div>

                <div className="form-group">
                  <label htmlFor="pass" style={{ marginBottom: 13 }}>{t('New password')}</label>
                  <div className='PassandCountainer'>
                    <input type="password" autoComplete={"false"} name="newPass" required onChange={handleOnChange} id="pass" placeholder="**********" />
                    <div className='PassStrenghtContainer'>
                        <div className='PassStrenghtLittleContainer1' id='PassStrenghtLittleContainer1'></div>
                        <div className='PassStrenghtLittleContainer2'id='PassStrenghtLittleContainer2'></div>
                        <div className='PassStrenghtLittleContainer3'id='PassStrenghtLittleContainer3'></div>
                    </div>
                  </div>
                  {checkPassStrength()}
                  <span className='Confirmation'>{scorePassword(state.newPass) > 60 ? <img src={checkicon} alt="checkicon" /> : null }</span>
                </div>

                <div className="form-group">
                  <label htmlFor="confirm_password">{t('Confirm password')}</label>
                  <input type="password" autoComplete={"false"} name="confirmPass" required onChange={handleOnChange} id="confirm_password" placeholder="**********" />
                  <span className='Confirmation'>{check() === 'Matching' ? <img src={checkicon} alt="checkicon" /> : null}</span>
                </div>
                <div className="form-group form-button">
                  <input type="submit" name="changepassword" id="changepassword" className="form-submit" value={t("Set the new the password")} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

    </div >
  )
}
