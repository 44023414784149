import { createSlice } from '@reduxjs/toolkit';
import { askPlanePath } from './Util/askPlanePath';

export const PathPlaneSlice = createSlice({
  name: 'PathPlane',
  initialState: {
    path: 
      {
        id: 'plane',
        routes: [],
        CO2displayed: 0
      },
    CO2: 
      {
        manufacture: 0.00489,
        infrastructure: 0.00678,
        operation: 0
      },
    planeLoading: false,
  },
  reducers: {
    setPathPlane: (state, action) => {
      if(action.payload[0] === 'CO2') {
        return {
          ...state,
          CO2: {
            manufacture: state.CO2.manufacture,
            infrastructure: state.CO2.infrastructure,
            operation: action.payload[1] 
          }
        }
      } else if(action.payload[0] === 'reset') {
        return {
          ...state,
          path : {
            id: 'plane',
            routes: [],
            CO2displayed: 0
          },
        }
      } else {
        return {
          ...state
        }
      }
      
    },
  },
  extraReducers: {
    [askPlanePath.fulfilled]: (state, action) => {
      return {
        ...state,
        path: 
        {
          id: 'plane',
          routes: action.payload,
          CO2displayed: action.payload[0]?.distance * (state.CO2.operation + state.CO2.manufacture + state.CO2.infrastructure)
        },
        planeLoading : false,
      }
    },
    [askPlanePath.rejected]:(state) => {
      return {
        ...state,
        path: 
        {
          id: 'plane',
          routes: [],
          CO2displayed: 0
        },
        planeLoading : false,
      }
    },
    [askPlanePath.pending]: (state) => {
      return {
        ...state,
        planeLoading : true
      }      
    },
  }
});

export const { setPathPlane } = PathPlaneSlice.actions;


export const selectPathPlane = state => state.PathPlane.path;
export const selectPlaneLoading = state => state.PathPlane.planeLoading;
export const selectPlaneCO2 = state => state.PathPlane.CO2;
export const selectDepartureAirport = state => state.PathPlane.departureAirport;
export const selectArrivalAirport = state => state.PathPlane.arrivalAirport;

export default PathPlaneSlice.reducer;