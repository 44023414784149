import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory,useLocation } from 'react-router-dom'
import Axios from '../../../api';
import loadinglogo from "../../../containers/MapApp/Results/PathList/loading.svg";
import { setAlert } from '../../../app/AppSlice';
import { isLoggedIn, logout, setLogin } from '../AuthSlice';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import styles from './ForgotPassword.module.css';
import PrimaryButtonAsync from '../../../components/PrimaryButton/PrimaryButtonAsync';
import { useTranslation } from 'react-i18next';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const [pageLoading, _pageLoading] = useState(true);
    const [loggingOut, _loggingOut] = useState(false);
    let history = useHistory();
    let query = useQuery();
    const isAuthenticated = useSelector(isLoggedIn);
    const [params, _params] = useState({
        password: '',
        confirmPass: '',
        token: query.get('token'),
        email: query.get('email')
    });
    const [error, _error] = useState('');
    const [passwordScore, _passwordScore] = useState(0);
    const { t } = useTranslation();
    

    useEffect(() => {
        if(params.token && params.email){
            setTimeout(() => { // set time to reduce the load at server 
                checkToken();
            }, 5000);
        } else {
            dispatch(setAlert({message: t('This link is broken'),showAlert: true, duration: 2000, severity:'error'}));
            history.replace("/");
        }
    }, []);

    const checkToken = async ()  => {
        try {
            const { data } = await Axios.post('/auth/check-forgotpassword-token',{token: params.token});
            _pageLoading(false);
        } catch (error) {
            if(error.response){
                dispatch(setAlert({message: error.response.data.message,showAlert: true, duration: 6000, severity:'error'}));
                setTimeout(() => {
                    history.replace("/");
                }, 1000);
            }
        }
    }

    const cleareSession = async () => {
        _loggingOut(true);
        try {
            await Axios.post('/auth/logout');
            dispatch(logout());
            _loggingOut(false);

        } catch (error) {
            dispatch(logout());
            _loggingOut(false);
        }
    }

    const setPassword = async () => {
        if(!(params.password && params.confirmPass)) {_error(t('Password and confirm password fields cannot be empty!')); return;}
        if(!(params.password === params.confirmPass)) {_error(t("Password and confirm password fields don't match")); return;}
        if(passwordScore < 60) {_error(t('Your password is too weak')); return;}
        try {
            const {data} = await Axios.post('/auth/set-newpassword', params);
            if(data.message === "Your password updated and logged in"){
                dispatch(setAlert({message: t('Your password has been successfully updated and you are logged in'),showAlert: true, duration: 4000, severity:'success'}));
                setTimeout(() => {
                    dispatch(setLogin({user: data.user}))
                    history.replace('/');
                }, 1000);
            }
        } catch (error) {
            if(error.response){
                dispatch(setAlert({message: error.response.data.message,showAlert: true, duration: 4000, severity:'error'}));
            }
        }
        
    }
    
    const onChangeField = (e = null) => {
        if(!e) return;
        _params({
            ...params,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        scorePassword(params.password);
    }, [params.password]);


    function scorePassword(pass) {
        let score = 0;
        if (!pass) return score;
        // award every unique letter until 5 repetitions
        let letters = {};
        for (let i=0; i<pass.length; i++) {
            letters[pass[i]] = (letters[pass[i]] || 0) + 1;
            score += 5.0 / letters[pass[i]];
        }
        // bonus points for mixing it up
        let variations = {
            digits: /\d/.test(pass),
            lower: /[a-z]/.test(pass),
            upper: /[A-Z]/.test(pass),
            nonWords: /\W/.test(pass),
        }
        let variationCount = 0;
        for (let check in variations) {
            variationCount += (variations[check] === true) ? 1 : 0;
        }
        score += (variationCount - 1) * 10;
        _passwordScore(parseInt(score))
      }

    return (
        <>
            {pageLoading ? 
            <div style={{display: "flex",height: "100vh",flexDirection: "column",alignItems: "center",justifyContent: "center"}} >
                <img id="loadingLogo" style={{ width: 100 }} src={loadinglogo} alt="loading"/>
            </div>
            :
            <div className={styles.main_container}>
                {isAuthenticated ? <Dialog open={true}>
                    <DialogTitle>
                        {t('Wait')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <p>{t('You are already logged in. To set a new password, you must be logged out')}</p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button disabled={loggingOut} onClick={()=>{
                        dispatch(setAlert({message: t('You will be redirected to the home page'),showAlert: true, duration: 1500, severity:'info'}));
                        setTimeout(() => {
                            history.replace("/");
                        }, 1000);
                    }} color="primary">
                        {t('Cancel')}
                    </Button>
                    <Button disabled={loggingOut} onClick={cleareSession} color="primary">
                        {t('Logout')}
                    </Button>
                    </DialogActions>
                </Dialog>: 
                    <form className={styles.form}>
                        <Alert className={styles.error_message} style={{visibility: error ? "visible" : "hidden"}} severity="error">
                            <AlertTitle>{t('Error')}</AlertTitle>
                            {t('Error')}
                        </Alert>
                        <div className={styles.input}>
                        <input type={"password"} placeholder="New password" name="password" onChange={onChangeField} />
                            {passwordScore && passwordScore >= 30 ? <div className={styles.bar_container}>
                                <div className={styles.bar}></div>
                                <div style={{visibility: passwordScore > 60 ? "visible" :"hidden"}} className={styles.bar}></div>
                                <div style={{visibility: passwordScore > 80 ? "visible" :"hidden"}} className={styles.bar}></div>
                            </div>:null}
                        </div>
                        <div className={styles.input}>
                            <input type={"password"} placeholder="Confirm password" name="confirmPass" onChange={onChangeField} />
                        </div>
                        <PrimaryButtonAsync onClick={setPassword}>{t('Set the new the password')}</PrimaryButtonAsync>
                    </form>
                }
            </div>
            }
        </>
    )
}

export default ForgotPassword

